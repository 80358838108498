import { Formik } from "formik";
import { pick } from "lodash";
import React from "react";

import { IBillingData } from "@types";
import { BillingDataFormContent } from "./BillingDataFormContent"
import { IProps } from "./types";

const DATA_FIELDS = [
  "businessName",
  "RFC",
  "taxRegime",
  "CFDI",
  "payment_method",
  "phone",
];

export const BillingData: React.FC<IProps> = ({
  data,
  handleSubmit,
  formId,
  errors,
  defaultValue,
  ...props
}: IProps) => {
  let BillingDataWithPickedFields: Partial<IBillingData> = {};

  if (data) {
    BillingDataWithPickedFields = pick(data, DATA_FIELDS);
  }

  return (
    <Formik
      initialValues={BillingDataWithPickedFields}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => { 
        if (handleSubmit) {
          handleSubmit(values);
        }
        setSubmitting(false);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        setFieldValue,
        setFieldTouched,
      }) => {
        return (
          <BillingDataFormContent
            {...{
              defaultValue,
              errors,
              formId,
              handleBlur,
              handleChange,
              handleSubmit,
              setFieldTouched,
              setFieldValue,
              values,
            }}
            {...props}
          />
        );
      }}
    </Formik>
  );
};
