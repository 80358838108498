import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import "./scss/index.scss"

import { Loader } from "@components/atoms";
import { ProductTile } from "@components/molecules";



// import * as S from "./styles";
import { IProps } from "./types";

export const ProductList: React.FC<IProps> = ({
  products,
  canLoadMore = false,
  loading = false,
  showSeeAll = false,
  onLoadMore = () => null,
}: IProps) => {
  return (
    <>
      <InfiniteScroll
        dataLength={products.length}
        next={onLoadMore}
        hasMore={canLoadMore}
        loader={<Loader />}
        className="product-list-container"
        style={{overflow: 'hidden'}}
      >
        {products.map(product => (
          <ProductTile product={product} key={product.id}/>
        ))}
      </InfiniteScroll>
    </>
  );
};
