import "./scss/index.scss";

import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Form, TextField } from "..";
import { maybe } from "../../core/utils";
import { TypedPasswordResetMutation } from "./queries";

import { passwordResetUrl } from "../../app/routes";

const PasswordResetForm: React.FC = () => {
  const intl = useIntl();
  return(
  <div className="password-reset-form">
    <p>      
      <FormattedMessage
        id="password_provide_email"
        defaultMessage="Ingresa tu correo electrónico y así enviarte un vínculo para cambiar tu contraseña."/>
    </p>
    <TypedPasswordResetMutation>
      {(passwordReset, { loading, data }) => {
        return (
          <Form
            errors={maybe(() => data.requestPasswordReset.errors, [])}
            onSubmit={(event, { email }) => {
              event.preventDefault();
              passwordReset({
                variables: {
                  email,
                  redirectUrl: `${window.location.origin}${passwordResetUrl}`,
                },
              });
            }}
          >
            <TextField
              name="email"
              autoComplete="email"
              label={intl.formatMessage({id:"email",defaultMessage:"Correo electrónico"})}
              type="email"
              required
            />
            <div className="password-reset-form__button">
              <Button type="submit" {...(loading && { disabled: true })}>
                {loading ? intl.formatMessage({id:"loading",defaultMessage:"Cargando"}) : intl.formatMessage({id:"resetpassword_reset",defaultMessage:"Restablecer contraseña"})}
              </Button>
            </div>
          </Form>
        );
      }}
    </TypedPasswordResetMutation>
  </div>
  );
};

export default PasswordResetForm;
