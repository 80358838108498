import "./scss/index.scss";

import * as React from "react";
import {  useIntl } from "react-intl";

import { accountConfirmUrl } from "../../../app/routes";

import { Button, Form, TextField } from "../..";
import { maybe } from "../../../core/utils";
import { RegisterAccount } from "./gqlTypes/RegisterAccount";
import { TypedAccountRegisterMutation } from "./queries";

import { AlertManager, useAlert } from "react-alert";

const showSuccessNotification = (
  data: RegisterAccount,
  hide: () => void,
  alert: AlertManager, 
  successMessage: string,
  confirmationMessage: string
) => {
  const successful = maybe(() => !data.accountRegister.errors.length);

  if (successful) {
    hide();
    alert.show(
      {
        title: data.accountRegister.requiresConfirmation
          ? confirmationMessage
          : successMessage,
      },
      { type: "success", timeout: 5000 }
    );
  }
};

const RegisterForm: React.FC<{ hide: () => void }> = ({ hide }) => {
  const alert = useAlert();
  const intl = useIntl();
  const [emailS, setEmailS] = React.useState("");
  const defEmail = localStorage.getItem("emailR");  

  React.useEffect(() => {
    if (defEmail !== undefined) {
      setEmailS(defEmail);
    }
  }, [emailS]);

  const changeEmail = (e) => {
    setEmailS(e.target.value);
    localStorage.setItem("emailR", e.target.value);
  };

  return (
    <TypedAccountRegisterMutation
      onCompleted={data => showSuccessNotification(
        data, 
        hide, 
        alert,
        intl.formatMessage({id:'register_success_message', defaultMessage:'Por favor checa tu correo electrónico para más instrucciones'}),
        intl.formatMessage({id:'register_confirmation_message', defaultMessage:'Un nuevo usuario ha sido creado'}))}
    >
      {(registerCustomer, { loading, data }) => {
        return (
          <Form
            errors={maybe(() => data.accountRegister.errors, [])}
            onSubmit={(event, { email, password }) => {
              event.preventDefault();
              email = emailS;
              const redirectUrl = `${window.location.origin}${accountConfirmUrl}`;
              registerCustomer({ variables: { email, password, redirectUrl } });
            }}
          >
            <TextField
              name="email"
              autoComplete="email"
              label={intl.formatMessage({id:'email', defaultMessage:'Correo Electrónico'})}
              type="email"
              onChange={(e) => {
                changeEmail(e);
              }}
              required
              value={emailS}
            />
            <TextField
              name="password"
              autoComplete="password"
              label={intl.formatMessage({id:'password', defaultMessage:'Contraseña'})}
              type="password"
              required
              
            />
            <div className="login__content__button">
              <Button type="submit" {...(loading && { disabled: true })}>
                {loading ? intl.formatMessage({id:'loading', defaultMessage:'Cargando'}) :  intl.formatMessage({id:'register', defaultMessage:'Registrarse'})}
              </Button>
            </div>
          </Form>
        );
      }}
    </TypedAccountRegisterMutation>
  );
};

export default RegisterForm;
