import { History } from "history";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Link, useHistory } from "react-router-dom";

import { Button, CartFooter, CartHeader } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { CartRow } from "@components/organisms";
import { Cart, CartEmpty } from "@components/templates";
import { IItems } from "@sdk/api/Cart/types";
import { UserDetails_me } from "@sdk/queries/gqlTypes/UserDetails";
import { useCart, useCheckout, useUserDetails } from "@sdk/react";
import { BASE_URL } from "@temp/core/config";
import { ITaxedMoney } from "@types";
import { TypedTaxesQuery } from "@temp/@next/components/organisms/CartSummary/queries";
import { MainMenuSubItem } from "../../../components/MainMenu/gqlTypes/MainMenuSubItem";
import { TypedMainMenuQuery } from "../../../components/MainMenu/queries";
import { generateCategoryUrl, maybe } from "../../../core/utils";

import { IProps } from "./types";

const title = (total: number) => (
  <>
    <h1 data-cy="cartPageTitle">
      <FormattedMessage id="cartpage_have" defaultMessage="TIENES " />({total})
      <FormattedMessage id="cartpage_products" defaultMessage=" ARTÍCULO(S) " />
    </h1>
    <h1>
      <FormattedMessage
        id="cartpage_in_your_cart"
        defaultMessage="EN TU CARRITO"
      />
    </h1>
  </>
);

const getShoppingButton = (history: History) => (
  <Button
    data-cy="cartPageBtnContinueShopping"
    onClick={() => history.push(BASE_URL)}
  >
    <FormattedMessage
      id="continue_buying"
      defaultMessage="Continuar Comprando"
    />
  </Button>
);

const getCheckoutButton = (history: History, user: UserDetails_me | null) => (
  <Button
    data-cy="cartPageBtnProceedToCheckout"
    onClick={() => history.push(user ? `/checkout/` : `/login/`)}
  >
    <FormattedMessage
      id="cartpage_continue_with_payment"
      defaultMessage="Proceder al pago"
    />
  </Button>
);

const cartHeader = <CartHeader />;

const prepareCartFooter = (
  totalPrice?: ITaxedMoney | null,
  shippingTaxedPrice?: ITaxedMoney | null,
  promoTaxedPrice?: ITaxedMoney | null,
  subtotalPrice?: ITaxedMoney | null
) => (
  <TypedTaxesQuery>
    {({ data }) => (
      <CartFooter
        subtotalPrice={
          <TaxedMoney
            data-cy="cartPageSubtotalPrice"
            taxedMoney={subtotalPrice}
          />
        }
        totalPrice={
          <TaxedMoney data-cy="cartPageTotalPrice" taxedMoney={totalPrice} />
        }
        shippingPrice={
          shippingTaxedPrice &&
          shippingTaxedPrice.gross.amount !== 0 && (
            <TaxedMoney
              data-cy="cartPageShippingPrice"
              taxedMoney={shippingTaxedPrice}
            />
          )
        }
        discountPrice={
          promoTaxedPrice &&
          promoTaxedPrice.gross.amount !== 0 && (
            <TaxedMoney
              data-cy="cartPageShippingPrice"
              taxedMoney={promoTaxedPrice}
            />
          )
        }
        taxPrice = {data}
      />
    )}
  </TypedTaxesQuery>
);

const generateCart = (
  items: IItems,
  removeItem: (variantId: string) => any,
  updateItem: (variantId: string, quantity: number) => any
) => {
  return items?.map(({ id, variant, quantity, totalPrice }, index) => (
    <CartRow
      key={id ? `id-${id}` : `idx-${index}`}
      index={index}
      name={variant?.product?.name || ""}
      maxQuantity={variant.quantityAvailable || quantity}
      quantity={quantity}
      onRemove={() => removeItem(variant.id)}
      onQuantityChange={(quantity) => updateItem(variant.id, quantity)}
      thumbnail={{
        ...variant?.product?.thumbnail,
        alt: variant?.product?.thumbnail?.alt || "",
      }}
      totalPrice={
        <TaxedMoney
          data-cy={`cartPageItem${index}TotalPrice`}
          taxedMoney={totalPrice}
        />
      }
      unitPrice={
        <TaxedMoney
          data-cy={`cartPageItem${index}UnitPrice`}
          taxedMoney={variant?.pricing?.price}
        />
      }
      sku={variant.sku}
      attributes={variant.attributes?.map((attribute) => {
        return {
          attribute: {
            id: attribute.attribute.id,
            name: attribute.attribute.name || "",
          },
          values: attribute.values.map((value) => {
            return {
              id: value?.id,
              name: value?.name || "",
              value: value?.value,
            };
          }),
        };
      })}
    />
  ));
};

const generateContinueBuyingLink = () => {
  return (
    <TypedMainMenuQuery renderOnError displayLoader={false}>
      {({ data }) => {
        const itemsMenu = maybe(() => data?.shop?.navigation?.main?.items, [])!;
        let item: MainMenuSubItem | undefined;
        if (itemsMenu!.length > 0) {
          item = itemsMenu[0]!;
        }

        return (
          <div>
            {item ? (
              <Link
                to={generateCategoryUrl(item.category!.id, item.category!.name)}
              >
                <FormattedMessage
                  id="continue_buying"
                  defaultMessage="Seguir comprando"
                />
              </Link>
            ) : (
              <FormattedMessage
                id="continue_buying"
                defaultMessage="Seguir comprando"
              />
            )}
          </div>
        );
      }}
    </TypedMainMenuQuery>
  );
};

export const CartPage: React.FC<IProps> = ({}: IProps) => {
  const history = useHistory();
  const { data: user } = useUserDetails();
  const { checkout } = useCheckout();
  const {
    loaded,
    removeItem,
    updateItem,
    items,
    totalPrice,
    subtotalPrice,
    shippingPrice,
    discount,
  } = useCart();

  const shippingTaxedPrice =
    checkout?.shippingMethod?.id && shippingPrice
      ? {
          gross: shippingPrice,
          net: shippingPrice,
        }
      : null;
  const promoTaxedPrice = discount && {
    gross: discount,
    net: discount,
  };

  if (loaded && items?.length) {
    return (
      <Cart
        title={title(items.length)}
        button={getCheckoutButton(history, user)}
        cartHeader={cartHeader}
        cartFooter={prepareCartFooter(
          totalPrice,
          shippingTaxedPrice,
          promoTaxedPrice,
          subtotalPrice
        )}
        cart={items && generateCart(items, removeItem, updateItem)}
        link={generateContinueBuyingLink()}
      />
    );
  } else {
    return <CartEmpty button={getShoppingButton(history)} />;
  }
};
