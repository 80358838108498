import "./scss/index.scss";

import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../core/config";
import Button from "../Button";

interface NotFoundProps {
  message?: string;
}

const NotFound: React.FC<NotFoundProps> = () => (
  <div className="not-found-page">
    <h2 className="not-found-page__header">404</h2>
    <div className="not-found-page__ruler" />
    <div className="not-found-page__message">
      <p>        
        <FormattedMessage
          id="notfound"
          defaultMessage="No pudimos encontrar la página que buscas."/>
      </p>
      <p>        
        <FormattedMessage
          id="notfound_error"
          defaultMessage="Lamentamos este error y esperamos que tengas un buen día."/>
      </p>
    </div>
    <div className="not-found-page__button">
      <Link to={BASE_URL}>
        <Button secondary>
          <FormattedMessage
            id="back_to_home"
            defaultMessage="Regresar a inicio"/>
        </Button>
      </Link>
    </div>
  </div>
);

export default NotFound;
