import React from "react";
import {  FormattedMessage } from "react-intl";
import { PasswordResetForm } from "..";

const ResetPasswordForm: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <>
    <h3 className="checkout__header">
      <FormattedMessage
        id="registered_user"
        defaultMessage="Usuario registrado"/>
    </h3>
    <PasswordResetForm />
    <p>
      <span className="u-link" onClick={onClick}>  
        <FormattedMessage
          id="back_to_login"
          defaultMessage="Regresar a inicio de sesión"/>
      </span>
    </p>
  </>
);

export default ResetPasswordForm;
