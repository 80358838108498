import React from "react";
import {  useIntl } from "react-intl";

import { AddNewTile, TileGrid } from "@components/atoms";
import { CreditCardTile } from "@components/molecules";

import { IProps } from "./types";

export const CreditCardGrid: React.FC<IProps> = ({ creditCards }: IProps) => {
  const intl = useIntl();
  const addNewTile = [<AddNewTile type={intl.formatMessage({id: 'card', defaultMessage:'Tarjeta'})} />];
  const ccTiles = creditCards.map(cc => <CreditCardTile {...cc} />);

  return <TileGrid elements={addNewTile.concat(ccTiles)} />;
};
