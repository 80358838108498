import "../globalStyles/scss/index.scss";

import React from "react";
import { Footer, MainMenu, MetaConsumer, OverlayManager, WhatsappButton } from "../components";
import { Routes } from "./routes";



declare global {
  interface Window {
    dataLayer: Array<Record<string, any>>;
  }
}

const App: React.FC = () => {
  if (typeof window !== "undefined") {
    window.dataLayer.push({
      event: "pageview",
    });
  }



  return (
    <>
      <MetaConsumer />
      <header>
        <MainMenu />
      </header>
      <Routes />
      <Footer />
      <WhatsappButton />
      <OverlayManager />
    </>
  );
};

export default App;
