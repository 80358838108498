import React, { useEffect, useState } from "react";
import {  FormattedMessage } from "react-intl";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Cart footer to use with conjunction of cart rows
 */
const CartFooter: React.FC<IProps> = ({
  subtotalPrice,
  shippingPrice,
  discountPrice,
  totalPrice,
  taxPrice
}: IProps) => {

  const [taxRate, setTaxRate] = useState(null);
  const [subTotal, setSubTotal] = useState(null);

 useEffect(() => {
   if (taxPrice) {
     taxPrice.taxCountry.map((countryTax: any) => {
       if (countryTax.countryCode === "MX") {
         const tax = countryTax.taxRate / 100 + 1;
         if (totalPrice) {
           const result = totalPrice.props.taxedMoney.net.amount / tax;
           const iva = totalPrice.props.taxedMoney.net.amount - result;
           setTaxRate(iva.toFixed(2));
           const subTotalAmount = totalPrice.props.taxedMoney.net.amount - iva
           setSubTotal(subTotalAmount.toFixed(2));
         }      
       }
     });
   }
 }, [totalPrice]);

  return (
    <S.Wrapper showShipping={!!shippingPrice} showDiscount={!!discountPrice}>
      <S.PrivacyText>        
        <h3>         
            <a href="/page/privacy"> 
              <FormattedMessage
                id="cartfooter_check_policies"
                defaultMessage="Revisa nuestras políticas aquí"/>
            </a>
        </h3>
        <p>          
          <FormattedMessage
            id="cartfooter_shipping_needs_time"
            defaultMessage="Nuestros envíos tardan de 5-10 días hábiles"/>
        </p>
      </S.PrivacyText>
      <S.SubtotalText>Subtotal</S.SubtotalText>
      <S.SubtotalPrice>{subTotal} MXN</S.SubtotalPrice>
      <S.TaxText><FormattedMessage id="tax" defaultMessage="Impuesto" /></S.TaxText>
      <S.TaxPrice>{taxRate} MXN</S.TaxPrice>
      {shippingPrice && (
        <>
          <S.ShippingText>            
            <FormattedMessage
              id="shipping"
              defaultMessage="Envío"/>
          </S.ShippingText>
          <S.ShippingPrice>{shippingPrice}</S.ShippingPrice>
        </>
      )}
      {discountPrice && (
        <>
          <S.DiscountText>
            <FormattedMessage
              id="promo_code"
              defaultMessage="Código de promoción"/>
          </S.DiscountText>
          <S.DiscountPrice>{discountPrice}</S.DiscountPrice>
        </>
      )}     
      <S.TotalText>Total</S.TotalText>
      <S.TotalPrice>{totalPrice}</S.TotalPrice>
    </S.Wrapper>
  );
};

export { CartFooter };
