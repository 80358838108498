import * as React from "react";

import { INavItem, OverlayContextInterface } from "..";

import {
  generateCategoryUrl,
  generateCollectionUrl,
  generatePageUrl,
  getDBIdFromGraphqlId,
} from "../../core/utils";


const MobileNav: React.FC<{ overlay: OverlayContextInterface }> = ({
  overlay,
}) => {
  
  const items: INavItem[] = overlay.context.data;
  const getUrl = (item) => {
    let url = null;
    if (item.category) {
      url = generateCategoryUrl(item.category.id, item.category.name);
    } else if (item.collection) {
      url = generateCollectionUrl(item.collection.id, item.collection.name);
    } else if (item.page) {
      url = generatePageUrl(item.page.slug);
    }
    return url;
  }
  const urlParts = window.location.href.split("/");
  const categoryIdSelected = urlParts[urlParts.length-1] ? urlParts[urlParts.length-1] : urlParts[urlParts.length-2];

  for (const item of items) {
    const url = getUrl(item);
    const categoryId = getDBIdFromGraphqlId(item.category.id,"Category");
    let styleValue = categoryIdSelected === categoryId.toString() ? "'display: block;'":"'display: none;'";
    const navBar = document.getElementById('mySidenav');
    const html = "<div style='padding-bottom:1rem;'><a class='title-dropdown' style='display:inline-block;width:90%' href="+ url +"> "+ item.name +" </a><i data='"+ item.id +"' class='dropdown-btn'>+</i></div>";
    let submenu = "<div id='"+ item.id +"' class='dropdown-container' style="+styleValue +">";
    
    for(const subitem of item.children) {
      const submenuUrl = getUrl(subitem);
      const subCategoryId = getDBIdFromGraphqlId(subitem.category.id,"Category");
      let subMenuClass = "submenu-item";
      let arrowClass = 'arrow-submenu';

      if( categoryIdSelected === subCategoryId.toString()){
        styleValue = "'display: block;'";
        submenu = "<div id='"+ item.id +"' class='dropdown-container' style="+styleValue +">";
        subMenuClass += " submenu-selected";
        arrowClass += ' arrow-submenu-selected';
      }
      
      submenu += "<div class='"+arrowClass+"'></div><a href='"+ submenuUrl +"' class='"+subMenuClass+"' href='#'>"+ subitem.name +"</a>"
    }
    submenu += "</div>";
    navBar.innerHTML += (html + submenu);
  }
  return null;
};

export default MobileNav;
