import { media, styled } from "@styles";

export const Wrapper = styled.div`
  display: grid;
  min-height: 140px;
  max-height: min-content;
  width: 100%;
  grid-template-areas: "photo description unitPrice quantity totalPrice";
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
  align-items: center;
  border-bottom: 1px solid rgba(50, 50, 50, 0.1);
  padding: 0.8rem 0rem;

  ${media.mediumScreen`
    grid-template-columns: 1fr 2fr 2fr;
    grid-row-gap: 15px;
    grid-column-gap: 20px;
    grid-template-areas: "photo description description"
    "trash description description"
    "trash unitPrice quantity"
    ". . totalPrice";
    padding: 1rem 0rem;
  `};
  ${media.smallScreen`
    grid-template-columns: 2fr 3fr;
    grid-row-gap: 15px;
    grid-column-gap: 20px;
    grid-template-areas: "photo description description" 
      "trash description description" 
      "unitPrice quantity quantity" 
      "totalPrice totalPrice ."
  `};
`;

export const QuantityButtons = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0;
  margin: 0 15px 0 0;
  width: 50px;

  > div {
    display: flex;
  }

  svg {
    cursor: pointer;
    justify-self: center;
  }
`;

export const Photo = styled.div`
  grid-area: photo;
  display: flex;
  align-items: flex-start;
  align-self: top;
  width: 170px;
  height: 170px;

  background-color: #f1f5f5;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  ${media.smallScreen`
    width: 100px;
    height: 100px;
  `};
`;

export const Description = styled.div`
  grid-area: description;
  height: 100%;
  
  ${media.mediumScreen`
    margin-left: 0px;
  `}
`;

export const Sku = styled.p`
  margin: 6px 0;
  text-align: center;
  margin-bottom: 10px;
`;

export const Attributes = styled.div`
  display: grid;
  grid-auto-columns: max-content;
  grid-template-columns: repeat(auto-fit, minmax(166px, 500px));
  

  ${media.mediumScreen`
    flex-flow: column;
  `};
`;

export const SingleAttribute = styled.p`
  margin: 0;
  flex-grow: 1;
  display: flex;
  justify-content: center
  white-space: nowrap;
  background-color: white;
`;

export const Name = styled.p`
  font-weight: ${props => props.theme.typography.boldFontWeight};
  font-size: ${props => props.theme.typography.h4FontSize};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 6px;
`;

export const LightFont = styled.span`
  font-size: ${props => props.theme.typography.smallFontSize};
  color: rgba(125, 125, 125, 0.6);
`;

export const Price = styled.div`
  font-size: ${props => props.theme.typography.h4FontSize};
  display: flex;
  justify-content: center;
  font-weight: bold;
  ${media.mediumScreen`
    font-weight: normal;
    flex-direction: column;
  `}

  p {
    margin: 0;
  }
`;

export const PriceLabel = styled.p`
  display: none;
  ${media.mediumScreen`
    display: block;
  `}
`;

export const TotalPrice = styled(Price as any)`
  grid-area: totalPrice;
  ${media.mediumScreen`
    p {
      text-align: right;
    }
  `}
`;

export const Trash = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-area: trash;
`;

export const UnitPrice = styled(Price as any)`
  grid-area: unitPrice;
`;

export const Quantity = styled.div`
  grid-area: quantity;
  min-width: 120px;
  margin: 0 15px;
  display: flex;
  align-items: center;
`;

export const QuantityText = styled.div`
  width: 30%;

  div {
    margin: 0;

    input {
      text-align: center;
    }
  }
`;

export const Add = styled.div`
  width: 40px;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
  background-color: black;

  button {
    font-size: 1.5rem;
    color: white;
    padding-bottom: 5px;
    width: 100%;
  }
`;

export const Substract = styled.div`
  width: 40px;
  height: 40px;
  border-width: 1px;
  border-style: solid;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  background-color: black;

  button {
    font-size: 1.5rem;
    color: white;
    padding-bottom: 5px;
    width: 100%;
  }
`;

export const Delete = styled.p`
  font-family: "DIN Pro";
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  text-align: center;

  button {
    border-bottom: 1px;
    border-bottom-color: black;
    border-bottom-style: solid;
    border-radius: 0px;
  }
`;