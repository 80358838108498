import * as React from "react";
import { smallScreen } from "../../globalStyles/scss/variables.scss";

import classNames from "classnames";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import Media from "react-media";
// import { Link } from "react-router-dom";

import { CachedImage, Thumbnail } from "@components/molecules";

import { Breadcrumbs, ProductDescription } from "../../components";
import { generateCategoryUrl, generateProductUrl } from "../../core/utils";
// import { TypedHomePageQuery } from "../Home/queries";
import { ProductDetails_product } from "./gqlTypes/ProductDetails";
import OtherProducts from "./Other";

import { ICheckoutModelLine } from "@sdk/repository";
import AddToCartButton from "@temp/components/ProductDescription/AddToCartButton";
import { ProductDescription as NewProductDescription } from "../../@next/components/molecules";
import { ProductGallery } from "../../@next/components/organisms/";

import { structuredData } from "../../core/SEO/Product/structuredData";
// import noPhotoImg from "../../images/no-photo.svg";

import saveAs from "file-saver";

class Page extends React.PureComponent<
  {
    product: ProductDetails_product;
    add: (variantId: string, quantity: number) => any;
    items: ICheckoutModelLine[];
    intl: IntlShape;
  },
  { variantId: string; slideIndex: number }
> {
  fixedElement: React.RefObject<HTMLDivElement> = React.createRef();
  productGallery: React.RefObject<HTMLDivElement> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 1,
      variantId: "",
    };
  }

  componentDidMount() {
    this.nextImage(this.state.slideIndex);
  }

  setVariantId = (id: string) => {
    this.setState({ variantId: id });
  };

  get showCarousel() {
    return this.props.product.images.length > 1;
  }

  populateBreadcrumbs = (product) => [
    {
      link: generateCategoryUrl(product.category.id, product.category.name),
      value: product.category.name,
    },
    {
      link: generateProductUrl(product.id, product.name),
      value: product.name,
    },
  ];

  getImages = () => {
    const { product } = this.props;
    if (product.variants && this.state.variantId) {
      const variant = product.variants
        .filter((variant) => variant.id === this.state.variantId)
        .pop();
      if (variant.images.length > 0) {
        return variant.images;
      } else {
        return product.images;
      }
    } else {
      return product.images;
    }
  };

  getLimit = () => {
    let limit = 8;
    if (window.innerWidth <= 323) {
      limit = 1;
    } else if (window.innerWidth <= 450) {
      limit = 2;
    } else if (window.innerWidth <= 550) {
      limit = 3;
    } else if (window.innerWidth <= 700) {
      limit = 4;
    } else if (window.innerWidth <= 1250) {
      limit = 5;
    }
    return limit;
  };

  nextImage = (n) => {
    let i;
    const x = document.getElementsByClassName(
      "product-page__categories__wrapper"
    ) as HTMLCollectionOf<HTMLElement>;

    if (n > x.length) {
      this.setState({ slideIndex: 1 });
    }
    if (n < 1) {
      this.setState({ slideIndex: x.length });
    }

    const limit = this.getLimit();

    const value = this.state.slideIndex as any;
    for (i = 0; i < x.length; i++) {
      x[i].style.display =
        i >= value - 1 && i < value - 1 + limit ? "flex" : "none";
    }
  };

  handleClickEvent = (e) => {
    const newValue = (this.state.slideIndex as any) + 1;
    this.setState({ slideIndex: newValue });
    this.nextImage(newValue);
  };

  renderImages = (product) => {
    const images = this.getImages();
    if (images && images.length) {
      return images.map((image) => (
        <a href={image.url} target="_blank">
          <CachedImage url={image.url} key={image.id}>
            <Thumbnail source={product} />
          </CachedImage>
        </a>
      ));
    }
    return <CachedImage />;
  };

  downloadDatasheet = (product) => {
    // Convert product.datasheet to Blob file and download it
    if (product.datasheet) {
      fetch(product.datasheet, {
        headers: {
          Accept: "application/pdf",
        },
        method: "GET",
      })
        .then((res) => res.blob())
        .then((blob) => {
          const name = (product.name as string)
            .toLowerCase()
            .replaceAll(" ", "_");
          saveAs(blob, "ficha_tecnica_" + name + ".pdf");
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  render() {
    const { product } = this.props;

    const videoSplit = product.youtubeUrl.split("v=");
    let videoId = videoSplit.length > 0 && videoSplit[1] ? videoSplit[1] : "";
    const ampersandPosition = videoId.indexOf("&");
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }

    const name =
      this.props.intl.locale === "es"
        ? product.name
        : product.translation?.name || product.name;

    const productDescription = (
      <ProductDescription
        items={this.props.items}
        productId={product.id}
        name={name}
        productVariants={product.variants}
        pricing={product.pricing}
        addToCart={this.props.add}
        setVariantId={this.setVariantId}
        enableQuoting={product.enableQuoting}
      />
    );

    return (
      <div className="product-page wrapper">
        {/*<TypedHomePageQuery alwaysRender displayLoader={false} errorPolicy="all">*/}
        {/*  {({ data, loading }) => {*/}
        {/*    return(*/}
        {/*      <div className="product-page__categories">*/}
        {/*        <div className="product-page__categories__see_more">*/}
        {/*          <Link*/}
        {/*            to={ generateCategoryUrl(product.category.id, product.category.name)}*/}
        {/*            >                  */}
        {/*              <p className="product-page__categories__see_more__title">                     */}
        {/*              <FormattedMessage*/}
        {/*                id="product_see_all"*/}
        {/*                defaultMessage="VER TODOS"/>*/}
        {/*              </p>                  */}
        {/*          </Link>*/}
        {/*        </div>*/}
        {/*         { data.categories.edges.map(({ node: category }) => (*/}
        {/*          <div key={category.id} className="product-page__categories__wrapper">*/}
        {/*            <Link*/}
        {/*              to={generateCategoryUrl(category.id, category.name)}*/}
        {/*              key={category.id}>                          */}
        {/*              <div */}
        {/*                className={classNames(*/}
        {/*                  "product-page__categories__wrapper__image"*/}
        {/*                )}*/}
        {/*                style={{*/}
        {/*                  backgroundImage: `url(${*/}
        {/*                    category.backgroundImage*/}
        {/*                      ? category.backgroundImage.url*/}
        {/*                      : noPhotoImg*/}
        {/*                  })`,*/}
        {/*                }}*/}
        {/*              ></div>*/}
        {/*              <p className="product-page__categories__wrapper__title">*/}
        {/*              {this.props.intl.locale==="es"?category.name: category.translation?.name || category.name}*/}
        {/*              </p>              */}
        {/*            </Link>*/}
        {/*          </div>            */}
        {/*        ))}*/}
        {/*        {data.categories.edges.length > this.getLimit() && */}
        {/*          <div className="product-page__categories__next-image">*/}
        {/*            <button onClick={this.handleClickEvent }><p>{'>'}</p></button>*/}
        {/*          </div>*/}
        {/*        }*/}
        {/*      </div>             */}
        {/*    );*/}
        {/*  }}*/}
        {/*</TypedHomePageQuery>*/}
        <>
          <Breadcrumbs breadcrumbs={this.populateBreadcrumbs(product)} />
        </>
        <div className="product-page__product_wrapper">
          <div className="product-page__product_wrapper__description">
            <div className="product-page__product__description">
              <h2 className="category-title">
                {this.props.intl.locale === "es"
                  ? product.category.name
                  : product.category.translation?.name || product.category.name}
              </h2>
              <h1 className="product-title">{name}</h1>

              <NewProductDescription
                descriptionJson={
                  this.props.intl.locale === "es"
                    ? product.descriptionJson
                    : product.translation?.descriptionJson ||
                      product.descriptionJson
                }
                attributes={product.attributes}
              />

              <div className="product-page__product__info">
                <div
                  className={classNames("product-page__product__info--fixed")}
                >
                  {productDescription}
                </div>
              </div>
            </div>
          </div>
          <div className="product-page__product_wrapper__image">
            <div className="product-page__product">
              {/* Add script here */}
              <script
                className="structured-data-list"
                type="application/ld+json"
              >
                {structuredData(product)}
              </script>

              {/*  */}
              <Media query={{ maxWidth: smallScreen }}>
                {(matches) =>
                  matches ? (
                    <>
                      {/*  <GalleryCarousel images={this.getImages()} />
                      <div className="product-page__product__info">
                        {productDescription}
                      </div> */}
                      <>
                        <div
                          className="product-page__product__gallery"
                          ref={this.productGallery}
                        >
                          <h1 className="product-title">
                            {this.props.intl.locale === "es"
                              ? product.name
                              : product.translation?.name || product.name}
                          </h1>
                          <h2 className="category-title">
                            {}
                            {this.props.intl.locale === "es"
                              ? product.category.name
                              : product.category.translation?.name ||
                                product.category.name}
                          </h2>
                          <ProductGallery images={this.getImages()} />
                        </div>
                      </>
                    </>
                  ) : (
                    <>
                      <div
                        className="product-page__product__gallery"
                        ref={this.productGallery}
                      >
                        <h1 className="product-title">
                          {this.props.intl.locale === "es"
                            ? product.name
                            : product.translation?.name || product.name}
                        </h1>
                        <h2 className="category-title">
                          {}
                          {this.props.intl.locale === "es"
                            ? product.category.name
                            : product.category.translation?.name ||
                              product.category.name}
                        </h2>

                        <ProductGallery images={this.getImages()} />

                        {/* Show this button only when the product has a technical sheet for download */}
                        {product.datasheet && (
                          <div>
                            <AddToCartButton
                              onClick={() => this.downloadDatasheet(product)}
                              className="button__download"
                            >
                              <FormattedMessage
                                id="download_sheet"
                                defaultMessage="Descargar la ficha técnica"
                              />
                            </AddToCartButton>
                          </div>
                        )}
                      </div>
                    </>
                  )
                }
              </Media>
              {product.datasheet && (
                <div>
                  <AddToCartButton
                    onClick={() => this.downloadDatasheet(product)}
                    className="button__download2"
                  >
                    <FormattedMessage
                      id="download_sheet"
                      defaultMessage="Descargar la ficha técnica"
                    />
                  </AddToCartButton>
                </div>
              )}
            </div>

            {videoId !== "" && (
              <div className="product-page__video_container">
                <iframe
                  width="560"
                  height="315"
                  src={`https://www.youtube.com/embed/${videoId}`}
                  title="YouTube video player"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </div>
        </div>

        <OtherProducts products={product.category.products.edges} />
      </div>
    );
  }
}

export default injectIntl(Page);
