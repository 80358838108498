import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
import { IProps } from "./types";
import Modalmessage from "../../../../components/Modalmessage";
import icon from "../../../../images/bxs-cart 1.svg";
import sendImg from "../../../../images/bxs-package 1.svg";
/**
 * Thank you page after completing the checkout.
 */
const ThankYou: React.FC<IProps> = ({
  orderNumber,
  continueShopping,
  orderDetails,
}: IProps) => {
  const [modalOpen, setmodalOpen] = useState(true);
  const CloseModal = () => {
    setmodalOpen(false);
  };

  return (
    <Fragment>
      <Modalmessage
        show={modalOpen}
        title={[
          <Fragment>
            <p>
              <FormattedMessage
                id="thankyou_purchase"
                defaultMessage="¡Gracias por su compra!"
              />
            </p>
            <img
              width="50px"
              className="modalMessage__icon"
              height="50px"
              src={icon}
            ></img>
          </Fragment>,
        ]}
        hide={CloseModal}
        children={
          <Fragment>
            <FormattedMessage
              id="we_are_pleased"
              defaultMessage="Nos complace ser parte de la seguridad de su proyecto."
            />
            <br></br>
            <br></br>
            <br></br>
            <FormattedMessage
              id="thankyou_number_order"
              defaultMessage="Número de orden"
            />
            : {orderNumber}
            <br></br>
            <br></br>
            <FormattedMessage
              id="order_arrive"
              defaultMessage="¡Su pedido llegará pronto!"
            />
            <img
              className="modalMessage__image"
              width="200px"
              height="190px"
              src={sendImg}
            ></img>
          </Fragment>
        }
      ></Modalmessage>
      <Container>
        <S.Wrapper>
          <S.ThankYouHeader>
            <FormattedMessage id="thankyou_thanks" defaultMessage="!Gracias" />
            <br />
            <span>
              <FormattedMessage
                id="thankyou_for_order"
                defaultMessage="por tu compra!"
              />
            </span>
          </S.ThankYouHeader>
          <S.Paragraph>
            <FormattedMessage
              id="thankyou_number_order"
              defaultMessage="Tu número de orden es "
            />
            <span>{orderNumber}</span>
          </S.Paragraph>
          <S.Paragraph>
            <FormattedMessage
              id="thankyou_we_email_you"
              defaultMessage="Te hemos envíado una orden de confirmación por email, y te notificaremos cuando la orden haya sido enviada"
            />
          </S.Paragraph>
          <S.Buttons>
            <Button
              onClick={continueShopping}
              color="secondary"
              fullWidth={true}
            >
              <FormattedMessage
                id="thankyou_continue_shopping"
                defaultMessage="Continuar comprando"
              />
            </Button>
            <Button onClick={orderDetails} fullWidth={true}>
              <FormattedMessage
                id="thankyou_order_details"
                defaultMessage="Detalles de Orden"
              />
            </Button>
          </S.Buttons>
        </S.Wrapper>
      </Container>
    </Fragment>
  );
};

export { ThankYou };
