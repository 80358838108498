import "./scss/index.scss";

import * as React from "react";
import whatsappIcon from "../../images/whatsapp.png"

const WhatsappButton: React.FC = () => (
    <div className="whatsapp__container">
        <a href="https://api.whatsapp.com/send?phone=528183877010" target="_blank">
          <img src={whatsappIcon} alt="whatsapp" className="whatsapp__img"/>
        </a>
    </div>
);

export default WhatsappButton;
