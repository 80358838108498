import React, { useCallback } from "react";
import { useIntl } from "react-intl";

import { TextField } from "@components/molecules";

import * as S from "./styles";
import { PropsWithFormik } from "./types";

export const BillingDataFormContent: React.FC<PropsWithFormik> = ({
  formRef,
  handleChange,
  handleBlur,
  formId,
  errors,
  handleSubmit,
  values,
}) => {
  const basicInputProps = useCallback(
    () => ({ onBlur: handleBlur, onChange: handleChange }),
    [handleChange, handleBlur]
  );

  const fieldErrors: any = {};
  const intl = useIntl();

  return (
    <S.AddressForm id={formId} ref={formRef} onSubmit={handleSubmit}>
      <S.Wrapper>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormFirstName"
            name="businessName"
            label={intl.formatMessage({
              defaultMessage: "Razón social",
              id: "addressform_businessName",
            })}
            value={values!.businessName || ""}
            autoComplete="given-name"
            errors={fieldErrors!.businessName}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormLastName"
            name="RFC"
            label={"RFC"}
            value={values!.RFC || ""}
            autoComplete="family-name"
            errors={fieldErrors!.RFC}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormCompanyName"
            name="taxRegime"
            label={intl.formatMessage({
              defaultMessage: "Regimen fiscal",
              id: "addressform_taxRegime",
            })}
            value={values!.taxRegime || ""}
            autoComplete="organization"
            errors={fieldErrors!.taxRegime}
            {...basicInputProps()}
          />

          <TextField
            data-cy="addressFormLastName"
            name="CFDI"
            label={intl.formatMessage({
              defaultMessage: "Uso de CFDI",
              id: "addressform_cdfi",
            })}
            value={values!.CFDI || ""}
            autoComplete="family-name"
            errors={fieldErrors!.CFDI}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormCity"
            name="payment_method"
            label={intl.formatMessage({
              defaultMessage: "Forma de pago",
              id: "payment_method",
            })}
            value={values!.payment_method || ""}
            autoComplete="address-level1"
            errors={fieldErrors!.payment_method}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormPhone"
            name="phone"
            label={intl.formatMessage({
              defaultMessage: "Teléfono",
              id: "addressform_phone",
            })}
            value={values!.phone || ""}
            autoComplete="tel"
            errors={fieldErrors!.phone}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
      </S.Wrapper>
      {errors && <p style={{color: '#c22d74' ,fontSize: "0.75rem"}}>{errors}</p>}
    </S.AddressForm>
  );
};
