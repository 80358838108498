import { media, styled } from "@styles";

export const Wrapper = styled.div`
  margin: 30px 0 100px 0;
`;

export const Breadcrumbs = styled.div``;

export const Title = styled.div`
  margin-bottom: 60px;
  h1{
    font-family: "DIN Pro";
    font-weight: 500;
    font-size: 48px;
  }
  
  ${media.smallScreen`
    h1 {
      font-size: 30px;
    }
  `};
`;

export const CartHeader = styled.div`
  ${media.mediumScreen`
    display: none;
  `};
`;
export const CartFooter = styled.div``;
export const Cart = styled.div`
  border-top: 1px solid rgba(50, 50, 50, 0.1);
`;
export const ProceedButton = styled.div`
  text-align: right;
  width:50%;
  padding: 1.5rem 0;
  
  button {
    background-color: black;
    border-radius: 64px;
  }
`;

export const ProceedButtonContainer = styled.div`
  display: flex;
  margin-top: 40px;
`;

export const Continue = styled.div`
  width:50%;
  padding: 1.5rem 0;

  a {
    font-family: "DIN Pro";
    font-weight: bold;
    font-size: 14px;
    text-decoration-line: underline;
  }
`;
