import React from "react";
import {  FormattedMessage } from "react-intl";

import * as S from "./styles";

/**
 * Cart header to use with conjunction of cart rows.
 */
const CartHeader: React.FC = () => {
  return (
    <S.Wrapper>
      <S.Column>        
        <FormattedMessage
          id="product"
          defaultMessage="Artículo"/>
      </S.Column>
      <S.Column>        
        <FormattedMessage
          id="cartheader_description"
          defaultMessage="Descripción"/>
      </S.Column>
      <S.Column>        
        <FormattedMessage
          id="price"
          defaultMessage="Precio"/>
      </S.Column>
      <S.Column>
        <FormattedMessage
          id="quantity"
          defaultMessage="Cantidad"/>
      </S.Column>
      <S.Column>
        <FormattedMessage
          id="total_price"
          defaultMessage="Precio Total"/>
      </S.Column>
    </S.Wrapper>
  );
};

export { CartHeader };
