import React from "react";
import { useIntl } from "react-intl";

import { RichTextContent } from "@components/atoms";

import * as S from "./styles";
import { IProps } from "./types";

export const ProductDescription: React.FC<IProps> = ({
  description = "",
  descriptionJson = "",
  attributes,
}: IProps) => {
  const intl = useIntl();

  return (
    <S.Wrapper>
      {descriptionJson ? (
        <S.Description>
          <RichTextContent descriptionJson={descriptionJson} />
        </S.Description>
      ) : (
        <S.Description>
          <p>{description}</p>
        </S.Description>
      )}
      {
        <S.AttributeList>
          {attributes &&
            attributes.map((attribute, index) => (
              <li key={index}>
                <S.AttributeName>
                  {intl.locale === "es"
                    ? attribute.attribute.name
                    : attribute.attribute.translation?.name ||
                      attribute.attribute.name}
                  :{" "}
                </S.AttributeName>{" "}
                <S.AttributeValue>
                  {attribute.values
                    .map((value) =>
                      intl.locale === "es"
                        ? value.name
                        : value.translation?.name || value.name
                    )
                    .join(", ")}
                </S.AttributeValue>
              </li>
            ))}
        </S.AttributeList>
      }
    </S.Wrapper>
  );
};
