import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button } from "@components/atoms";
import { TextField } from "../TextField";

import * as S from "./styles";
import { IProps } from "./types";

export const ResetPasswordForm: React.FC<IProps> = ({
  handleBlur,
  handleChange,
  handleSubmit,
  values,
  tokenError,
  passwordError,
  errors,
}: IProps) => {
  const intl = useIntl();
  return (
    <S.Wrapper>
      <h3>
        <FormattedMessage
          id="resetpassword_reset"
          defaultMessage="Retablece tu contraseña"/>
      </h3>

      <p>
        <FormattedMessage
          id="resetpassword_new_password"
          defaultMessage="Por favor ingresa tu nueva contraseña"/>
      </p>
      {tokenError && (
        <S.GeneralError>
          <FormattedMessage
            id="resetpassword_bad_token"
            defaultMessage="Parece que la clave para restablecer tu contraseña ya no es válida"/>
        </S.GeneralError>
      )}
      <form onSubmit={handleSubmit}>
        <S.InputFields>
          <TextField
            label={intl.formatMessage({id:"password",defaultMessage:"Contraseña"})}
            name="password"
            onBlur={handleBlur}
            onChange={handleChange}
            type="password"
            value={values.password}
            errors={
              errors.password || passwordError
                ? [
                    {
                      field: "password",
                      message: errors.password || passwordError,
                    },
                  ]
                : undefined
            }
          />
          <TextField
            label={intl.formatMessage({id:"password_retype",defaultMessage:"Vuelve a escribir tu contraseña"})}
            onBlur={handleBlur}
            name="retypedPassword"
            onChange={handleChange}
            type="password"
            value={values.retypedPassword}
            errors={
              errors.retypedPassword
                ? [
                    {
                      field: "retypedPassword",
                      message: errors.retypedPassword,
                    },
                  ]
                : undefined
            }
          />
        </S.InputFields>

        <Button type="submit" fullWidth={true}>
          <FormattedMessage
            id="resetpassword_set_new_password"
            defaultMessage="Establecer nueva contraseña"/>
        </Button>
      </form>
    </S.Wrapper>
  );
};
