import "./scss/index.scss";

import React, {useState} from "react";
import {FormattedMessage} from "react-intl";
import ReactSVG from "react-svg";

import arrowRight from "../../images/arrow_forward_red.png"
import actualidadImg from "../../images/Historia-actualidad.jpg";
import expansionImg from "../../images/Historia-expancion.jpg";
import fabricantesImg from "../../images/Historia-fabricantes.jpg";
import fundacionImg from "../../images/Historia1.jpg";
import importacionImg from "../../images/Historia2.jpg";
import timelineImg from "../../images/timeline.svg"

const Nosotros: React.FC = () => {

  const [selectedYear, setSelectedYear] = useState(1998);

  // History contents per year components

  const Year1998 = () => (
    <div className="history-content-inner">
      <img src={fundacionImg} alt="placeholder" className="history-image" width="372px" height="235"/>
      <div>
        <h4 className="history-content__subtitle">
          <FormattedMessage
            id="home_history_1998_title"
            defaultMessage="Fundación"/>
        </h4>
        <div className="history-content__description">
          <p>
            <FormattedMessage
              id="home_history_1998_description_1"
              defaultMessage="El "/>
            <strong>
              <FormattedMessage
                id="home_history_1998_description_2"
                defaultMessage="Ing.RAMON MORENO "/>
            </strong>
            <FormattedMessage
              id="home_history_1998_description_3"
              defaultMessage="fundó "/>
            <strong>
              <FormattedMessage
                id="home_history_1998_description_4"
                defaultMessage="Grupo AEMA."/>
              <br/>
            </strong>
            <FormattedMessage
              id="home_history_1998_description_5"
              defaultMessage="Desde el inicio demostró su capacidad en los negocios comercializando diversos productos,
              siendo los sellos de seguridad los que tomaron mayor relevancia. "/>
          </p>
          <p>
            <FormattedMessage
              id="home_history_1998_description_6"
              defaultMessage="En ese momento, sus
              actividades comerciales se limitaban a la región del estado de Nuevo León"/>
          </p>
        </div>
      </div>
    </div>
  )

  const Year2005 = () => (
    <div className="history-content-inner">
      <img src={importacionImg} alt="placeholder" className="history-image" width="372px" height="235"/>
      <div>
        <h4 className="history-content__subtitle">
          <FormattedMessage
            id="home_history_2005_title"
            defaultMessage="IMPORTACIÓN"/>
        </h4>
        <div className="history-content__description">
          <p>
            <FormattedMessage
              id="home_history_2005_description_1"
              defaultMessage="Nuestro personal demostró su gran capacidad, pues a pesar de ser pocos, nuestra
              demanda de productos aumentó, lo que nos permitió establecer nuestros primeros lazos
              internacionales importando productos de alta calidad y mejor precio."/>

          </p>
          <p>
            <FormattedMessage
              id="home_history_2005_description_2"
              defaultMessage="Además, deseosos de
              ofrecer cada vez mejores servicios y productos, adquirimos nuestra primera tecnología de
              impresión y personalización tipo "/>
            <strong>
              <FormattedMessage
                id="home_history_2005_description_3"
                defaultMessage="HOT STAMPING "/>
            </strong>
            <FormattedMessage
              id="home_history_2005_description_4"
              defaultMessage=" (Termo Transferencia)."/>
          </p>
        </div>
      </div>
    </div>
  )

  const Year2010 = () => (
    <div className="history-content-inner">
      <img src={fabricantesImg} alt="placeholder" className="history-image" width="372px" height="235"/>
      <div>
        <h4 className="history-content__subtitle">
          <FormattedMessage
            id="home_history_2010_title"
            defaultMessage="FABRICANTES"/>
        </h4>
        <div className="history-content__description">
          <p>
            <FormattedMessage
              id="home_history_2010_description_1"
              defaultMessage="Con la intención de seguir ofreciendo los mejores precios, productos y servicios, dimos un
              gran paso al comenzar a fabricar nuestros propios productos, ofreciendo así una mejor
              calidad, mejores tiempos de entrega y mejores precios."/>

          </p>
          <p>
            <FormattedMessage
              id="home_history_2010_description_2"
              defaultMessage="Este año también empezamos a
              revolucionar nuestras tecnologías de personalización y foliado; ofreciendo el "/>
            <strong>
              <FormattedMessage
                id="home_history_2010_description_3"
                defaultMessage="MARCAJE LÁSER "/>
            </strong>
            <FormattedMessage
              id="home_history_2010_description_4"
              defaultMessage="como otra de nuestras opciones."/>
          </p>
          <p>
            <FormattedMessage
              id="home_history_2010_description_5"
              defaultMessage="Teníamos claro que íbamos en la dirección correcta."/>
          </p>
        </div>
      </div>
    </div>
  )

  const Year2015 = () => (
    <div className="history-content-inner">
      <img src={expansionImg} alt="placeholder" className="history-image" width="372px" height="235"/>
      <div>
        <h4 className="history-content__subtitle">
          <FormattedMessage
            id="home_history_2015_title"
            defaultMessage="EXPANSIÓN"/>
        </h4>
        <div className="history-content__description">
          <p>
            <FormattedMessage
              id="home_history_2015_description_1"
              defaultMessage="Gracias al compromiso y capacidad de nuestro personal, así como la calidad de nuestros
              productos, la cantidad de clientes y productos aumentó, lo que nos llevó a consolidar e
              incrementar nuestra capacidad de fabricación; así mismo nuestra tecnología de marcaje
              láser se volvió una de las más grandes y eficientes del país."/>

          </p>
        </div>
      </div>
    </div>
  )
  const Year2022 = () => (
    <div className="history-content-inner">
      <img src={actualidadImg} alt="placeholder" className="history-image" width="372px" height="235"/>
      <div>
        <h4 className="history-content__subtitle">
          <FormattedMessage
            id="home_history_2022_title"
            defaultMessage="ACTUALIDAD"/>
        </h4>
        <div className="history-content__description">
          <p>
            <FormattedMessage
              id="home_history_2022_description_1"
              defaultMessage="Actualmente contamos con más de 150 personas que forman parte de la Familia AEMA. Formando así parte de los "/>
            <strong>
              <FormattedMessage
                id="home_history_2022_description_2"
                defaultMessage="LÍDERES NACIONALES "/>
            </strong>
            <FormattedMessage
              id="home_history_2022_description_3"
              defaultMessage="en el mercado."/>
          </p>
          <p>
            <FormattedMessage
              id="home_history_2022_description_4"
              defaultMessage="Consolidandonos como
              uno de los mayores fabricantes en el país; distribuyendo nuestros productos dentro y fuera
              de México; contando también con una de las "/>
            <strong>
              <FormattedMessage
                id="home_history_2022_description_5"
                defaultMessage="INFRAESTRUCTURAS MÁS GRANDES Y DE VANGUARDIA "/>
            </strong>
            <FormattedMessage
              id="home_history_2022_description_6"
              defaultMessage=" del mercado, que actualmente sigue en franco crecimiento, esto con la
              finalidad de seguir ofreciendo lo que nuestros clientes tanto quieren, "/>
            <strong>
              <FormattedMessage
                id="home_history_2022_description_7"
                defaultMessage="CALIDAD Y BUEN PRECIO."/>
            </strong>
          </p>
        </div>
      </div>
    </div>
  )

  return (
    <div className="us-container">
      <div className="wrapper">
        <div className="home-page__section-title-container">
          <h2 className="home-page__section-title">
            <FormattedMessage
              id="home_history"
              defaultMessage="Nosotros"/>
          </h2>
        </div>
        <div className="us-layout">
          <div className="us-history-container"  data-aos="fade-right">
            <div>
              <h3 className="home-page__section-title title-mobile">
                <FormattedMessage
                  id="home_history_title"
                  defaultMessage="Nuestra historia"/>
              </h3>
            </div>
            <div className="timeline timeline-mobile">
              <div className="line">
              </div>
              <div className="story-points story-points-mobile ">
                <button className="history-point year_1998" onClick={() => setSelectedYear(1998)}>1998</button>
                <button className="history-point year_2005" onClick={() => setSelectedYear(2005)}>2005</button>
                <button className="history-point year_2010" onClick={() => setSelectedYear(2010)}>2010</button>
                <button className="history-point year_2015" onClick={() => setSelectedYear(2015)}>2015</button>
                <button className="history-point year_2022" onClick={() => setSelectedYear(2022)}>2022</button>
              </div>
            </div>
            <div className="us-history">
              <div className="history">
                <div className="timeline">
                  <div className="line">
                    <ReactSVG path={timelineImg}/>
                  </div>
                  <div className="story-points">
                    <button className="history-point year_1998" onClick={() => setSelectedYear(1998)}>1998</button>
                    <button className="history-point year_2005" onClick={() => setSelectedYear(2005)}>2005</button>
                    <button className="history-point year_2010" onClick={() => setSelectedYear(2010)}>2010</button>
                    <button className="history-point year_2015" onClick={() => setSelectedYear(2015)}>2015</button>
                    <button className="history-point year_2022" onClick={() => setSelectedYear(2022)}>2022</button>
                  </div>
                </div>
                <div className="history-content">
                  <h3 className="title">
                    <FormattedMessage
                      id="home_history_title"
                      defaultMessage="Nuestra historia"/>
                  </h3>
                  {selectedYear === 1998 ? (<Year1998/>)
                    : selectedYear === 2005 ? (<Year2005/>)
                      : selectedYear === 2010 ? (<Year2010/>)
                        : selectedYear === 2015 ? (<Year2015/>)
                          : selectedYear === 2022 ? (<Year2022/>) : (<Year1998/>)
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="us-company" data-aos="fade-left">
            <div className="us-company-item">
              <div>
                <img src={arrowRight} alt="arrow" className="us-company-arrow"/>
              </div>
              <div>
                <h4 className="us-company-title">
                  <FormattedMessage
                    id="home_history-mission-title"
                    defaultMessage="MISIÓN"/>
                </h4>
                <p>
                  <FormattedMessage
                    id="home_history-mission-description"
                    defaultMessage="Garantizar con nuestros productos que los bienes de nuestros clientes conserven su integridad durante su traslado o almacenamiento."/>
                </p>
              </div>
            </div>
            <div className="us-company-hr"/>
            <div className="us-company-item">
              <div>
                <img src={arrowRight} alt="arrow" className="us-company-arrow"/>
              </div>
              <div>
                <h4 className="us-company-title">
                  <FormattedMessage
                    id="home_history-vision-title"
                    defaultMessage="VISIÓN"/>
                </h4>
                <p>
                  <FormattedMessage
                    id="home_history-vision-description"
                    defaultMessage="Ofrecer seguridad en los distintos ámbitos de la vida y los negocios de nuestros clientes."/>
                </p>
              </div>
            </div>
            <div className="us-company-hr"/>
            <div className="us-company-item">
              <div>
                <img src={arrowRight} alt="arrow" className="us-company-arrow"/>
              </div>
              <div>
                <h4 className="us-company-title">
                  <FormattedMessage
                    id="home_history-values-title"
                    defaultMessage="VALORES"/>
                </h4>
                <p>
                  <strong>Grupo AEMA </strong>
                  <FormattedMessage
                    id="home_history-values-description-1"
                    defaultMessage="se crea bajo los preceptos del valor fundamental de toda sociedad humana, LA FAMILIA."/>
                </p>
                <p>
                  <FormattedMessage
                    id="home_history-values-description-2"
                    defaultMessage="Respetamos y practicamos los valores que de ella se desprenden con nuestro equipo, y nuestros clientes."/>
                </p>
                <p>
                  <FormattedMessage
                    id="home_history-values-description-3"
                    defaultMessage="Conscientes que haciéndolo así seguiremos consolidando nuestro liderazgo en el mercado con nuestra calidad, servicio y experiencia."/>
                </p>
                <p>
                  <strong>
                    <FormattedMessage
                      id="home_history-values-description-4"
                      defaultMessage="Una empresa esta formada por seres humanos, y cada ser humano esta formado por una familia."/>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}


export default Nosotros;
