import * as React from "react";
import { FormattedMessage } from "react-intl";

export const NothingFound: React.FC<{ search: string }> = ({ search }) => (
  <div className="search__products--not-found">
    <p className="u-lead u-lead--bold u-uppercase">
      <FormattedMessage
        id="search_dont_find"
        defaultMessage="Lo sentimos, no pudimos encontrar los resultados que buscas para"/>
      : {search}
    </p>
    <p>
      <FormattedMessage
        id="search_check_spelling"
        defaultMessage="No te preocupes - revisa la ortografía o piensa algo menos especifíco y busca de nuevo."/>
    </p>
  </div>
);

export default NothingFound;
