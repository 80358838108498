import React from "react";

import { InputSelect } from "@components/molecules";
import { useSelectableProductVariantsAttributeValues } from "@hooks";
import { ProductDetails_product_variants } from "@sdk/queries/gqlTypes/ProductDetails";
import {
  IProductVariantsAttribute,
  IProductVariantsAttributesSelectedValues,
} from "@types";

import * as S from "./styles";

export const ProductVariantAttributeSelect: React.FC<{
  selectSidebar: boolean;
  selectSidebarTarget?: HTMLElement | null;
  productVariantsAttributeId: string;
  productVariants: ProductDetails_product_variants[];
  productVariantsAttribute: IProductVariantsAttribute;
  productVariantsAttributesSelectedValues: IProductVariantsAttributesSelectedValues;
  onChangeSelection: (value: any, name?: any) => void;
  onClearSelection: () => void;
}> = ({
  selectSidebar = false,
  selectSidebarTarget,
  productVariantsAttributeId,
  productVariants,
  productVariantsAttribute,
  productVariantsAttributesSelectedValues,
  onChangeSelection,
  onClearSelection,
}) => {
  const selectableProductVariantsAttributeValues = useSelectableProductVariantsAttributeValues(
    productVariantsAttributeId,
    productVariants,
    productVariantsAttributesSelectedValues
  );

  const selectedValue = productVariantsAttributesSelectedValues &&
    productVariantsAttributesSelectedValues[productVariantsAttributeId] && {
      disabled: false,
      id: productVariantsAttributesSelectedValues[productVariantsAttributeId]!
        .id,
      label: productVariantsAttributesSelectedValues[
        productVariantsAttributeId
      ]!.name!,
      value: productVariantsAttributesSelectedValues[
        productVariantsAttributeId
      ]!.value!,
    };

  const attributeOptions = productVariantsAttribute.values
    .filter((value) => value)
    .map((value) => {
      const isOptionDisabled =
        selectableProductVariantsAttributeValues[productVariantsAttributeId] &&
        !selectableProductVariantsAttributeValues[
          productVariantsAttributeId
        ].values.includes(value);

      return {
        disabled: isOptionDisabled,
        id: value.id,
        label: value.name!,
        value: value.value!,
      };
    });

  const selectLabel = productVariantsAttribute.attribute.name
    ? productVariantsAttribute.attribute.name!
    : "";

  return (
    <S.Select>
      <InputSelect
        name={productVariantsAttribute.attribute.id}
        label={selectLabel}
        value={selectedValue}
        options={attributeOptions}
        isOptionDisabled={(optionValue) => optionValue.disabled}
        onChange={(optionValue) =>
          onChangeSelection(optionValue && optionValue.value)
        }
        clearable={true}
        clearValue={onClearSelection}
      />
    </S.Select>
  );
};
