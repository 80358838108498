import React, { useCallback } from "react";
import { useIntl } from "react-intl";

import { InputSelect, TextField } from "@components/molecules";

import * as S from "./styles";
import { PropsWithFormik } from "./types";

import cities from "@temp/@next/utils/cities";

export const AddressFormContent: React.FC<PropsWithFormik> = ({
  formRef,
  handleChange,
  handleBlur,
  formId,
  errors,
  handleSubmit,
  values,
  countriesOptions,
  defaultValue,
  setFieldValue,
  includeEmail = false,
}) => {
  const basicInputProps = useCallback(
    () => ({ onBlur: handleBlur, onChange: handleChange }),
    [handleChange, handleBlur]
  );

  const fieldErrors: any = {};
  const intl = useIntl();

  if (errors) {
    errors.map(({ field, message }: { field: string; message: string }) => {
      fieldErrors[field] = fieldErrors[field]
        ? [
            ...fieldErrors[field],
            {
              message: message.includes("required")
                ? message
                : intl.formatMessage({
                    defaultMessage: "Campo requerido",
                    id: "field_required",
                  }),
            },
          ]
        : [{ message }];
    });
  }

  return (
    <S.AddressForm id={formId} ref={formRef} onSubmit={handleSubmit}>
      <S.Wrapper>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormFirstName"
            name="firstName"
            label={intl.formatMessage({
              defaultMessage: "Nombre(s)",
              id: "addressform_name",
            })}
            value={values!.firstName || ""}
            autoComplete="given-name"
            errors={fieldErrors!.firstName}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormLastName"
            name="lastName"
            label={intl.formatMessage({
              defaultMessage: "Apellidos",
              id: "addressform_last_name",
            })}
            value={values!.lastName || ""}
            autoComplete="family-name"
            errors={fieldErrors!.lastName}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormCompanyName"
            name="companyName"
            label={intl.formatMessage({
              defaultMessage: "Empresa (Opcional)",
              id: "addressform_company",
            })}
            value={values!.companyName || ""}
            autoComplete="organization"
            errors={fieldErrors!.companyName}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormPhone"
            name="phone"
            label={intl.formatMessage({
              defaultMessage: "Teléfono",
              id: "addressform_phone",
            })}
            value={values!.phone || ""}
            autoComplete="tel"
            errors={fieldErrors!.phone}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithOneCell>
          <TextField
            data-cy="addressFormStreetAddress1"
            name="streetAddress1"
            label={intl.formatMessage({
              defaultMessage: "Dirección 1",
              id: "addressform_address1",
            })}
            value={values!.streetAddress1 || ""}
            autoComplete="address-line1"
            errors={fieldErrors!.streetAddress1}
            {...basicInputProps()}
          />
        </S.RowWithOneCell>
        <S.RowWithOneCell>
          <TextField
            data-cy="addressFormStreetAddress2"
            name="streetAddress2"
            label={intl.formatMessage({
              defaultMessage: "Dirección 2",
              id: "addressform_address2",
            })}
            value={values!.streetAddress2 || ""}
            autoComplete="address-line2"
            errors={fieldErrors!.streetAddress2}
            {...basicInputProps()}
          />
        </S.RowWithOneCell>
        <S.RowWithTwoCells>
          <TextField
            data-cy="addressFormCity"
            name="city"
            label={intl.formatMessage({
              defaultMessage: "Ciudad",
              id: "addressform_city",
            })}
            value={values!.city || ""}
            autoComplete="address-level1"
            errors={fieldErrors!.city}
            {...basicInputProps()}
          />
          <TextField
            data-cy="addressFormPostalCode"
            name="postalCode"
            label={intl.formatMessage({
              defaultMessage: "Código Postal",
              id: "addressform_zip_code",
            })}
            value={values!.postalCode || ""}
            autoComplete="postal-code"
            errors={fieldErrors!.postalCode}
            {...basicInputProps()}
          />
        </S.RowWithTwoCells>
        <S.RowWithTwoCells>
          <InputSelect
            inputProps={{
              "data-cy": "addressFormCountry",
            }}
            defaultValue={defaultValue}
            label={intl.formatMessage({
              defaultMessage: "País",
              id: "addressform_country",
            })}
            name="country"
            options={countriesOptions}
            value={
              values!.country &&
              countriesOptions &&
              countriesOptions!.find(
                (option) => option.code === values!.country!.code
              )
            }
            onChange={(value: any, name: any) => setFieldValue(name, value)}
            optionLabelKey="country"
            optionValueKey="code"
            errors={fieldErrors!.country}
            withMargin={false}
          />

          {values?.country === undefined ? null : values?.country.code ===
              "MX" ? (
            <>
              <InputSelect
                data-cy="addressFormCountryArea"
                value={
                  values!.countryArea && cities.find((option) => option.value === values!.countryArea)
                }
                autoComplete="address-level2"
                label={intl.formatMessage({
                  defaultMessage: "Estado/Provincia",
                  id: "addressform_state",
                })}
                name="countryArea"
                errors={fieldErrors!.countryArea}
                options={cities}
                onChange={(value: any, name: any) => {
                  setFieldValue(name, value.value);
                }}
                withMargin={false}
              />
            </>
          ) : (
            <TextField
              data-cy="addressFormCountryArea"
              name="countryArea"
              label={intl.formatMessage({
                defaultMessage: "Estado/Provincia",
                id: "addressform_state",
              })}
              value={typeof values!.countryArea === "string" ? values!.countryArea : ""}
              autoComplete="address-level2"
              errors={fieldErrors!.countryArea}
              {...basicInputProps()}
            />
          )}
        </S.RowWithTwoCells>
        {includeEmail && (
          <S.RowWithTwoCells>
            <TextField
              data-cy="addressFormEmail"
              name="email"
              label={intl.formatMessage({
                defaultMessage: "Correo Electrónico",
                id: "email",
              })}
              value={values!.email || ""}
              autoComplete="email"
              errors={
                fieldErrors!.email
                  ? fieldErrors!.email
                  : "" || fieldErrors!.quantity
                  ? fieldErrors!.quantity
                  : ""
              }
              {...basicInputProps()}
            />
          </S.RowWithTwoCells>
        )}
      </S.Wrapper>
    </S.AddressForm>
  );
};
