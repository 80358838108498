import { styled } from "@styles";
import { css } from "styled-components";

const textProps = css`
  font-size: ${props => props.theme.typography.baseFontSize};
  text-align: left;
`;

export const Wrapper = styled.div`
  text-align: center;
  transition: 0.3s;
  //display: flex;
  //flex-direction: column;
  //justify-content: flex-end;
`;

export const TitleWrapper = styled.div`
  margin-top: 10px;
  display: flex;
`;

export const AddCart = styled.div` 
  width: 28px;
  height: 28px;
  background-color: black;
  border-radius: 50px;
  display: flex;  
  align-items: center;
  justify-content: center;
  color: white;
`;

export const Title = styled.h4`
  text-transform: uppercase;  
  font-family: DIN Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color: #0A2057;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  ${textProps}
`;

export const Price = styled.p`
  font-family: DIN Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  color:#BDBDBD;
  ${textProps}
`;

export const Image = styled.div`
  width: 100%;
  overflow: hidden; /* [1.2] Hide the overflowing of child elements */
  background-color: #F1F1F1;
  padding-top: 130%;
  position: relative;
  

  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    transition: transform .5s ease;

  }

`;

export const Description = styled.div`
  text-align: start;
  font-size: 1rem;
  color: #585858;
  line-height: 22px;
  margin-top: 32px;

  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const ButtonShop = styled.button`
  max-width: 266px;
  width: 100%;
  margin: 28px auto 0;
  height: 74px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #0A2057;
  font-size: 20px;
  font-weight: bold;
  color: #0A2057;
  text-transform: uppercase;
  

  &:hover {
    background-color: #0A2057;
    color: #FFF;
  }

  @media (max-width: $small-screen) {
    font-size: 1rem;
    width: 132px;
    height: 42px;
    margin: 28px auto;
  }

`
