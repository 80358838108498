import "./scss/index.scss";

import classNames from "classnames";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";
import {
  ProductsList_categories,
  ProductsList_shop,
  ProductsList_shop_homepageCollection_backgroundImage,
} from "./gqlTypes/ProductsList";

import { Nosotros } from "@temp/components";
import ContactForm from "../ContactForm";

import { structuredData } from "../../core/SEO/Homepage/structuredData";
import { generateCategoryUrl } from "../../core/utils";
import arrowIcon from "../../images/arrow-right.png";
import contactUsBackground from "../../images/contact-us-bg.png";
import mapImg from "../../images/mapaMexico.png";
import noPhotoImg from "../../images/no-photo.svg";

const Page: React.FC<{
  loading: boolean;
  categories: ProductsList_categories;
  backgroundImage: ProductsList_shop_homepageCollection_backgroundImage;
  shop: ProductsList_shop;
}> = ({ loading, categories, backgroundImage, shop }) => {
  const categoriesExist = () => {
    return categories && categories.edges && categories.edges.length > 0;

  };
  const intl = useIntl();
  // const [services, setServices] = React.useState(true);

  const [showContactForm, setShowContactForm] = useState(false);

  const productList = []

  function sortName(a, b, c, d, e, f) {
    productList.push(a);
    productList.push(f);
    productList.push(e);
    productList.push(b);
    productList.push(c);
    productList.push(d);
  }

  return (
    <>
      <script className="structured-data-list" type="application/ld+json">
        {structuredData(shop)}
      </script>
      <div className="home-page__hero">
        <div className="home-page__phone_titles">
          <div>
            <span className="home-page__hero__title">
              <h2 className="header-title">
                <FormattedMessage
                  id="home_slide_title_1"
                  defaultMessage="Más"
                />
              </h2>
            </span>
            <span className="home-page__hero__title">
              <h2 className="header-title">
                <FormattedMessage
                  id="home_slide_title_2"
                  defaultMessage="Seguridad,"
                />
              </h2>
            </span>
            <span className="home-page__hero__title">
              <h2 className="header-title">
                <FormattedMessage
                  id="home_slide_title_1"
                  defaultMessage="Más"
                />
              </h2>
            </span>
            <span className="home-page__hero__title">
              <h2 className="header-title">
                <FormattedMessage
                  id="home_slide_title_3"
                  defaultMessage="Tranquilidad."
                />
              </h2>
            </span>
          </div>
        </div>
        <div className="home-page__hero-text wrapper" data-aos="fade-right">
          <div>
            <span className="home-page__hero__title">
              <h2 className="header-title">
                <FormattedMessage
                  id="home_slide_title_1"
                  defaultMessage="más"
                />
              </h2>
            </span>
            <span className="home-page__hero__title">
              <h2 className="header-title">
                <FormattedMessage
                  id="home_slide_title_2"
                  defaultMessage="seguridad,"
                />
              </h2>
            </span>
            <span className="home-page__hero__title">
              <h2 className="header-title">
                <FormattedMessage
                  id="home_slide_title_1"
                  defaultMessage="más"
                />
              </h2>
            </span>
            <span className="home-page__hero__title">
              <h2 className="header-title">
                <FormattedMessage
                  id="home_slide_title_3"
                  defaultMessage="tranquilidad."
                />
              </h2>
            </span>
          </div>
        </div>
      </div>
      <section id="historia">
        <Nosotros />
      </section>
      <section className="home-section">
        <div className="home-page__section-title-container">
          <h2 className="home-page__section-title">
            <FormattedMessage id="home_products" defaultMessage="Productos" />
          </h2>
        </div>
        <div className="wrapper">
          {categoriesExist() && (
            <div className="home-section__products">
              { 
                  sortName(
                    categories.edges[0],
                    categories.edges[1],
                    categories.edges[2],
                    categories.edges[3],
                    categories.edges[4],
                    categories.edges[5]
                  )}
              {productList.map(({ node: category }) => (
                <div
                  className="home-section__products__wrapper"
                  key={category.id}
                >
                  <Link to={generateCategoryUrl(category.id, category.name)}>
                    <div
                      className={classNames("home-section__products__image", {
                        "home-page__categories__list__image--no-photo": !category.backgroundImage,
                      })}
                      style={{
                        backgroundImage: `url(${
                          category.backgroundImage
                            ? category.backgroundImage.url
                            : noPhotoImg
                        })`,
                      }}
                    ></div>
                    <p className="home-section__products__image__title">
                      {intl.locale === "es"
                        ? category.name
                        : category.translation?.name || category.name}
                    </p>
                  </Link>
                </div>
              ))}
            </div>
          )}
        </div>
      </section>
      <section id="contacto">
        <div className="home-page__section-title-container">
          <h2 className="home-page__section-title">
            <FormattedMessage id="home_contact" defaultMessage="CONTACTO" />
          </h2>
        </div>
        {showContactForm ? (
          <div>
            <ContactForm />
          </div>
        ) : (
          <div className="contact-us__container">
            <div className="contact-us__image-container">
              <img src={contactUsBackground} alt="aema product" />
            </div>
            <div className="wrapper contact-us__text" data-aos="fade-right">
              <div>
                <span className="contact-us__title">
                  <h2 className="header-title">
                    <FormattedMessage
                      id="home_contact-us_title"
                      defaultMessage="Comencemos a"
                    />
                  </h2>
                </span>
                <span className="contact-us__title">
                  <h2 className="header-title">
                    <FormattedMessage
                      id="home_contact-us_title-2"
                      defaultMessage="hacer tu negocio"
                    />
                  </h2>
                </span>
                <span className="contact-us__title">
                  <h2 className="header-title">
                    <FormattedMessage
                      id="home_contact-us_title-3"
                      defaultMessage="más seguro"
                    />
                  </h2>
                </span>
              </div>
              <div>
                <span className="contact-us__title">
                  <h4 className="header-subtitle uppercase">
                    <FormattedMessage
                      id="home_contact-us_subtitle"
                      defaultMessage="Estamos para servirte"
                    />
                  </h4>
                </span>
                <button
                  className="btn__outline--red"
                  onClick={() => setShowContactForm(true)}
                >
                  <FormattedMessage
                    id="footer_contact_button"
                    defaultMessage="Contáctanos"
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </section>
      <section className="home-contact">
        <div className="wrapper">
          <h2 className="home-contact__title">
            <FormattedMessage
              id="home_contact_title-main"
              defaultMessage="Protege lo que"
            />
            <span> </span>
            <FormattedMessage
              id="home_contact_title_2-main"
              defaultMessage="quieras, "
            />
            <br className="br" />
            <FormattedMessage
              id="home_contact_title_3-main"
              defaultMessage=" dónde quieras"
            />
          </h2>
          <div className="home-contact__container">
            <img src={mapImg} alt="mapa" className="home-contact__image" />
          </div>
          <div className="map-message__container" data-aos="fade-right">
            <div className="map-title__container">
              <h1 className="header-title map-title">
                <FormattedMessage
                  id="home_contact_title"
                  defaultMessage="Haznos parte de"
                />
              </h1>
              <h1 className="header-title map-title">
                <FormattedMessage
                  id="home_contact_title_2"
                  defaultMessage="tu seguridad"
                />
              </h1>
            </div>
            <ul className="map-list">
              <li>
                <span className="map-arrow">
                  <img src={arrowIcon} alt="arrow" />
                </span>
                <FormattedMessage
                  id="home_contact_ensure"
                  defaultMessage="Asegura "
                />
                <FormattedMessage
                  id="home_contact_ensure_peace"
                  defaultMessage="tu tranquilidad"
                />
              </li>
              <li>
                <span className="map-arrow">
                  <img src={arrowIcon} alt="arrow" />
                </span>
                <FormattedMessage
                  id="home_contact_protect"
                  defaultMessage="Protege "
                />
                <FormattedMessage
                  id="home_contact_protect_business"
                  defaultMessage="tu negocio"
                />
              </li>
              <li>
                <span className="map-arrow">
                  <img src={arrowIcon} alt="arrow" />
                </span>
                <FormattedMessage
                  id="home_contact_ware"
                  defaultMessage="Cuida "
                />
                <FormattedMessage
                  id="home_contact_ware_merchandise"
                  defaultMessage="tu mercancía"
                />
              </li>
            </ul>
          </div>
        </div>
      </section>
      {/*      <ProductsFeatured /> */}
    </>
  );
};

export default Page;
