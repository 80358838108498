import React from "react";
import { FormattedMessage } from "react-intl";

import { ErrorMessage } from "@components/atoms";
import { AddressSummary } from "@components/molecules";

import * as S from "./styles";
import { IProps } from "./types";
import { BillingDataSummary } from "../../molecules/BillingDataSummary";
/**
 * Review order view showed in checkout.
 */
const CheckoutReview: React.FC<IProps> = ({
  shippingAddress,
  billingAddress,
  billingData,
  shippingMethodName,
  paymentMethodName,
  email,
  errors,
}: IProps) => {
  return (
    <S.Wrapper>
      <S.Title data-cy="checkoutPageSubtitle">      
        <FormattedMessage
          id="checkout_reivew"
          defaultMessage="REVISIÓN DE ORDEN"/>
      </S.Title>
      <S.Grid>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">            
            <FormattedMessage
              id="shipping_address"
              defaultMessage="Dirección de Envío"/>
          </S.SubTitle>
          <S.Divider />
          <AddressSummary address={shippingAddress} email={email} />
        </section>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">            
            <FormattedMessage
              id="billing_address"
              defaultMessage="Dirección de Facturación"/>
          </S.SubTitle>
          <S.Divider />
          <AddressSummary address={billingAddress} email={email} />
        </section>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">          
          </S.SubTitle>
          <S.Divider />
          <S.TextSummary>{shippingMethodName}</S.TextSummary>
        </section>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">            
            <FormattedMessage
              id="billing_address"
              defaultMessage="Datos de Facturación"/>
          </S.SubTitle>
          <S.Divider />
          <BillingDataSummary data={billingData}/>
        </section>
        <section>
          <S.SubTitle data-cy="checkoutReviewSectionTitle">            
            <FormattedMessage
              id="payment_method"
              defaultMessage="Método de Pago"/>
          </S.SubTitle>
          <S.Divider />
          <S.TextSummary>{paymentMethodName}</S.TextSummary>
        </section>
      </S.Grid>
      <S.ErrorMessages>
        <ErrorMessage errors={errors} />
      </S.ErrorMessages>
    </S.Wrapper>
  );
};

export { CheckoutReview };
