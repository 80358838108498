import React from "react";
import {  FormattedMessage } from "react-intl";

import * as S from "./styles";
import { IProps } from "./types";

import { Container } from "../Container";

/**
 * Template for empty cart page.
 */
const CartEmpty: React.FC<IProps> = ({ button }: IProps) => {
  return (
    <Container>
      <S.Wrapper>
        <S.TitleFirstLine>
          <FormattedMessage
            id="cartempty_your_cart"
            defaultMessage="Tu carro"/>
        </S.TitleFirstLine>
        <S.TitleSecondLine>
          <FormattedMessage
            id="cartempty_looks_empty"
            defaultMessage="Tu carro"/>
        </S.TitleSecondLine>
        <S.HR />
        <S.Subtitle>
          <FormattedMessage
            id="cartempty_not_choice"
            defaultMessage="Quizá no has hecho tus elecciones aún"/>
        </S.Subtitle>
        <S.ContinueButton>{button}</S.ContinueButton>
      </S.Wrapper>
    </Container>
  );
};

export { CartEmpty };
