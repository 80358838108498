import "./scss/index.scss";

import isEqual from "lodash/isEqual";
import React, { useState } from "react";

import { ProductVariantPicker } from "@components/organisms";
import {
  ProductDetails_product_pricing,
  ProductDetails_product_variants,
  ProductDetails_product_variants_pricing,
} from "@sdk/queries/gqlTypes/ProductDetails";
import { IProductVariantsAttributesSelectedValues, ITaxedMoney } from "@types";

import { ICheckoutModelLine } from "@sdk/repository";
import { FormattedMessage, useIntl } from "react-intl";
import { TaxedMoney } from "../../@next/components/containers";
import { Locale } from "../Locale/WrapperIntl";
import AddToCart from "./AddToCart";
import AddToCartButton from "./AddToCartButton";
import ModalQuote from "./ModalQuote";
import { QuantityTextField } from "./QuantityTextField";

const LOW_STOCK_QUANTITY = 5;
interface ProductDescriptionProps {
  productId: string;
  productVariants: ProductDetails_product_variants[];
  name: string;
  enableQuoting: boolean;
  pricing: ProductDetails_product_pricing;
  items: ICheckoutModelLine[];
  addToCart(varinatId: string, quantity?: number): void;
  setVariantId(variantId: string);
}

interface ProductDescriptionState {
  quantity: number;
  variant: string;
  variantStock: number;
  variantPricing: ProductDetails_product_variants_pricing;
  variantPricingRange: {
    min: ITaxedMoney;
    max: ITaxedMoney;
  };
  isOpenModalQuote: boolean;
}

const ProductDescription = (props: ProductDescriptionProps) => {
  const { locale } = useIntl();

  const [state, setState] = useState<ProductDescriptionState>({
    isOpenModalQuote: false,
    quantity: 1,
    variant: "",
    variantPricing: null,
    variantPricingRange: {
      max: props.pricing.priceRange.stop,
      min: props.pricing.priceRange.start,
    },
    variantStock: null,
  });

  const getProductPrice = () => {
    const { variantPricingRange, variantPricing } = state;

    const { min, max } = variantPricingRange;
    if (variantPricing) {
      if (isEqual(variantPricing.priceUndiscounted, variantPricing.price)) {
        return <TaxedMoney taxedMoney={variantPricing.price} />;
      } else {
        return (
          <>
            <span className="product-description__undiscounted_price">
              <TaxedMoney taxedMoney={variantPricing.priceUndiscounted} />
            </span>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <TaxedMoney taxedMoney={variantPricing.price} />
          </>
        );
      }
    }
    if (isEqual(min, max)) {
      return <TaxedMoney taxedMoney={min} />;
    } else {
      return (
        <>
          <TaxedMoney taxedMoney={min} /> - <TaxedMoney taxedMoney={max} />
        </>
      );
    }
  };

  const onVariantPickerChange = (
    _selectedAttributesValues?: IProductVariantsAttributesSelectedValues,
    selectedVariant?: ProductDetails_product_variants
  ) => {
    if (selectedVariant) {
      setState({
        ...state,
        variant: selectedVariant.id,
        variantPricing: selectedVariant.pricing,
        variantStock: selectedVariant.quantityAvailable,
      });
      props.setVariantId(selectedVariant.id);
    } else {
      setState({ ...state, variant: "", variantPricing: null });
      props.setVariantId("");
    }
  };

  const canAddToCart = () => {
    const { items } = props;
    const { variant, quantity, variantStock } = state;

    const cartItem = items?.find((item) => item.variant.id === variant);
    const syncedQuantityWithCart = cartItem
      ? quantity + (cartItem?.quantity || 0)
      : quantity;
    return quantity !== 0 && variant && variantStock >= syncedQuantityWithCart;
  };

  const handleSubmit = () => {
    props.addToCart(state.variant, state.quantity);
    setState({ ...state, quantity: 0 });
  };

  const getAvailableQuantity = () => {
    const { items } = props;
    const { variant, variantStock } = state;
    const cartItem = items?.find((item) => item.variant.id === variant);
    const quantityInCart = cartItem?.quantity || 0;
    return variantStock - quantityInCart;
  };

  const handleQuantityChange = (quantity: number) => {
    setState({ ...state, quantity });
  };

  const renderErrorMessage = (id: string, message: string) => (
    <p className="product-description__error-message">
      <FormattedMessage id={id} defaultMessage={message} />
    </p>
  );

  const addQuantity = () => {
    setState((prev) => ({ ...state, quantity: prev.quantity + 1 }));
  };

  const substractQuantity = () => {
    if (state.quantity > 0) {
      setState((prev) => ({ ...state, quantity: prev.quantity - 1 }));
    }
  };

  const { name, enableQuoting } = props;
  const { variant, variantStock, quantity } = state;

  const availableQuantity = getAvailableQuantity();
  const isOutOfStock = !!variant && variantStock === 0;
  const isNoItemsAvailable = !!variant && !isOutOfStock && !availableQuantity;
  const isLowStock =
    !!variant &&
    !isOutOfStock &&
    !isNoItemsAvailable &&
    availableQuantity < LOW_STOCK_QUANTITY;

  return (
    <div className="product-description">
      {
        <ModalQuote
          modalProps={{
            hide: () => setState({ ...state, isOpenModalQuote: false }),
            loading: false,
            show: state.isOpenModalQuote,
            title: locale === Locale.ES ? "Cotizar" : "Quote",
          }}
          productData={{
            name,
            quantity,
          }}
        />
      }
      <h3>{name}</h3>
      {isOutOfStock ? (
        renderErrorMessage("out_stock", "Agotado")
      ) : (
        <h4>{getProductPrice()}</h4>
      )}
      {isLowStock && renderErrorMessage("last_pieces", "Ultimas piezas")}
      {isNoItemsAvailable && renderErrorMessage("notavaiable","No Disponible")}
      <div className="product-description__variant-picker">
        <ProductVariantPicker
          productVariants={props.productVariants}
          onChange={onVariantPickerChange}
          selectSidebar={true}
        />
      </div>
      <div className="product-description__quantity_container">
        <div className="product-description__price_container__cart">
          <p>Cantidad de paquetes</p>
        </div>
        <div className="product-description__quantity-input">
          <div className="wrapper-quantity">
            <button
              className="wrapper-quantity__add"
              onClick={substractQuantity}
            >
              -
            </button>
            <QuantityTextField
              quantity={quantity}
              maxQuantity={availableQuantity}
              disabled={isOutOfStock || isNoItemsAvailable}
              onQuantityChange={handleQuantityChange}
              hideErrors={!variant || isOutOfStock || isNoItemsAvailable}
            />
            <button
              className="wrapper-quantity__substract"
              onClick={addQuantity}
            >
              +
            </button>
          </div>
        </div>
      </div>
      <div className="product-description__price_container">
        <AddToCart onSubmit={handleSubmit} disabled={!canAddToCart()} />
        {enableQuoting && (
          <AddToCartButton
            className="description_action"
            disabled={false}
            onClick={() => setState({ ...state, isOpenModalQuote: true })}
          >
            <FormattedMessage id="quote" defaultMessage="Cotizar" />
          </AddToCartButton>
        )}
      </div>
    </div>
  );
};

export default ProductDescription;
