import facebookIcon from "images/facebook-header.svg";
import instagramIcon from "images/instagram-header.svg";
import twitterIcon from "images/twitter-header.svg";
import youtubeIcon from "images/youtube-header.svg";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Locale, LocaleContext } from "../Locale/WrapperIntl";

import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { MenuDropdown } from "..";

import chevronDown from "../../images/chevron_down.png";

import "./scss/index.scss";

import logoIcon from "../../images/logo.svg";

const Nav: React.FC = () => {
  const contextIntl = useContext(LocaleContext);

  return (
    <footer className="footer-nav">
      <div className="wrapper footer-nav__container">
        <div className="footer-nav__logo">
          <ReactSVG path={logoIcon} />
        </div>
        <div className="footer-nav__containerList">
          <div>
            <h3 className="footer-nav__title">
              <FormattedMessage
                id="footer_contact_button"
                defaultMessage="Contáctanos"
              />
            </h3>
            <div>
              <ul className="contact-list">
                <li>
                  <span>E.</span> contacto@grupoaema.com
                </li>
                <li>
                  <span>+</span> (81) 8387 7008 |09|10|11
                </li>
                <li>
                  <span>+</span> 01 800 108 AEMA (2362)
                </li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="footer-nav__title">Monterrey</h3>
            <div>
              <ul className="list">
                <li>Corporativo AEMA</li>
                <li>CEDIS Santa Catarina</li>
                <li>Torre AEMA</li>
                <li>Parque AEMA</li>
              </ul>
            </div>
          </div>
          <div>
            <h3 className="footer-nav__title">CDMX</h3>
            <div>
              <ul className="list">
                <li>CEDIS Valle de Chalco</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="footer-nav__locale">
          <MenuDropdown
            head={
              <p className="main-menu__icon main-menu__languages">
                <FormattedMessage
                  defaultMessage="ESP"
                  description="label"
                  id="language"
                />
                <img src={chevronDown} alt="down" className="arrow-down" />
              </p>
            }
            content={
              <ul className="dropdown">
                <li data-testid="link">
                  <label onClick={() => contextIntl.setLocale(Locale.EN)}>
                    English
                  </label>
                </li>
                <li data-testid="link">
                  <label onClick={() => contextIntl.setLocale(Locale.ES)}>
                    Español
                  </label>
                </li>
              </ul>
            }
          />
        </div>
      </div>
      <div className="footer__socialMedia">
        <ul className="socialMedia">
          <li>
            <a href="https://twitter.com/GrupoAema" target="_blank">
              <ReactSVG path={twitterIcon} />
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/grupoaemamx/" target="_blank">
              <ReactSVG path={instagramIcon} />
            </a>
          </li>
          <li>
            <a
              href="https://www.youtube.com/channel/UCeDVShdVHdUGjQj2zOJbgbA"
              target="_blank"
            >
              <ReactSVG path={youtubeIcon} />
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/AemaSeguridad/" target="_blank">
              <ReactSVG path={facebookIcon} />
            </a>
          </li>
        </ul>
      </div>
      <div className="information-pages wrapper">
        <div className="horizontal-rule" />
        <div className="rights">
          <p>
            © 2022
            <FormattedMessage
              id="footer_rights"
              defaultMessage=" Grupo AEMA de México. Derechos Reservados."
            />
          </p>
          <div className="terms">
            <Link to="/privacy-policies">
              <FormattedMessage
                id="footer_privacy"
                defaultMessage="Politicas de Privacidad"
              />
            </Link>
            <Link to="/terms-conditions">
              <FormattedMessage
                id="footer_legal"
                defaultMessage="Términos de Uso"
              />
            </Link>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Nav;
