import React, { Fragment, useState } from "react";
import { FormattedMessage } from "react-intl";
import "./scss/index.scss";
import Modal from '../../components/Modalmessage';
import sendIcon from "../../images/message-moda-icon.svg";
import sendImg from "../../images/send_icon.svg";

const formData = require('form-data')
const Mailgun = require('mailgun.js')
const mailgun = new Mailgun(formData)
const mg = mailgun.client({
  key: process.env.MAILGUN_API_KEY,
  username: 'api'
})

const ContactForm: React.FC = () => {

  const [form, setForm] = useState({
    city: '',
    description: '',
    email: '',
    name: '',
    telephone: '',
  });

  const [success, setSuccess] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);

  const CloseModal = () =>{
    setmodalOpen(false);
  }
  const handleInput = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const messageParams = {
      from: `AEMA contact message <${process.env.MAILGUN_DOMAIN}>`,
      html: `<div>
        <p><strong>Name:</strong> ${form.name}</p>
        <p><strong>Telephone:</strong> ${form.telephone}</p>
        <p><strong>Email:</strong> ${form.email}</p>
        <p><strong>City:</strong> ${form.city}</p>
        <p><strong>Message:</strong><br>${form.description}</p>
      </div>`,
      subject: "Contact message",
      to: ["contacto@grupoaema.com"]
    };

    mg.messages.create('mail.grupoaema.com', messageParams)
      .then(() => {
        setSuccess(true)
        setmodalOpen(true);
      })
  }

  return (
    <div className="contact-form">
      <div className="wrapper">
        <form className="form" onSubmit={handleSubmit}>
          {/*Input for name*/}
          <div>
            <label className="label">
              <FormattedMessage
                id="contact_name"
                defaultMessage="Nombre" />
            </label>
            <input name="name" type="text" className="input" required onChange={handleInput} value={form.name} />
          </div>
          {/*Input for telephone*/}
          <div>
            <label className="label">
              <FormattedMessage
                id="contact_telephone"
                defaultMessage="Teléfono" />
            </label>
            <input name="telephone" type="tel" className="input" required onChange={handleInput} value={form.telephone} />
          </div>
          {/*Input for email*/}
          <div>
            <label className="label">
              <FormattedMessage
                id="contact_email"
                defaultMessage="Correo electrónico" />
            </label>
            <input name="email" type="email" className="input" required onChange={handleInput} value={form.email} />
          </div>
          {/*Input for city*/}
          <div>
            <label className="label">
              <FormattedMessage
                id="contact_subject"
                defaultMessage="Ciudad" />
            </label>
            <input name="city" type="text" className="input" required onChange={handleInput} value={form.city} />
          </div>
          {/*Input for description*/}
          <div>
            <label className="label">
              <FormattedMessage
                id="contact_description"
                defaultMessage="Mensaje" />
            </label>
            <textarea name="description" className="textarea" required cols={8} onChange={handleInput}
              value={form.description} />
          </div>
          <div className="button__container">
            {success && (
              <div className="success-message">
                <FormattedMessage
                  id="contact_success"
                  defaultMessage="Hemos recibido tu mensaje. Gracias por comunicarte." />
              </div>
            )}
            {/*Modal Message Confirmation*/}
            <Modal show={modalOpen}
              title={[
                <Fragment>
                  <p>
                    <FormattedMessage
                      id="message-sent"
                      defaultMessage="¡Mensaje enviado!" />
                  </p>
                  <img width='50px' className="modalMessage__icon" height='50px' src={sendIcon}></img>
                </Fragment>,
              ]}
              hide={CloseModal}
              children={
                <Fragment>
                  <FormattedMessage
                    id="thanks-contact"
                    defaultMessage="Gracias por contactarnos" />
                  <br></br>
                  <br></br>
                  <FormattedMessage
                    id="comunicate-with-you"
                    defaultMessage="En breve nos comunicaremos con usted." />
                  <br></br>
                  <br></br>
                  <FormattedMessage
                    id="hour-attention"
                    defaultMessage=" Nuestros horarios de atención son:" />
                  <br></br>
                  <FormattedMessage
                    id="monday-tuesday"
                    defaultMessage=" Lunes a Viernes: 9 am a 6 pm" />
                  <img className="modalMessage__image" width='200px' height='190px' src={sendImg}></img>
                </Fragment>
              }></Modal>
            {/*End of Modal Message Confirmation*/}
            <button className="button" type="submit" disabled={success}>
              <FormattedMessage
                id="contact_button"
                defaultMessage="Enviar" />
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ContactForm;
