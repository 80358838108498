import React from "react";
import "./scss/index.scss";

import { useIntl } from "react-intl";

const TermsConditions: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      {intl.locale === "en" ? (
        <div className="wrapper terms-conditions-container">
          <p className="center">
            <b>TERMS AND CONDITIONS OF USE.</b>
          </p>
          <p>
            <span>
              These terms and conditions are applicable to viewing, browsing,
              buying and selling products through{" "}
            </span>
            <a href="https://www.grupoaema.com">
              <span>https://www.grupoaema.com</span>
            </a>
            <span>
              . These terms and conditions were prepared in accordance with the
              provisions of articles 76 Bis and 76 Bis 1 of the Federal Consumer
              Protection Law, which establish the provisions to which all those
              persons defined by said Mexican standard as users shall be
              subject. or clients when entering the site
              https://www.grupoaema.com seeking at all times, guarantee, make
              transparent, inform and guide properly about the navigation and
              carrying out of any type of transaction through the website. This
              website is owned and operated by GRUPO AEMA DE M&Eacute;XICO, S.A.
              DE C.V. (hereinafter "AEMA GROUP"). The purpose of this site is
              the commercialization and elaboration of security seals, security
              labels, security bags, fuel protection, among others.
            </span>
          </p>
          <ol>
            <li>
              <b>A. TERMS AND CONDITIONS OF USE</b>
              <span>
                . The use policies are established in the following terms and
                conditions under which the website and the services offered by
                &ldquo;GRUPO AEMA&rdquo; can be used. By accessing or using the
                website of our service, you agree that you have read, understood
                and agreed to be bound by these terms and conditions of use.
              </span>
            </li>
            <li className="mt-1">
              <b>B. COPYRIGHT AND TRADEMARKS OF GRUPO AEMA</b>
              <span>.</span>
            </li>
          </ol>
          <p>
            <span>
              "GRUPO AEMA" is the owner of the website https://www.grupoaema.com
              owns the rights to all desktop wallpapers, icons, characters,
              graphic material, images, graphic designs, music, texts, videos,
              software ( computer program) and other content on the website
              (hereinafter the "Content"). The "Content" is protected by
              copyright. You may not copy, modify, upload, download, transmit,
              republish, view for distribution to third parties for commercial
              purposes, or distribute by any procedure the Content of the
              website without prior written authorization from &ldquo;GRUPO
              AEMA&rdquo;. The Content of the Website may not be used for any
              purpose other than that established in this Agreement.
            </span>
          </p>
          <p>
            <span>
              All names, logos and trademarks that appear on the Website are the
              property of &ldquo;GRUPO AEMA'' unless otherwise stated or
              clarified on the page. They may not be used for purposes that may
              lead to customer confusion, or for any other purpose without prior
              written authorization from &ldquo;GRUPO AEMA''. The client and
              user are expressly prohibited from modifying, altering or
              deleting, either in whole or in part, the notices, trademarks,
              trade names, signs, advertisements, logos or in general any
              content or indication contained in https: //www.grupoaema .com.
              Your breach of this Agreement of Terms and Conditions of Use will
              constitute a breach of contract and a violation of intellectual
              property and trademarks and intellectual property rights.
            </span>
          </p>
          <ol>
            <li>
              <b>C. SAMPLE PAGE.</b>
            </li>
          </ol>
          <p>
            <span>
              This web page represents a sample page. All information and
              details regarding the availability, existence, shipping or
              delivery of a product are only estimates. In the event that, for
              any reason, during the processing of your order, the product
              ordered by you will no longer be available we will allow you to
              order the same subject to inventory confirmation. If we cannot
              obtain the desired product, your order cannot be confirmed.
            </span>
          </p>
          <ol>
            <li>
              <b>D. OWN OR OWNED PRODUCTS</b>
            </li>
          </ol>
          <p>
            <span>
              &ldquo;GRUPO AEMA&rdquo; is not a manufacturer of all the products
              it offers, it also acts as a marketer of the stock available at
              https://www.grupoaema.com. Therefore, it is disclaimed from any
              responsibility related to the processing and content of these,
              with the understanding that the user is aware of the role of
              "GRUPO AEMA" at https://www.grupoaema.com. "GRUPO AEMA" is not
              responsible for examining or rating, or in any case guaranteeing,
              the functionality of the products of other companies or
              individuals, or the content hosted on their respective websites.
              "GRUPO AEMA" does not assume any responsibility for the actions,
              products and contents of any of such companies or individuals or
              any other third parties. You should carefully review the privacy
              statements and other terms of use of such third parties.
            </span>
          </p>
          <ol>
            <li>
              <b>E. LIMITATION OF LIABILITY</b>
              <span>.</span>
            </li>
          </ol>
          <p>
            <span>
              While &ldquo;GRUPO AEMA&rdquo; makes every effort to include
              up-to-date and reliable information on the website, it does not
              provide any guarantee or representation regarding the content of
              the website. To the extent permitted by applicable legislation,
              "GRUPO AEMA" will not be responsible, directly, indirectly,
              incidentally, resulting from the loss of data, income or profits,
              compensation for damages and / or claims from third parties that
              resulting from the use, access, or inability to use the
              information and / or products offered on the website or for any
              damage caused by the use of the website or in connection with it.
              In addition, "GRUPO AEMA" does not assume any responsibility and
              will not be responsible for damages or losses caused or by viruses
              that could infect your computer system or other properties as a
              result of your access, use or exploration / navigation on the
              website or by the downloading information from the website. The
              user is solely responsible for all damages (including damage to
              the content of the Site) caused by the use (or transmission) of
              any user or site content and related transactions or events.
              "GRUPO AEMA" does not assume any responsibility for unauthorized
              access to the user's account or for the automatic forwarding of
              messages or viruses (caused by viruses or other means) to other
              people whose information the user has included in the address
              books in line available on this site.
            </span>
          </p>
          <p>
            <span>
              The limitations and exclusions of liability in these Terms and
              Conditions of Use apply regardless of whether the liability is
              based on breach of contract, damages, strict liability, breach of
              warranties or any other legal cause. The non-validity of any of
              the terms, conditions or provisions of this agreement will not
              affect the applicability of those portions of it estimated as
              enforceable by the pertinent courts.
            </span>
          </p>
          <p></p>
          <ol>
            <li>
              <b>F. PRIVACY NOTICE.</b>
            </li>
          </ol>
          <p>
            <span>Carefully read the Privacy Notice available at </span>
            <b>(link)</b>
            <span>
              {" "}
              that governs how "GRUPO AEMA" will handle any personal information
              that you provide.
            </span>
          </p>
          <ol>
            <li>
              <span>G. USERS.</span>
            </li>
          </ol>
          <p>
            <span>
              To order or communicate through the site and have access to it,
              the resources and part or all of the content of the website, the
              user may be required to provide registration details and log in to
              use this site, the products and content of
              https://www.grupoaema.com. It is a requirement that all
              registration details provided by the user are and remain valid,
              correct, current and complete. The user agrees to notify "GRUPO
              AEMA" immediately in case of any pertinent change to his
              registration, being responsible for updating it.
            </span>
          </p>
          <p>
            <span>
              For account registration, personal data such as the user's full
              name, billing address, shipping address, date of birth, email and
              telephone number are requested. "GRUPO AEMA" protects consumer
              privacy and will only use this data for the purposes established
              above. When the client uses the "GRUPO AEMA" website, he is
              responsible for maintaining the confidentiality of his account
              data and password, as well as restricting access to his computer
              and other devices, and you assume responsibility for any
              activities made from your account or using your password. "GRUPO
              AEMA" reserves the right to cancel accounts, remove or edit
              content, cancel orders in case of violation of these Conditions of
              Use, General Conditions of Services or any policy, terms and
              conditions of "GRUPO AEMA", rights of third parties or commission
              of a crime or any conduct that damages the reputation of
              &ldquo;GRUPO AEMA&rdquo; or the customer experience. However, the
              user acknowledges and understands that https://www.grupoaema.com
              should only be used for the purposes mentioned above, with the
              understanding that otherwise the terms and conditions policies
              would be violated.
            </span>
          </p>
          <ol>
            <li>
              <b>H. SHIPPING AND RISK OF LOSS</b>
              <span>.</span>
            </li>
          </ol>
          <p>
            <span>
              Shipments will be made throughout the Mexican Republic, "GRUPO
              AEMA'' has a list of packages handled depending on the location of
              the address given by the customer. The packages used will be
              subject to availability and will vary between the following list:
              GRUPO PGL M&Eacute;XICO, OPERADORA DE SERVICIOS PAQUETEXPRESS,
              TRANSPORTES CASTORES DE BAJA CALIFORNIA, VILLAGON EXPRESS PACK,
              DHL EXPRESS M&Eacute;XICO, PACKAGING SERVICES AND SHIPPING YELLOW
              ARROW decide to use. The user acknowledges and accepts that "GRUPO
              AEMA" does not have control of logistics, delivery times, route
              used, quality of service and subsequent delays, from the parcel
              selected by "GRUPO AEMA'' for the shipment of the order made by
              the customer since this selection depends solely and exclusively
              on the availability of packages on the date of the order /
              shipment. Ownership and risk of loss of the products will transfer
              to you at the time we deliver the products to the appropriate
              carrier. "GRUPO AEMA'' delivers in full compliance and with the
              guaranteed quality of the product to the carrier and disclaims
              responsibility for the service provided by the carrier. However, a
              shipping guide will be sent so that the user / client can follow
              the route of their order, with the understanding that, even when
              "GRUPO AEMA'' provides the shipping guide, it is not responsible
              for it. and the ownership belongs only to the corresponding.
            </span>
          </p>
          <ol>
            <li>
              <b>I. PAYMENT BY INTERMEDIARY.</b>
            </li>
          </ol>
          <p>
            <span>
              The user will select the products of their choice and they will be
              added to a shopping cart. When you want to finalize your order,
              you must choose a payment method through the user already
              registered on the website. If not registered, the user is aware
              that they will have to create an account to continue with their
              order. The payment methods will be through an intermediary, who
              will be in charge of processing the payment either by credit or
              debit card through their software. The user at the time of payment
              is accepting the terms and conditions of the intermediary which
              can be found on the following page: https://stripe.com/es. "GRUPO
              AEMA" is not responsible for the User's bank details, since at no
              time will it know said account numbers and corresponding PIN.
            </span>
          </p>
          <ol>
            <li>
              <b>J. ANALYTICS HEATMAP.</b>
            </li>
          </ol>
          <p>
            <span>
              This website uses heatmap analytics only for the OnPage
              Optimization which refers to an &ldquo;on-page optimization&rdquo;
              as it collects the opportunity areas of the website. It consists
              of graphics that make it possible to identify the hot spots on the
              web, -the areas that most attract the attention of users, allowing
              to analyze and improve the usefulness and accessibility of the web
              page.
            </span>
          </p>
          <ol>
            <li>
              <b>K. LINKS AND THIRD PARTY WEBSITES</b>
              <span>.</span>
            </li>
          </ol>
          <p>
            <span>
              "GRUPO AEMA" is not responsible for the content of pages or links
              to any other web page, it is the sole responsibility of the user.
              &ldquo;GRUPO AEMA&rdquo; may provide links to other web pages, but
              the introduction of such links is at the user's convenience.
              "GRUPO AEMA" does not make representations and waives all express
              or implicit guarantees, regarding the reliability, validity,
              suitability, legality or others of any material or information
              contained in such web pages.
            </span>
          </p>
          <ol>
            <li>
              <b>L. REPLACEMENTS AND RETURNS.</b>
            </li>
          </ol>
          <p>
            <span>
              For purposes of complying with articles 54, section II and III, 92
              and other relative ones of the Federal Consumer Protection Law,
              "GRUPO AEMA" will be responsible for returns or replacements of
              merchandise only and exclusively in the case of factory defects
              duly verified by the user, therefore, for the purposes of the
              above, the following procedure is established:
            </span>
          </p>
          <p>
            <span>
              1.- Users who identify one or more products with manufacturing
              defects should contact &ldquo;GRUPO AEMA&rdquo; via email at the
              following address: ventas@grupoaema.com in order to demonstrate
              the product's manufacturing defect and request its return or
              replacement.
            </span>
          </p>
          <p>
            <span>
              In the email, the user must send images of the merchandise that
              has a factory defect, as well as express his desire to return or
              replace it and indicate through which parcel of those indicated in
              paragraph H) of this letter he wishes. that the return is made.
            </span>
          </p>
          <p>
            <span>
              In order for the return or replacement to be appropriate, the user
              must send the described email to &ldquo;GRUPO AEMA&rdquo; within 1
              (one) calendar day after realizing the factory defect.
            </span>
          </p>
          <p>
            <span>
              2.- "GRUPO AEMA" will have a period of 2 (two) business days after
              receiving the email with the attached images of the defective
              merchandise to notify the user through the same means of whether
              or not the return or replacement of said commodity. The user
              acknowledges that the origin of the return or replacement is only
              due to a duly verified factory defect.
            </span>
          </p>
          <p>
            <span>
              3.- "GRUPO AEMA" will be in charge of covering the costs of
              transport and shipping of merchandise for the return or
              replacement requested, so that in case the return or replacement
              by factory defect proceeds, it will be sent to the user A prepaid
              shipping guide of the parcel you have indicated in the email
              through which you have made your request. However, "GRUPO AEMA"
              could designate another parcel service if the one chosen by the
              user is not available for delivery, of course notifying the user
              in advance via email.
            </span>
          </p>
          <p>
            <span>
              4.- In the event that the return of the merchandise is
              appropriate, &ldquo;GRUPO AEMA&rdquo; will return the amount
              equivalent to the same amount according to the same method used in
              the initial purchase on the https://www.grupoaema.com portal. The
              refund of the amount will be made until "GRUPO AEMA" receives the
              defective merchandise at its address.
            </span>
          </p>
          <p>
            <span>
              5.- If the replacement of the merchandise is appropriate, "GRUPO
              AEMA" will also be responsible for the cost of shipping the new
              merchandise to the user. Said shipment will be made to the same
              address provided in the initial purchase by the user.
            </span>
          </p>
          <p>
            <span>
              6.- The return or replacement of the merchandise will be carried
              out in a period of approximately 5 (five) business days from the
              positive response of origin via email.
            </span>
          </p>
          <p>
            <span>
              7.- By fortuitous event is understood an event of nature that is
              unpredictable and by force majeure an event caused by man that is
              inevitable. In the event that "GRUPO AEMA", either by fortuitous
              event or force majeure, is unable to comply with the return or
              replacement procedure in the terms agreed upon herein, there will
              be no liability whatsoever for this, but will notify the user in
              the most opportune moment on the delay for the return or
              replacement and new days will be agreed to comply with this
              procedure.
            </span>
          </p>
          <ol>
            <li>
              <b>M. RIGHT TO CHANGE AND MODIFY THE TERMS</b>
              <span>.</span>
            </li>
          </ol>
          <p>
            <span>
              "GRUPO AEMA" reserves the right to modify these terms and
              conditions of use by updating its publication. The user agrees to
              comply with such modifications. Our company invites you to
              regularly consult the website to find out the modifications that
              may be applied.
            </span>
          </p>
          <ol>
            <li>
              <b>N. JURISDICTION</b>
            </li>
          </ol>
          <p>
            <span>
              All differences that result from or are related to the use of the
              website will be exclusively subject to the jurisdiction of the
              Courts of Monterrey, Nuevo Le&oacute;n, Me.
            </span>
          </p>
          <p></p>
          <ol>
            <li>
              <b>NOTICE OF PRIVACY.</b>
            </li>
          </ol>
          <p>
            <strong>GRUPO AEMA DE MÉXICO, SA DE CV</strong> (hereinafter{" "}
            <strong>"GRUPO AEMA"</strong> ) aims to Marketing and production of
            Security Seals, Security Labels, Security Bags, Fuel Protection
            among others. It has an address located at Gerardo Torres Diaz 3505,
            Monterrey, Nuevo León and that based on and in compliance with the
            provisions of Federal Law Protection of Personal Data Held by
            Individuals; informs that he puts his provision of this Privacy
            Notice by virtue of which it is responsible for collecting your data
            personal information, in the same way informs the use that is given
            to them and their protection.
          </p>
          <ol>
            <li>
              <b>PURPOSES OF THE USE OF PERSONAL DATA.</b>
            </li>
          </ol>
          <p>
            <span>
              The personal data that <strong>"GRUPO AEMA"</strong>
              collects in its offices, if requested via telephone, electronic
              messages; or through the website and contract our products or
              online services; or through other sources permitted by law, they
              may be, among others:
            </span>
          </p>
          <p>
            1. Name, telephone numbers, email address, address, date of birth,
            place of birth, personal interests, shipping address.
            <br />
            2. Federal Taxpayers Registry (RFC) and Unique Population Registry
            Code (CURP).
            <br />
            3. Bank details: bank account number, interbank code, number of
            cards. The Personal Data of the Holder will be collected solely and
            exclusively by "GRUPO AEMA" with the purpose of:
          </p>
          <p>
            1. Carry out the activities that derive from the relationship that
            binds <strong>“GRUPO AEMA ”</strong> with the Owner, regarding the
            services or products provided to the Owner;
            <br />
            2. Preparation of service proposals;
            <br />
            3. Preparation of purchase orders;
            <br />
            4. Preparation of invoices, referrals or any other information aimed
            at complying with the services or products provided to the Holder;
            <br />
            5. To share with the Owner information about the services of{" "}
            <strong>“GRUPO AEMA”</strong>, at through social networks
            (Instagram, Facebook, Twitter, LinkedIn, etc.) and / or page Web;
            <br />
            6. For any other activity aimed at promoting, maintaining, improving
            and evaluating the services of <strong>“GRUPO AEMA”</strong>.
          </p>
          <p>
            <strong>"GRUPO AEMA"</strong> undertakes that all Personal Data that
            the Holder provides, or that <strong>"GRUPO AEMA"</strong> obtains
            from it, will be treated under the strictest security measures that
            guarantee their confidentiality and good use for the purposes
            described in this Notice of Privacy.
          </p>
          <p>
            <strong>
              MEASURES TO LIMIT THE USE OR DISCLOSURE OF PERSONAL DATA.
            </strong>
            <br />
            In order to limit the use or disclosure of personal data,{" "}
            <strong>"GRUPO AEMA"</strong> safeguards said personal data
            implementing security, administrative, technical and physical
            measures. Personal data is managed and protected through the use of
            databases, administered in computer systems, of which the only and
            exclusively access to the persons designated for this purpose,
            without allowing their use, consultation, management or access to
            unauthorized persons. <strong>"GRUPO AEMA"</strong> has implemented
            applicable internal policies and processes to its staff, through
            which the personal data is used by a minimum of people, limiting the
            use of the means of reproduction and generating the obligation to
            destroy all those copies or reproductions of documents that contain
            personal data other than strictly essential for the proper
            performance of the functions of the personnel of{" "}
            <strong>"GRUPO AEMA"</strong> as well as the prohibition of
            extracting from the offices of <strong>"GRUPO AEMA"</strong> any
            type of information containing personal data.
          </p>
          <p>
            <strong>TRANSFERS OF PERSONAL DATA.</strong>
            <br /> By accepting and authorizing the processing of your personal
            data in the terms indicated in the Federal Law on Protection of
            Personal Data Held by Private Parties, expressly empowers "GRUPO
            AEMA" to transfer personal information for the purposes of
            fulfilling the purposes and commercial purposes between the Holder
            and <strong>"GRUPO AEMA".</strong>
            <br />
          </p>
          <p>
            <strong>USE OF COOKIES.</strong>
            <br />
            In order to provide you with a better service,{" "}
            <strong>"GRUPO AEMA"</strong> may use cookies. By cookies we must
            understand how data files are automatically downloaded and stored on
            the computer's hard drive computer equipment of the user when
            browsing a specific Internet page, which allows the exchange of
            information on the site and the user's browser. Likewise, the web
            beacon is a visible or hidden image embedded within a website or
            email, which is used to monitor the behavior of the user in these
            media. These cookies and / or web beacons can be disabled. To know
            how, consult the email{" "}
            <a href="mailto:contacto@grupoaema.com">contacto@grupoaema.com</a>.
            <br />
          </p>
          <p>
            <strong>
              MEANS TO EXERCISE THE RIGHTS OF ACCESS, RECTIFICATION,
              CANCELLATION OR OPPOSITION (ARCO).
            </strong>
            <br /> As the Holder of the personal data, you have the rights of
            access, rectification, cancellation and opposition provided for in
            Chapter II of the Federal Law on Protection of Personal Data in
            Possession of Individuals. To exercise any of these rights, the
            Holder must submit to <strong>"GRUPO AEMA"</strong> an application
            which must be accompanied by the following: the name of the Holder
            and address or other means so that <strong>"GRUPO AEMA"</strong>{" "}
            communicates its response; the documents that prove your identity,
            or where appropriate the representation of the Holder; the clear and
            precise description of the personal data with respect to which it is
            sought exercise any of the aforementioned rights, and any other
            element or document that facilitates the location of personal data.
            In the case of requests for rectification of personal data, the
            Holder must indicate, in addition to the aforementioned, the
            modifications to be made and provide the documentation to support
            your request. The requests referred to will be dealt with by the
            Customer Service Department of
            <strong>"GRUPO AEMA"</strong> , and should be sent via email to
            <a href="mailto:contacto@grupoaema.com">
              {" "}
              contacto@grupoaema.com
            </a>{" "}
            or in writing to the address of <strong>"GRUPO AEMA"</strong>
            indicated in the first paragraph of this Notice. The requests will
            be analyzed and if they are appropriate, you will be communicating
            through the email or address that for this purpose you have provided
            us, within the 20 days following the receipt of your requests in
            order to make effective any of the aforementioned rights. In the
            event that we do not obtain your express opposition for your
            personal data to be transferred in the form and terms described
            above, we will understand that you have given your consent tacitly
            to it.
          </p>
          <p>
            <strong>MODIFICATIONS TO THE PRIVACY NOTICE.</strong>
            <br />
            <strong>"GRUPO AEMA"</strong> reserves the right to modify this
            privacy notice to adapt it to Legislative or jurisprudential
            developments as well as industry practices, in which case,{" "}
            <strong>"GRUPO AEMA"</strong> will announce on this page the changes
            introduced with the anticipation that in its opinion is reasonable
            before to put it into practice. Certain services provided on or
            through the Site may contain particular conditions with provisions
            specific in terms of protection of Personal Data, in such case,
            Users must be subject to the privacy policies and conditions that
            are specifically mentioned in the contract that governs said
            services Any modification to this Privacy Notice may be consulted at{" "}
            <a href="https://www.grupoaema.com/">https://www.grupoaema.com/</a>
            <br />
          </p>
          <p>Last update date: January 11, 2021.</p>
        </div>
      ) : (
        <div className="wrapper terms-conditions-container">
          <p className="center">
            <strong>T&Eacute;RMINOS Y CONDICIONES DE USO.</strong>
          </p>
          <p>
            Estos t&eacute;rminos y condiciones son aplicables a la
            visualizaci&oacute;n, navegaci&oacute;n, compra y venta de productos
            a trav&eacute;s de{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>.
          </p>
          <p>
            Los presentes t&eacute;rminos y condiciones fueron elaborados de
            conformidad a lo establecido por los art&iacute;culos 76 bis y 76
            bis 1 de la Ley Federal de Protecci&oacute;n al Consumidor, mismos
            que establecen las disposiciones a las que se sujetar&aacute;n todas
            aquellas personas definidas por dicha norma mexicana como usuarios o
            clientes al ingresar al sitio{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>{" "}
            buscando en todo momento, garantizar, transparentar, informar y
            orientar adecuadamente sobre la navegaci&oacute;n y
            realizaci&oacute;n de cualquier tipo de transacci&oacute;n a
            trav&eacute;s del sitio web.
          </p>
          <p>
            Esta p&aacute;gina web es propiedad y est&aacute; operada por{" "}
            <strong>GRUPO AEMA DE M&Eacute;XICO, S.A. DE C.V. </strong>(en lo
            sucesivo <strong>&ldquo;GRUPO AEMA&rdquo;</strong>). Este sitio
            tiene por objeto la comercializaci&oacute;n y elaboraci&oacute;n de
            sellos de seguridad, etiquetas de seguridad, bolsas de seguridad,
            protecci&oacute;n de combustible, entre otros.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>T&Eacute;RMINOS Y CONDICIONES DE USO.</strong>
            </li>
          </ol>
          <p>
            Las pol&iacute;ticas de uso se establecen en los siguientes
            t&eacute;rminos y condiciones bajo los cuales se puede usar la
            p&aacute;gina web y los servicios ofrecidos por &ldquo;
            <strong>GRUPO AEMA&rdquo;</strong>. Al acceder o usar la
            p&aacute;gina web de nuestro servicio, usted aprueba que ha
            le&iacute;do, entendido y aceptado estar sujeto a estos
            t&eacute;rminos y condiciones de uso.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>DERECHOS DE AUTOR Y MARCAS DE GRUPO AEMA.</strong>
            </li>
          </ol>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo; </strong>es el propietario de la
            p&aacute;gina web{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>{" "}
            posee los derechos sobre todos los fondos de escritorio, iconos,
            caracteres, material gr&aacute;fico, im&aacute;genes, dise&ntilde;os
            gr&aacute;ficos, m&uacute;sica, textos, v&iacute;deos, software
            (programa de c&oacute;mputo) y otros contenidos de la p&aacute;gina
            web (en lo sucesivo el &ldquo;Contenido&rdquo;).
          </p>
          <p>
            El &ldquo;Contenido&rdquo; est&aacute; protegido por los derechos de
            autor. No podr&aacute; copiar, modificar, cargar, descargar,
            transmitir, volver a publicar, visualizar para distribuci&oacute;n a
            terceros con prop&oacute;sitos comerciales, o distribuir por
            cualquier procedimiento del Contenido de la p&aacute;gina web sin
            autorizaci&oacute;n previa por escrito de &ldquo;
            <strong>GRUPO AEMA&rdquo;</strong>. No se podr&aacute; utilizar el
            Contenido de la P&aacute;gina Web para ning&uacute;n
            prop&oacute;sito que no sea lo establecido en este Acuerdo.
          </p>
          <p>
            Todos los nombres, logos y marcas comerciales que aparecen en la
            P&aacute;gina Web son propiedad de &ldquo;
            <strong>GRUPO AEMA&rdquo; </strong>a menos de que en la
            p&aacute;gina se manifieste o clarifique lo contrario. No
            podr&aacute;n ser utilizados con prop&oacute;sitos que puedan llevar
            a confusi&oacute;n de los clientes, o con cualquier otro
            prop&oacute;sito sin autorizaci&oacute;n previa por escrito de
            &ldquo;<strong>GRUPO AEMA&rdquo;</strong>.
          </p>
          <p>
            Se proh&iacute;be expresamente al cliente y usuario modificar,
            alterar o suprimir, ya sea en forma total o parcial, los avisos,
            marcas, nombres comerciales, se&ntilde;as, anuncios, logotipos o en
            general cualquier contenido o indicaci&oacute;n contenida en{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>.
          </p>
          <p></p>
          <p>
            Su incumplimiento con este Acuerdo de T&eacute;rminos y Condiciones
            de Uso constituir&aacute; un incumplimiento de contrato y una
            violaci&oacute;n de la propiedad intelectual y marcas y derechos de
            propiedad intelectual.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>P&Aacute;GINA MUESTRA.</strong>
            </li>
          </ol>
          <p>
            Esta p&aacute;gina web representa una p&aacute;gina muestra. Toda la
            informaci&oacute;n y detalles al respecto de la disponibilidad,
            existencia, env&iacute;o o entrega de un producto se trata
            &uacute;nicamente de estimaciones. En caso de que, por cualquier
            raz&oacute;n, durante el procesamiento de su orden, el producto
            ordenado por usted ya no se encontrar&aacute; disponible le
            permitiremos pedir el mismo sujeto a la confirmaci&oacute;n de
            inventario. Si no podemos obtener el producto deseado, su pedido no
            podr&aacute; ser confirmado.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>PRODUCTOS PROPIOS O AJENOS</strong>
            </li>
          </ol>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> no es manufacturera de
            todos los productos que ofrece, tambi&eacute;n act&uacute;a como
            comercializadora del stock disponible en{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>
            <u>.</u> Por lo que se deslinda de cualquier responsabilidad
            relacionada con el procesamiento y contenido de estos, en el
            entendido de que el usuario est&aacute; consciente del rol de{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo; </strong>en{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>.
            <strong> &ldquo;GRUPO AEMA&rdquo;</strong> no es responsable de
            examinar o calificar, ni en ning&uacute;n caso garantizar, la
            funcionalidad de los productos de otras empresas o personas
            f&iacute;sicas, ni el contenido alojado en sus respectivos sitios
            web. <strong>&ldquo;GRUPO AEMA&rdquo;</strong> no asume
            responsabilidad alguna por las acciones, productos y contenidos de
            ninguna de tales empresas o individuos o cualesquiera otros
            terceros. Usted debe revisar detenidamente las declaraciones de
            privacidad y dem&aacute;s condiciones de uso de tales terceros.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>LIMITACI&Oacute;N DE RESPONSABILIDAD.</strong>
            </li>
          </ol>
          <p>
            Mientras &ldquo;<strong>GRUPO AEMA&rdquo;</strong> se esfuerza al
            m&aacute;ximo para incluir informaci&oacute;n actualizada y fiable
            en la p&aacute;gina web, no proporciona ninguna garant&iacute;a o
            representaci&oacute;n con respecto al contenido de esta. En la
            medida que lo permita la legislaci&oacute;n aplicable, &ldquo;
            <strong>GRUPO AEMA&rdquo;</strong> no ser&aacute; responsable de
            manera directa, indirecta, casual, resultante, de la p&eacute;rdida
            de datos, ingresos o beneficios, de indemnizaciones por da&ntilde;os
            y perjuicios y/o reclamos de terceros que resulten del uso, acceso,
            o incapacidad para usar la informaci&oacute;n y/o los productos
            ofrecidos en la p&aacute;gina web o por cualquier da&ntilde;o
            causado por el uso de la p&aacute;gina web o en conexi&oacute;n con
            esta. Adem&aacute;s, &ldquo;<strong>GRUPO AEMA&rdquo;</strong> no
            asume ninguna responsabilidad y no ser&aacute; responsable por los
            da&ntilde;os o perjuicios causados o por virus que podr&iacute;an
            infectar su sistema inform&aacute;tico u otras propiedades como
            consecuencia de su acceso, uso o
            exploraci&oacute;n/navegaci&oacute;n en la p&aacute;gina web o por
            la descarga de informaci&oacute;n a partir de la p&aacute;gina web.
          </p>
          <p>
            El usuario es el &uacute;nico responsable de todos los da&ntilde;os
            (incluidos los da&ntilde;os al contenido del Sitio) causados por el
            uso (o la transmisi&oacute;n) de cualquier contenido de usuario o
            del sitio y de las transacciones o sucesos relacionados.
          </p>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> no asume ninguna
            responsabilidad por el acceso no autorizado a la cuenta del usuario
            ni por el reenv&iacute;o autom&aacute;tico de mensajes o virus
            (causados por virus u otros medios) a otras personas cuya
            informaci&oacute;n el usuario haya incluido en los libros de
            direcciones en l&iacute;nea disponibles en este sitio.
          </p>
          <p>
            Las limitaciones y exclusiones de responsabilidad en estos
            T&eacute;rminos y Condiciones de Uso se aplican independientemente
            de si la responsabilidad se basa en incumplimiento de contrato,
            perjuicios, responsabilidad estricta, incumplimiento de
            garant&iacute;as o cualquier otra causa legal. La no validez de
            cualquiera de los t&eacute;rminos, condiciones o disposiciones del
            presente acuerdo no afectar&aacute; la aplicabilidad de aquellas
            porciones de este estimadas como exigibles por los tribunales
            pertinentes.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>AVISO DE PRIVACIDAD.</strong>
            </li>
          </ol>
          <p>
            Lea atentamente el Aviso de Privacidad disponible en (link del aviso
            de privacidad) que rige la manera de c&oacute;mo &ldquo;
            <strong>GRUPO AEMA&rdquo; </strong>manipular&aacute; cualquier
            informaci&oacute;n personal que usted proporcione.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong> </strong>
            </li>
          </ol>
          <p>
            Para hacer pedidos o comunicarse a trav&eacute;s del sitio y tener
            acceso al mismo, los recursos y parte o la totalidad del contenido
            del sitio web, es posible que se le solicite al usuario proporcionar
            detalles de registro e iniciar sesi&oacute;n para el uso de este
            sitio, los productos y el contenido de{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>
            <u>.</u> Es un requisito que todos los detalles de registro que
            proporcione el usuario sean y sigan siendo v&aacute;lidos,
            correctos, actualizados y completos. El usuario acepta notificar a{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> de inmediato en caso de
            cualquier cambio pertinente a su registro, siendo responsable de su
            actualizaci&oacute;n.
          </p>
          <p>
            Para el registro de la cuenta se piden datos personales como lo son
            nombre completo del usuario, domicilio de facturaci&oacute;n,
            domicilio de env&iacute;o, fecha de nacimiento, correo
            electr&oacute;nico y n&uacute;mero telef&oacute;nico.{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo; </strong>protege la privacidad del
            consumidor y s&oacute;lo utilizar&aacute; estos datos para los
            prop&oacute;sitos antes establecidos.
          </p>
          <p>
            Cuando el cliente utiliza la p&aacute;gina web de{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo; </strong>es el responsable de
            mantener la confidencialidad de los datos de su cuenta y su
            contrase&ntilde;a, as&iacute; como de restringir el acceso a su
            computadora y dem&aacute;s dispositivos, y usted asume la
            responsabilidad de cualesquier actividades realizadas desde su
            cuenta o utilizando su contrase&ntilde;a.
          </p>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo; </strong>se reserva el derecho de
            dar de baja cuentas, remover o editar contenido, cancelar ordenes en
            caso de violaci&oacute;n a las presentes Condiciones de Uso,
            Condiciones Generales de los Servicios o cualquier pol&iacute;tica,
            t&eacute;rminos y condiciones de{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong>, derechos de terceros o
            comisi&oacute;n de un delito o cualquier conducta que da&ntilde;e la
            reputaci&oacute;n de <strong>&ldquo;GRUPO AEMA&rdquo; </strong>o la
            experiencia al cliente.
          </p>
          <p>
            No obstante, el usuario, reconoce y entiende que{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>{" "}
            debe ser &uacute;nicamente utilizada para los fines mencionados
            anteriormente, en el entendido que de no ser as&iacute; se
            estar&iacute;an violando las pol&iacute;ticas de t&eacute;rminos y
            condiciones.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>ENV&Iacute;O Y RIESGO DE P&Eacute;RDIDA.</strong>
            </li>
          </ol>
          <p>
            Se realizar&aacute;n env&iacute;os en toda la Rep&uacute;blica
            Mexicana, <strong>&ldquo;GRUPO AEMA</strong>&rdquo; cuenta con un
            listado de paqueter&iacute;as manejadas dependiendo de la
            ubicaci&oacute;n del domicilio otorgado por el cliente. Las
            paqueter&iacute;as utilizadas quedar&aacute;n sujetas a
            disponibilidad y variar&aacute;n entre el siguiente listado:{" "}
            <em>
              GRUPO PGL M&Eacute;XICO, OPERADORA DE SERVICIOS PAQUETEXPRESS,
              TRANSPORTES CASTORES DE BAJA CALIFORNIA, VILLAGON EXPRESS PACK,
              DHL EXPRESS M&Eacute;XICO, SERVICIOS DE PAQUETER&Iacute;A Y
              ENV&Iacute;OS FLECHA AMARILLA, o bien otra que se decida utilizar.
            </em>
          </p>
          <p>
            El usuario reconoce y acepta que{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> no cuenta con control de
            la log&iacute;stica, horarios de entrega, ruta utilizada, calidad de
            servicio y demoras posteriores, de parte de la paqueter&iacute;a
            seleccionada por <strong>&ldquo;GRUPO AEMA&rdquo;</strong> para el
            env&iacute;o del pedido realizado por el cliente ya que esta
            selecci&oacute;n depende &uacute;nica y exclusivamente de la
            disponibilidad de paqueter&iacute;as a la fecha del
            pedido/env&iacute;o.
          </p>
          <p>
            La propiedad y el riesgo de p&eacute;rdida de los productos le
            ser&aacute; transferida a usted en el momento en que entreguemos los
            productos al transportista correspondiente.
            <strong> &ldquo;GRUPO AEMA&rdquo; </strong>entrega en entera
            conformidad y con la calidad garantizada del producto al
            transportista y se deslinda de la responsabilidad del servicio
            prove&iacute;do por el transportista.
          </p>
          <p>
            Sin embargo, se enviar&aacute; una gu&iacute;a de env&iacute;o para
            que el usuario/cliente, pueda dar seguimiento a la ruta de su
            pedido, en el entendido de que, aun cuando
            <strong> &ldquo;GRUPO AEMA&rdquo;</strong> otorga la gu&iacute;a de
            env&iacute;o, no es responsable de la misma y la titularidad le
            pertenece &uacute;nicamente a la paqueter&iacute;a correspondiente.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>PAGO POR INTERMEDIARIO.</strong>
            </li>
          </ol>
          <p>
            El usuario seleccionar&aacute; los productos de su elecci&oacute;n y
            se ir&aacute;n a&ntilde;adiendo a un carrito de compras. Al momento
            de querer finalizar su pedido deber&aacute; elegir un m&eacute;todo
            de pago mediante el usuario ya registrado en el sitio web. De no
            encontrarse registrado, el usuario est&aacute; consciente de que
            tendr&aacute; que crear una cuenta para continuar con su pedido. Los
            m&eacute;todos de pago ser&aacute;n a trav&eacute;s de un
            intermediario, el cual se encargar&aacute; de tramitar el pago ya
            sea con tarjeta de cr&eacute;dito o d&eacute;bito mediante su
            software. El usuario al momento de pagar est&aacute; aceptando los
            t&eacute;rminos y condiciones del intermediario los cuales se pueden
            encontrar en la siguiente p&aacute;gina:{" "}
            <a href="https://stripe.com/es">https://stripe.com/es</a>.{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> no se hace responsable de
            los datos bancarios del Usuario, ya que en ning&uacute;n momento
            conocer&aacute; dichos n&uacute;meros de cuenta y nip
            correspondientes.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>ANALYTICS HEATMAP.</strong>
            </li>
          </ol>
          <p>
            Este sitio web hace uso de analytics heatmap &uacute;nicamente para
            la Optimizaci&oacute;n OnPage de la p&aacute;gina web. Analytics
            heatmap traducido al espa&ntilde;ol hace referencia a un &ldquo;mapa
            de calor anal&iacute;tico&rdquo; parte de la p&aacute;gina web y su
            uso se define como Optimizaci&oacute;n OnPage que hace referencia a
            una &ldquo;optimizaci&oacute;n en la p&aacute;gina&rdquo; ya que
            recopila las &aacute;reas de oportunidad del sitio web. Consiste en
            gr&aacute;ficos que permiten identificar los puntos calientes de la
            web, -las zonas que m&aacute;s llaman la atenci&oacute;n a los
            usuarios permitiendo analizar y mejorar la utilidad y accesibilidad
            de la p&aacute;gina web.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>ENLACES Y P&Aacute;GINAS WEB DE TERCEROS.</strong>
            </li>
          </ol>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> no es responsable del
            contenido de p&aacute;ginas o enlaces a cualquier otra p&aacute;gina
            web es entera responsabilidad del usuario. &ldquo;
            <strong>GRUPO AEMA&rdquo; </strong>puede proporcionar enlaces a
            otras p&aacute;ginas web, pero la introducci&oacute;n de tales
            enlaces es a conveniencia del usuario. &ldquo;
            <strong>GRUPO AEMA&rdquo; </strong>no hace representaciones y
            renuncia a todas las garant&iacute;as expl&iacute;citas o
            impl&iacute;citas, con respecto a la fiabilidad, validez, idoneidad,
            legalidad u otros de cualquier material o informaci&oacute;n
            contenida en tales p&aacute;ginas web.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>REPOSICIONES Y DEVOLUCIONES.</strong>
            </li>
          </ol>
          <p>
            Para efectos de cumplir con los art&iacute;culos 54, fracci&oacute;n
            II y III, 92 y dem&aacute;s relativos de la Ley Federal de
            Protecci&oacute;n al Consumidor,{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> se har&aacute; cargo de
            devoluciones o reposiciones de mercanc&iacute;a &uacute;nica y
            exclusivamente cuando se trate de defectos de f&aacute;brica
            debidamente comprobados por el usuario, por lo que para efectos de
            lo anterior, se establece el procedimiento siguiente:
          </p>
          <p>
            1.- Los usuarios que identifiquen uno o varios productos con
            defectos de fabrica deber&aacute;n comunicarse con{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> v&iacute;a correo
            electr&oacute;nico a la siguiente direcci&oacute;n:{" "}
            <a href="mailto:ventas@grupoaema.com">ventas@grupoaema.com</a> para
            efectos de demostrar el defecto de f&aacute;brica del producto y
            solicitar su devoluci&oacute;n o reposici&oacute;n.
          </p>
          <p>
            En el correo electr&oacute;nico el usuario deber&aacute;
            env&iacute;ar im&aacute;genes de la mercanc&iacute;a que cuenta con
            el defecto de f&aacute;brica, as&iacute; como deber&aacute;
            manifestar su deseo de devolverla o reponerla e indicar&aacute; a
            trav&eacute;s de cual paqueter&iacute;a de las se&ntilde;aladas en
            el inciso H) de la presente carta desea que se realice la
            devoluci&oacute;n.
          </p>
          <p>
            Para efectos de que la devoluci&oacute;n o reposici&oacute;n sea
            procedente, el usuario deber&aacute; enviar a{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> el correo
            electr&oacute;nico descrito en el t&eacute;rmino de 1 (un)
            d&iacute;a natural despu&eacute;s de haberse dado cuenta del defecto
            de f&aacute;brica.
          </p>
          <p>
            2.-<strong> &ldquo;GRUPO AEMA&rdquo;</strong> tendr&aacute; un
            periodo de 2 (dos) d&iacute;as h&aacute;biles despu&eacute;s de
            recibido el correo electr&oacute;nico con las im&aacute;genes
            adjuntas de la mercanc&iacute;a defectuosa para notificar mediante
            el mismo medio al usuario de si procede o no la devoluci&oacute;n o
            reposici&oacute;n de dicha mercanc&iacute;a. El usuario reconoce que
            la procedencia de la devoluci&oacute;n o reposici&oacute;n es
            &uacute;nicamente por presentarse defecto de f&aacute;brica
            debidamente comprobado.
          </p>
          <p>
            {" "}
            3.- <strong>&ldquo;GRUPO AEMA&rdquo;</strong> ser&aacute; la
            encargada de cubrir los costos de transporte y env&iacute;o de
            mercanc&iacute;a por la devoluci&oacute;n o reposici&oacute;n
            solicitada, por lo que en caso de que la devoluci&oacute;n o
            reposici&oacute;n por defecto de f&aacute;brica proceda, se le
            har&aacute; llegar al usuario una gu&iacute;a de env&iacute;o
            prepagada de la paqueter&iacute;a que haya indicado en el correo
            electr&oacute;nico a trav&eacute;s del que haya realizado su
            solicitud.
          </p>
          <p>
            No obstante, <strong>&ldquo;GRUPO AEMA&rdquo;</strong> podr&iacute;a
            designar otro servicio de paqueter&iacute;a si el elegido por el
            usuario no se encontrara disponible para la realizaci&oacute;n del
            env&iacute;o, desde luego notificando con anterioridad al usuario a
            trav&eacute;s de correo electr&oacute;nico.
          </p>
          <p>
            4.- En caso de que sea procedente la devoluci&oacute;n de la
            mercanc&iacute;a <strong>&ldquo;GRUPO AEMA&rdquo;</strong>{" "}
            devolver&aacute; el monto equivalente a la msima conforme al mismo
            m&eacute;todo realizado en la compra inicial en el portal{" "}
            <a href="https://www.grupoaema.com">https://www.grupoaema.com</a>.
            La devoluci&oacute;n del monto ser&aacute; realizada hasta en tanto{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> reciba la mercanc&iacute;a
            defectuosa en su domicilio.
          </p>
          <p>
            5.- En caso de que sea procedente la reposici&oacute;n de la
            mercanc&iacute;a <strong>&ldquo;GRUPO AEMA&rdquo;</strong> tambien
            ser&aacute; la encargada del costo por el env&iacute;o de la nueva
            mercanc&iacute;a al usuario. Dicho env&iacute;o se realizar&aacute;
            a la misma direcci&oacute;n proporcionada en la compra incial por el
            usuario.
          </p>
          <p>
            6.- La devoluci&oacute;n o reposici&oacute;n de la mercanc&iacute;a
            se llevar&aacute; a cabo en un periodo aproximado de 5 (cinco)
            d&iacute;as h&aacute;biles desde la respuesta positiva de
            procedencia v&iacute;a correo electr&oacute;nico.
          </p>
          <p>
            7.- Por caso fortuito se entiende un evento de la naturaleza que es
            impredicible y por fuerza mayor un evento causado por el hombre que
            es inevitable. En caso de que{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> ya sea por caso fortuito o
            fuerza mayor se encuentre imposibilitada para cumplir con el
            procedimiento de devoluci&oacute;n o reposici&oacute;n en los
            t&eacute;rminos acordados en la presente, no habr&aacute;
            responsabilidad alguna para esta, sino que dar&aacute; aviso al
            usuario en el momento m&aacute;s oportuno sobre el retraso para la
            devoluci&oacute;n o reposici&oacute;n y se acordaran nuevos
            d&iacute;as para cumplir con el presente procedimiento.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>
                DERECHO A CAMBIAR Y MODIFICAR LOS T&Eacute;RMINOS.
              </strong>
            </li>
          </ol>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo; </strong>se reserva el derecho de
            modificar estos t&eacute;rminos y condiciones de uso actualizando su
            publicaci&oacute;n. El usuario acepta cumplir con dichas
            modificaciones. Nuestra empresa le invita a consultar regularmente
            la p&aacute;gina web para conocer las modificaciones que puedan
            aplicarse.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>JURISDICCI&Oacute;N</strong>
            </li>
          </ol>
          <p>
            Todas las diferencias que resulten o est&eacute;n relacionadas con
            el uso de la p&aacute;gina web estar&aacute;n exclusivamente sujetas
            a la jurisdicci&oacute;n de los Tribunales de Monterrey, Nuevo
            Le&oacute;n, M&eacute;xico.
          </p>
          <p></p>
        </div>
      )}
    </>
  );
};

export default TermsConditions;
