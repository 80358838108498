import { TypedHomePageQuery } from "@temp/views/Home/queries";
import noPhotoImg from "images/no-photo.svg";
import React, {useEffect} from "react";
import "./scss/index.scss";

import { FormattedMessage } from "react-intl";

import { Swiper, SwiperSlide } from "swiper/react/swiper-react";
import "swiper/swiper-bundle.css";

// import Swiper core and required modules
import SwiperCore, { Navigation } from "swiper";

SwiperCore.use([Navigation]);

import { IFilterAttributes, IFilters } from "@types";
import { Link } from "react-router-dom";
import {
  Breadcrumbs,
  extractBreadcrumbs,
  ProductsFeatured,
} from "../../components";

// import { ProductListHeader } from "../../@next/components/molecules";
import { ProductList } from "../../@next/components/organisms";
// import { FilterSidebar } from "../../@next/components/organisms/FilterSidebar";
// import { TypedMainMenuQuery }  from "../../components/MainMenu/queries";

import { generateCategoryUrl, maybe } from "../../core/utils";

import { Category_category, Category_products } from "./gqlTypes/Category";

interface SortItem {
  label: string;
  value?: string;
}

interface SortOptions extends Array<SortItem> {}

interface PageProps {
  activeFilters: number;
  attributes: IFilterAttributes[];
  activeSortOption: string;
  category: Category_category;
  displayLoader: boolean;
  filters: IFilters;
  hasNextPage: boolean;
  products: Category_products;
  sortOptions: SortOptions;
  clearFilters: () => void;
  onLoadMore: () => void;
  onAttributeFiltersChange: (attributeSlug: string, value: string) => void;
  onOrder: (order: { value?: string; label: string }) => void;
}

const Page: React.FC<PageProps> = ({
  activeFilters,
  activeSortOption,
  attributes,
  category,
  displayLoader,
  hasNextPage,
  clearFilters,
  onLoadMore,
  products,
  filters,
  onOrder,
  sortOptions,
  onAttributeFiltersChange,
}) => {
  const canDisplayProducts = maybe(
    () => !!products.edges && products.totalCount !== undefined
  );

  const productList = [];

  function sortName(a, b, c, d, e, f) {
    productList.push(a);
    productList.push(f);
    productList.push(e);
    productList.push(b);
    productList.push(c);
    productList.push(d);
  }

  const hasProducts = canDisplayProducts && !!products.totalCount;

  const handleScroll = () => {
    window.addEventListener('scroll',()=>{
    scroll();
    });
    const barr = document.getElementById("product_navbar");
    const categories = document.getElementById("wrapper");

    const scroll = () => {
      const height = document.documentElement.scrollTop;

      if (height > 90) {
        barr.classList.add("page__title_hide");
        categories.classList.add("wrapper_margintop");
      } else {
        barr.classList.remove("page__title_hide");
        categories.classList.remove("wrapper_margintop");
      }
    }
  };

  useEffect(() => {
    handleScroll();
  }, [handleScroll]);
  // const [showFilters, setShowFilters] = React.useState(false);
  // const [showSubcategory, setShowSubcategory] = React.useState([]);

  // const getAttribute = (attributeSlug: string, valueSlug: string) => {
  //   return {
  //     attributeSlug,
  //     valueName: attributes
  //       .find(({ slug }) => attributeSlug === slug)
  //       .values.find(({ slug }) => valueSlug === slug).name,
  //     valueSlug,
  //   };
  // };

  // const handleClick= (index)=>{
  //   const newCategories = [...showSubcategory];
  //   newCategories[index] = !showSubcategory[index];
  //   setShowSubcategory(newCategories);
  // }

  // const activeFiltersAttributes =
  //   filters &&
  //   filters.attributes &&
  //   Object.keys(filters.attributes).reduce(
  //     (acc, key) =>
  //       acc.concat(
  //         filters.attributes[key].map(valueSlug => getAttribute(key, valueSlug))
  //       ),
  //     []
  //   );

  // @ts-ignore
  // @ts-ignore
  // @ts-ignore
  return (
    <div className="category">
      <div className="categories_list">
      <div className="page__title__container" id="product_navbar">
          <h1 className="page__title">
            <FormattedMessage id="header_products" defaultMessage="Productos" />
          </h1>
        </div>
        <div className="wrapper" id="wrapper">
          <div className="category__wrapper__categories">
            {/* <div id="mySidenav"></div> */}
            <div>
              {/*<TypedMainMenuQuery renderOnError displayLoader={false}>*/}
              {/*  {({ data }) => {*/}
              {/*    const items = maybe(() => data.shop.navigation.main.items, []);*/}

              {/*    if(showSubcategory.length === 0 && items.length > 0) {*/}
              {/*      setShowSubcategory(Array(items.length).fill(false));*/}
              {/*    }*/}
              {/*    return (*/}
              {/*      <ul>*/}
              {/*        {items.map((item, i) => {*/}
              {/*          return(*/}
              {/*            <li className="menu-product" key={i}>*/}
              {/*              <NavLink className="title-dropdown item-menu" item={item} />*/}
              {/*              <i className="dropdown-btn" onClick={()=>handleClick(i)}>+</i>*/}
              {/*              {*/}
              {/*                showSubcategory[i]?*/}
              {/*                <ul className="dropdown-container">*/}

              {/*                  {item.children.map(subItem => {*/}
              {/*                    return(*/}
              {/*                    <li>*/}
              {/*                      <div className="arrow-submenu"></div>*/}
              {/*                      <NavLink className="submenu-item" item={subItem} />*/}
              {/*                    </li>*/}
              {/*                    );*/}
              {/*                  })}*/}
              {/*                </ul>*/}
              {/*                : <div></div>*/}
              {/*              }*/}
              {/*            </li>*/}
              {/*          );*/}
              {/*        })*/}
              {/*        }*/}
              {/*    </ul>*/}
              {/*    );*/}
              {/*  }}*/}
              {/*</TypedMainMenuQuery>*/}
              <TypedHomePageQuery renderOnError displayLoader={false}>
                {({ data, loading }) => {
                  sortName(
                    data.categories.edges[0],
                    data.categories.edges[1],
                    data.categories.edges[2],
                    data.categories.edges[3],
                    data.categories.edges[4],
                    data.categories.edges[5]
                  );

                  return (
                    <>
                      <Swiper
                        navigation={true}
                        className="categories--large"
                        spaceBetween={32}
                        slidesPerView={6}
                      >
                        {productList.map(({ node: category }) => (
                          <SwiperSlide
                            key={category.id}
                            className="category-page__categories__wrapper"
                          >
                            <Link
                              to={generateCategoryUrl(
                                category.id,
                                category.name
                              )}
                              key={category.id}
                            >
                              <div className="category-page__categories__imageContainer">
                                <img
                                  src={
                                    category.backgroundImage
                                      ? category.backgroundImage.url
                                      : noPhotoImg
                                  }
                                  alt="category image"
                                />
                              </div>
                              <p className="category-page__categories__title">
                                {category.name}
                              </p>
                            </Link>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        navigation={true}
                        className="categories--medium"
                        spaceBetween={32}
                        slidesPerView={3}
                      >
                        {productList.map(({ node: category }) => (
                          <SwiperSlide
                            key={category.id}
                            className="category-page__categories__wrapper"
                          >
                            <Link
                              to={generateCategoryUrl(
                                category.id,
                                category.name
                              )}
                              key={category.id}
                            >
                              <div className="category-page__categories__imageContainer">
                                <img
                                  src={
                                    category.backgroundImage
                                      ? category.backgroundImage.url
                                      : noPhotoImg
                                  }
                                  alt="category image"
                                />
                              </div>
                              <p className="category-page__categories__title">
                                {category.name}
                              </p>
                            </Link>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                      <Swiper
                        navigation={true}
                        className="categories--small"
                        spaceBetween={24}
                        slidesPerView={2}
                      >
                        {productList.map(({ node: category }) => (
                          <SwiperSlide
                            key={category.id}
                            className="category-page__categories__wrapper"
                          >
                            <Link
                              to={generateCategoryUrl(
                                category.id,
                                category.name
                              )}
                              key={category.id}
                            >
                              <div className="category-page__categories__imageContainer">
                                <img
                                  src={
                                    category.backgroundImage
                                      ? category.backgroundImage.url
                                      : noPhotoImg
                                  }
                                  alt="category image"
                                />
                              </div>
                              <p className="category-page__categories__title">
                                {category.name}
                              </p>
                            </Link>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </>
                  );
                }}
              </TypedHomePageQuery>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="category__wrapper__products">
          <Breadcrumbs breadcrumbs={extractBreadcrumbs(category)} />
          {/*<FilterSidebar*/}
          {/*  show={showFilters}*/}
          {/*  hide={() => setShowFilters(false)}*/}
          {/*  onAttributeFiltersChange={onAttributeFiltersChange}*/}
          {/*  attributes={attributes}*/}
          {/*  filters={filters}*/}
          {/*/>*/}
          {/*<ProductListHeader*/}
          {/*  activeSortOption={activeSortOption}*/}
          {/*  openFiltersMenu={() => setShowFilters(true)}*/}
          {/*  numberOfProducts={products ? products.totalCount : 0}*/}
          {/*  activeFilters={activeFilters}*/}
          {/*  activeFiltersAttributes={activeFiltersAttributes}*/}
          {/*  clearFilters={clearFilters}*/}
          {/*  sortOptions={sortOptions}*/}
          {/*  onChange={onOrder}*/}
          {/*  onCloseFilterAttribute={onAttributeFiltersChange}*/}
          {/*/>*/}
          {canDisplayProducts && (
            <ProductList
              // @ts-ignore
              products={products.edges.map((edge) => edge.node)}
              canLoadMore={hasNextPage}
              loading={displayLoader}
              onLoadMore={onLoadMore}
            />
          )}
          <div id="containerRef" />
        </div>

        {!hasProducts && <ProductsFeatured title="You might like" />}
      </div>
    </div>
  );
};

export default Page;
