import * as React from "react";
import { useAlert } from "react-alert";
import {  useIntl } from "react-intl";

import { StringParam, useQueryParams } from "use-query-params";

import { BASE_URL } from "../../core/config";

import { RouteComponentProps } from "react-router";
import { TypedAccountConfirmMutation } from "./queries";

import "./scss/index.scss";

const AccountConfirm: React.FC<RouteComponentProps> = ({ history }) => {
  const [query] = useQueryParams({
    email: StringParam,
    token: StringParam,
  });

  const alert = useAlert();
  const intl = useIntl();
  const displayConfirmationAlert = anyErrors => {
    alert.show(
      {
        content:
          anyErrors.length > 0
            ? anyErrors.map(error => error.message).join(" ")
            : intl.formatMessage({id:"account_login",defaultMessage:"Ahora puedes iniciar sesión"}),
        title: anyErrors.length > 0 ? "Error" : intl.formatMessage({id:"account_confirmed",defaultMessage:"Cuenta Confirmada"}),
      },
      { type: anyErrors.length > 0 ? "error" : "success", timeout: 5000 }
    );
  };

  React.useEffect(() => {
    this.accountManagerFn({
      variables: { email: query.email, token: query.token },
    })
      .then(result => {
        const possibleErrors = result.data.confirmAccount.errors;
        displayConfirmationAlert(possibleErrors);
      })
      .catch(() => {
        const errors = [
          {
            message: intl.formatMessage({id:"account_error",defaultMessage:"Algo Salió mal mientras activábamos tu cuenta."}),
          },
        ];
        displayConfirmationAlert(errors);
      })
      .finally(() => {
        history.push(BASE_URL);
      });
  }, []);

  return (
    <TypedAccountConfirmMutation>
      {accountConfirm => {
        this.accountManagerFn = accountConfirm;
        return <div></div>;
      }}
    </TypedAccountConfirmMutation>
  );
};

export default AccountConfirm;
