import { Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Chip, ErrorMessage, Input } from "@components/atoms";
import * as S from "./styles";
import { IProps } from "./types";

export const DiscountForm: React.FC<IProps> = ({
  handleSubmit,
  discount,
  errors,
  formId,
  formRef,
}: IProps) => {
  const promoCode = discount && discount.promoCode;
  const intl = useIntl();
  const [inputCode, setInputCode] = React.useState("");
  const [tempPromoCode, setTempPromoCode] = React.useState(promoCode);

  const handleApplyBtnClick = (newInputCode: string) => {
    setTempPromoCode(newInputCode);
    setInputCode("");
    if (handleSubmit) {
      handleSubmit(newInputCode);
    }
  };

  const handleRemoveBtnClick = (newInputCode: string) => {
    setTempPromoCode(undefined);
    setInputCode(newInputCode);
    if (handleSubmit) {
      handleSubmit(null);
    }
  };

  return (
    <Formik
      initialValues={{
        errors,
        inputCode,
        tempPromoCode,
      }}
      enableReinitialize={true}
      onSubmit={(values, { setSubmitting }) => {
          if (handleSubmit) {
            handleSubmit({
              promoCode: values.tempPromoCode,
            });
          }
         setSubmitting(false);
      }}
    >
      {({
        handleChange,
        handleSubmit,
        handleBlur,
        values,
        setFieldValue,
        setFieldTouched,
      }) => {
        const hasErrors = !!(values.errors && values.errors.length);

        return (
          <S.DiscountForm
            id={formId}
            ref={formRef}
            onSubmit={handleSubmit}
          >
            <S.Input>
              <S.InputWithButton>
                <S.InputWrapper>
                  <Input
                    data-cy="checkoutPaymentPromoCodeInput"
                    error={hasErrors}
                    name="inputCode"
                    value={values.inputCode}
                    label={intl.formatMessage({
                      defaultMessage: "Código",
                      id: "discount_code", 
                    })}
                    onChange={handleChange}
                  />
                </S.InputWrapper>
                <S.ButtonWrapper>
                  <Button
                    type="button"
                    data-cy="checkoutPaymentPromoCodeBtn"
                    onClick={() => handleApplyBtnClick(values.inputCode)}
                  >
                    <FormattedMessage
                      id="discount_apply"
                      defaultMessage="Aplicar"
                    />
                  </Button>
                </S.ButtonWrapper>
              </S.InputWithButton>
              <ErrorMessage errors={values.errors} />
            </S.Input>
            {values.tempPromoCode && (
              <>
                <span>
                  <FormattedMessage
                    id="discount_code"
                    defaultMessage="Código"
                  />
                  :
                </span>
                <S.ChipsWrapper>
                  <Chip onClose={() => handleRemoveBtnClick(values.inputCode)}>
                    <span data-cy="checkoutPaymentPromoCodeChip">
                      {values.tempPromoCode}
                    </span>
                  </Chip>
                </S.ChipsWrapper>
              </>
            )}
          </S.DiscountForm>
        );
      }}
    </Formik>
  );
};
