import React, { useState } from "react";
import { mediumScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import { useCart, useSignOut, useUserDetails } from "@sdk/react";

import { Icon } from "@temp/@next/components/atoms/Icon";
import Media from "react-media";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ReactSVG from "react-svg";
import { MainMenuSubItem } from "./gqlTypes/MainMenuSubItem";
import MobileMenu from "./MobileMenu";
import { TypedMainMenuQuery } from "./queries";

import {
  MenuDropdown,
  Offline,
  Online,
  OverlayContext,
  OverlayTheme,
  OverlayType,
} from "..";
import * as appPaths from "../../app/routes";

import { FormattedMessage, useIntl } from "react-intl";

import cartImg from "images/cart.svg";
import facebookIcon from "images/facebook-header.svg";
import instagramIcon from "images/instagram-header.svg";
import logoImg from "images/Logotipo-01.png";
import searchImg from "images/search.svg";
import twitterIcon from "images/twitter-header.svg";
import userImg from "images/user.svg";
import youtubeIcon from "images/youtube-header.svg";
import { generateCategoryUrl, maybe } from "../../core/utils";

const MainMenu: React.FC = () => {
  const [showMobileMenu, setShowMobileMenu] = useState<boolean>(false);

  const { data: user } = useUserDetails();
  const [signOut] = useSignOut();
  const { items } = useCart();

  const handleSignOut = () => {
    signOut();
  };
  const intl = useIntl();

  const cartItemsQuantity =
    (items &&
      items.reduce((prevVal, currVal) => prevVal + currVal.quantity, 0)) ||
    0;

  return (
    <>
      <OverlayContext.Consumer>
        {(overlayContext) => (
          <div className="main-menu__container">
            <nav className="main-menu wrapper" id="header">
              <div className="main-menu__left">
                <Link to={appPaths.baseUrl}>
                  <img src={logoImg} alt="logo" className="logo" />
                  {/* <ReactSVG path={logoImg} /> */}
                </Link>
              </div>

              <div className="main-menu__center">
                <ul>
                  <li>
                    <HashLink to="/#historia">
                      <FormattedMessage
                        id="header_about_us"
                        defaultMessage="Nosotros"
                      />
                    </HashLink>
                  </li>
                  <li>
                    <TypedMainMenuQuery renderOnError displayLoader={false}>
                      {({ data }) => {
                        const items = maybe(
                          () => data.shop.navigation.main.items,
                          []
                        );
                        let item: MainMenuSubItem;
                        if (items.length > 0) {
                          item = items[0];
                        }
                        return (
                          <div>
                            {item ? (
                              <Link
                                to={`/products${generateCategoryUrl(
                                  item.category.id,
                                  item.category.name
                                )}`}
                              >
                                <FormattedMessage
                                  id="header_products"
                                  defaultMessage="Productos"
                                />
                              </Link>
                            ) : (
                              <div></div>
                            )}
                          </div>
                        );
                      }}
                    </TypedMainMenuQuery>
                  </li>
                  <li>
                    <HashLink to="/#contacto">
                      <FormattedMessage
                        id="header_contact"
                        defaultMessage="Contacto"
                      />
                    </HashLink>
                  </li>
                </ul>
              </div>

              <div className="main-menu__right">
                <div className="main-menu__socialMedia ">
                  <ul className="socialMedia">
                    <li >
                      <a href="https://twitter.com/GrupoAema" target="_blank">
                        <ReactSVG path={twitterIcon} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/grupoaemamx/"
                        target="_blank"
                      >
                        <ReactSVG path={instagramIcon} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.youtube.com/channel/UCeDVShdVHdUGjQj2zOJbgbA"
                        target="_blank"
                      >
                        <ReactSVG path={youtubeIcon} />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/AemaSeguridad/"
                        target="_blank"
                      >
                        <ReactSVG path={facebookIcon} />
                      </a>
                    </li>
                  </ul>
                </div>
                <ul>
                  <Online>
                    <li
                      className="main-menu__search"
                      onClick={() =>
                        overlayContext.show(
                          OverlayType.search,
                          OverlayTheme.right
                        )
                      }
                    >
                      <Media
                        query={{ minWidth: mediumScreen }}
                        render={() => <span></span>}
                      />
                      <ReactSVG path={searchImg} />
                    </li>
                    <li
                      className="main-menu__icon main-menu__cart"
                      onClick={() => {
                        overlayContext.show(
                          OverlayType.cart,
                          OverlayTheme.right
                        );
                      }}
                    >
                      <ReactSVG path={cartImg} className="icon-svg" />
                      {cartItemsQuantity > 0 ? (
                        <span className="main-menu__cart__quantity">
                          {cartItemsQuantity}
                        </span>
                      ) : null}
                    </li>

                    <Media
                      query={{ minWidth: 300 }}
                      render={() => (
                        <>
                          {user ? (
                            <MenuDropdown
                              head={
                                <li className="main-menu__icon main-menu__user--active">
                                  <ReactSVG path={userImg} />
                                </li>
                              }
                              content={
                                <ul className="main-menu__dropdown">
                                  <li data-testid="my_account__link">
                                    <Link to={appPaths.accountUrl}>
                                      <FormattedMessage
                                        id="header_my_account"
                                        defaultMessage="Mi Cuenta"
                                      />
                                    </Link>
                                  </li>
                                  <li data-testid="order_history__link">
                                    <Link to={appPaths.orderHistoryUrl}>
                                      <FormattedMessage
                                        id="header_orders_history"
                                        defaultMessage="Historial de Ordenes"
                                      />
                                    </Link>
                                  </li>
                                  <li data-testid="address_book__link">
                                    <Link to={appPaths.addressBookUrl}>
                                      <FormattedMessage
                                        id="header_address"
                                        defaultMessage="Direcciones"
                                      />
                                    </Link>
                                  </li>
                                  <li
                                    onClick={handleSignOut}
                                    data-testid="logout-link"
                                  >
                                    <FormattedMessage
                                      id="header_logout"
                                      defaultMessage="Cerrar Sesión"
                                    />
                                  </li>
                                </ul>
                              }
                            />
                          ) : (
                            <li
                              data-testid="login-btn"
                              className="main-menu__icon"
                              onClick={() =>
                                overlayContext.show(
                                  OverlayType.login,
                                  OverlayTheme.right
                                )
                              }
                            >
                              <ReactSVG path={userImg} />
                            </li>
                          )}
                        </>
                      )}
                    />
                    {/* Hamburger icon and mobile menu */}
                    <Media
                      query={{ maxWidth: 750 }}
                      render={() => (
                        <>
                          <li
                            className="main-menu__icon"
                            onClick={() => setShowMobileMenu(true)}
                          >
                            <Icon name="hamburger" />
                          </li>
                          <MobileMenu
                            show={showMobileMenu}
                            onClose={() => setShowMobileMenu(false)}
                          />
                        </>
                      )}
                    />
                    {/*  <MenuDropdown*/}
                    {/*  head={*/}
                    {/*    <li className="main-menu__icon main-menu__languages">*/}
                    {/*      <FormattedMessage*/}
                    {/*        defaultMessage="Idiomas "*/}
                    {/*        description="label"*/}
                    {/*        id="language"*/}
                    {/*      />*/}
                    {/*    </li>*/}
                    {/*  }*/}
                    {/*  content={*/}
                    {/*    <ul className="main-menu__dropdown">*/}
                    {/*      <li data-testid="my_account__link">*/}
                    {/*        <button onClick={this.handleClick}></button>*/}
                    {/*        <label onClick={() => contextIntl.setLocale(Locale.EN)}>English</label>*/}
                    {/*      </li>*/}
                    {/*      <li data-testid="order_history__link">*/}
                    {/*        <label onClick={() => contextIntl.setLocale(Locale.ES)}>Español</label>*/}
                    {/*      </li>*/}
                    {/*    </ul>*/}
                    {/*  }*/}
                    {/*/>*/}
                  </Online>
                  <Offline>
                    <li className="main-menu__offline">
                      <Media
                        query={{ minWidth: mediumScreen }}
                        render={() => (
                          <span>
                            {intl.formatMessage({
                              defaultMessage: "Desconectado",
                              id: "offline",
                            })}
                          </span>
                        )}
                      />
                    </li>
                  </Offline>
                </ul>
              </div>
            </nav>
          </div>
        )}
      </OverlayContext.Consumer>

      <div className="main-menu__space" />

      <div className="phone-menu">
        <HashLink to="/#historia" className="link">
          <FormattedMessage id="header_about_us" defaultMessage="Nosotros" />
        </HashLink>
        <TypedMainMenuQuery renderOnError displayLoader={false}>
          {({ data }) => {
            const items = maybe(() => data.shop.navigation.main.items, []);
            let item: MainMenuSubItem;
            if (items.length > 0) {
              item = items[0];
            }
            return (
              <>
                {item ? (
                  <Link
                    className="link"
                    to={`/products${generateCategoryUrl(
                      item.category.id,
                      item.category.name
                    )}`}
                  >
                    <FormattedMessage
                      id="header_products"
                      defaultMessage="Productos"
                    />
                  </Link>
                ) : (
                  <div></div>
                )}
              </>
            );
          }}
        </TypedMainMenuQuery>
        <HashLink to="/#contacto" className="link">
          <FormattedMessage id="header_contact" defaultMessage="Contacto" />
        </HashLink>
      </div>
    </>
  );
};

export default MainMenu;
