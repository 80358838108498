import { styled } from "@styles";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  div {
    margin-bottom: 5px;
  }
`;
export const Select = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  div {
    margin-bottom: 5px;
  }
  width:50%
`;
export const SelectIndicator = styled.div`
  margin: 0 1rem 0 0;
  cursor: pointer;
`;
