import * as React from "react";
import { useIntl } from "react-intl";

import { RichTextContent } from "@components/atoms";
import { Breadcrumb, Breadcrumbs } from "../../components";

interface PageNavigationElement {
  active: boolean;
  label: string;
  url: string;
}

interface PageProps {
  breadcrumbs: Breadcrumb[];
  headerImage: string | null;
  navigation: PageNavigationElement[];
  page: {
    contentJson: any;
    title: string;
    translation: any;
  };
}
export const Page: React.FC<PageProps> = ({
  breadcrumbs,
  headerImage,
  navigation,
  page,
}) =>{
  const intl = useIntl();

  return (
  <div className="article-page">   
    <div className="article-page__container-breadcumbs">
      <Breadcrumbs breadcrumbs={breadcrumbs} />
    </div>  
    <div className="article-page__container">     
      <div className="article-page__navigation">
          <h1 className="article-page__navigation__title">{intl.locale=== 'es'? page.title : page.translation?.title || page.title }</h1>
      </div>  
      <div className="article-page__content">
        <RichTextContent
          descriptionJson={intl.locale=== 'es'?page.contentJson : page.translation?.contentJson || page.contentJson }
        />
      </div>
    </div>
  </div>
)};
export default Page;
