import formData from "form-data";
import { Formik } from "formik";
import Mailgun from "mailgun.js";
import React, { Fragment, useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Modal, TextField } from "..";
import { Locale } from "../Locale/WrapperIntl";
import { IModalProps } from "../Modal";
import Modalmessage from "../Modalmessage";
import icon from '../../images/cotization_icon.svg'
import sendImg from "../../images/send_icon.svg";

const mailgun = new Mailgun(formData);
const mg = mailgun.client({
  key: process.env.MAILGUN_API_KEY,
  username: "api"
});

interface ProductData {
  name: string;
  quantity: number;
}

interface FormValues {
  city: string;
  email: string;
  message: string;
  name: string;
  phone: string;
}

interface IModalQuoteProps {
  modalProps: IModalProps;
  productData: ProductData;
}

export default function ModalQuote({
  modalProps,
  productData,
}: IModalQuoteProps) {
  const { locale, formatMessage } = useIntl();

  const [isEmailSend, setIsEmailSend] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);

  const CloseModal = () => {
    setmodalOpen(false);
  }

  const getMessage = (product: ProductData) => {
    if (locale === Locale.EN) {
      return `Product: ${product.name} \ Quantity: ${product.quantity}\n`;
    } else {
      return `Producto: ${product.name} \nCantidad de paquetes: ${product.quantity}`;
    }
  };

  const handleSubmit = async (values: FormValues) => {
    const messageParams = {
      from: `AEMA contact message <${process.env.MAILGUN_DOMAIN}>`,
      html: `<div>
        <p><strong>Hello, I need a quoting for a product</strong></p>
        <p><strong>Product:</strong> ${productData.name}</p>
        <p><strong>Product Quantity:</strong> ${productData.quantity}</p>
        <p><strong>Email:</strong> ${values.email}</p>
        <p><strong>Name:</strong> ${values.name}</p>
        <p><strong>Phone:</strong> ${values.phone}</p>
        <p><strong>City:</strong> ${values.city}</p>
      </div>`,
      subject: "Quoting message",
      to: ["contacto@grupoaema.com"],
    };

    mg.messages.create('mail.grupoaema.com', messageParams);
  };

  const [message, setMessage] = useState(getMessage(productData));

  useEffect(() => {
    setMessage(getMessage(productData));
  }, [productData]);

  if (isEmailSend) {
    return (

      <Modalmessage show={modalOpen}
        title={[
          <Fragment>
            <p>
              <FormattedMessage
                id="quote-request"
                defaultMessage="Solicitud de Cotización" />
            </p>
            <img width='40px' className="modalMessage__icon" height='50px' src={icon}/>
          </Fragment>,
        ]}
        hide={CloseModal}
        children={
          <Fragment>
            <FormattedMessage
              id="quote-received-message"
              defaultMessage="Recibimos su solicitud de cotización del siguiente producto:" />
            <br></br>
            <br></br>
            {productData.name}
            <br></br>
            <FormattedMessage
              id="quantity"
              defaultMessage="Cantidad" />: {productData.quantity}
            <br></br>

            <br></br>
            <FormattedMessage
              id="comunicate-with-you"
              defaultMessage="Nos pondremos en contacto con usted a la brevedad." />
            <br></br>
            <br></br>
            <FormattedMessage
              id="hour-attention"
              defaultMessage=" Nuestros horarios de atención son:" />
            <br></br>
            <FormattedMessage
              id="monday-tuesday"
              defaultMessage=" Lunes a Viernes: 9 am a 6 pm" />
            <img className="modalMessage__image" width='200px' height='190px' src={sendImg}></img>
          </Fragment>
        }></Modalmessage>

    );
  }

  return (
    <Modal {...modalProps}>
      <Formik
        initialValues={{
          city: "",
          email: "",
          message,
          name: "",
          phone: "",
        }}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await handleSubmit(values);
          setTimeout(() => {
            setSubmitting(false);
            resetForm();
            setIsEmailSend(true);
            setmodalOpen(true);
          }, 500);
        }}
        validate={(values) => {
          const errors: {
            name?: string;
            phone?: string;
            email?: string;
            city?: string;
          } = {};
          const emailRegex = RegExp(
            /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/
          );
          if (!values.email) {
            errors.email = formatMessage({
              defaultMessage: "Campo Requerido",
              id: "field_required",
            });
          }
          if (!emailRegex.test(values.email)) {
            errors.email = formatMessage({
              defaultMessage: "Correo electrónico inválido",
              id: "wrong_email",
            });
          }
          if (!values.phone) {
            errors.phone = formatMessage({
              defaultMessage: "Campo Requerido",
              id: "field_required"
            })
          }
          if (!Boolean(values.phone.length === 10 || values.phone.length === 12 || values.phone.length === 13)) {
            errors.phone = formatMessage({
              defaultMessage: "Formato de número inválido",
              id: "wrong_phone"
            })
          }
          return errors;
        }}
      >
        {({
          handleChange,
          handleSubmit,
          handleBlur,
          values,
          errors,
          touched,
          isSubmitting,
          isValid,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <div className="modal__quote__form">
                <div className="modal__quote__form--col modal__quote__form--pr">
                  <div className="modal__quote__input">
                    <label htmlFor="name">
                      <FormattedMessage id="name" defaultMessage="Nombre" />
                    </label>
                    <TextField
                      type="text"
                      name="name"
                      id="name"
                      value={values.name}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={
                        touched.name && errors.name
                          ? [{ message: errors.name }]
                          : undefined
                      }
                    />
                  </div>
                  <div className="modal__quote__input">
                    <label htmlFor="email">
                      <FormattedMessage
                        id="email"
                        defaultMessage="Correo electrónico"
                      />
                      *
                    </label>
                    <TextField
                      type="email"
                      name="email"
                      id="email"
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={
                        touched.email && errors.email
                          ? [{ message: errors.email }]
                          : undefined
                      }
                    />
                  </div>
                  <div className="modal__quote__input">
                    <label htmlFor="message">
                      <FormattedMessage id="message" defaultMessage="Mensaje" />
                    </label>
                    <textarea
                      name="message"
                      id="message"
                      defaultValue={values.message}
                    ></textarea>
                  </div>
                </div>
                <div className="modal__quote__form--col">
                  <div className="modal__quote__input">
                    <label htmlFor="phone">
                      <FormattedMessage id="phone" defaultMessage="Teléfono" />*
                    </label>
                    <TextField
                      type="text"
                      name="phone"
                      id="phone"
                      value={values.phone}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={
                        touched.phone && errors.phone
                          ? [{ message: errors.phone }]
                          : undefined
                      }
                    />
                  </div>
                  <div className="modal__quote__input">
                    <label htmlFor="city">
                      <FormattedMessage id="city" defaultMessage="Ciudad" />
                    </label>
                    <TextField
                      type="text"
                      name="city"
                      id="city"
                      value={values.city}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      errors={
                        touched.city && errors.city
                          ? [{ message: errors.city }]
                          : undefined
                      }
                    />
                  </div>
                  <div className="modal__quote__button">
                    <button type="submit" disabled={isSubmitting || !isValid}>
                      <FormattedMessage id="send" defaultMessage="Enviar" />
                    </button>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
}
