import React from "react";
import { FormattedMessage } from "react-intl";
// import {  FormattedMessage } from "react-intl";

import * as S from "./styles";
import { IProps } from "./types";

/**
 * Billing Data summary
 */
const BillingDataSummary: React.FC<IProps> = ({ data }: IProps) => {
  if (data) {
    return (
      <S.Wrapper>
        <strong>{data.businessName}</strong>
        <br />
        {data.taxRegime}
        <br />
        {data.RFC}
        <br />
        {data.CFDI}
        <br />
        {data.phone}
        <br />
        {data.payment_method}
        <br />
        <br />
      </S.Wrapper>
    );
  } else {
    return (
      <FormattedMessage
        id="dont_require_billing"
        defaultMessage="No requiere facturación"
      />
    );
  }
  return null;
};

export { BillingDataSummary };
