import React, {
  forwardRef,
  RefForwardingComponent,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { RouteComponentProps, useHistory } from "react-router";

import { CheckoutPayment } from "@components/organisms";
import { useCart, useCheckout, useUserDetails } from "@sdk/react";
import { ShopContext } from "@temp/components/ShopProvider/context";
import { CHECKOUT_STEPS } from "@temp/core/config";
import { IAddress, ICardData, IFormError, IBillingData } from "@types";
import { filterNotEmptyArrayItems } from "@utils/misc";

export interface ICheckoutPaymentSubpageHandles {
  submitPayment: () => void;
}
interface IProps extends RouteComponentProps<any> {
  selectedPaymentGateway?: string;
  selectedPaymentGatewayToken?: string;
  selectPaymentGateway: (paymentGateway: string) => void;
  changeSubmitProgress: (submitInProgress: boolean) => void;
}

const CheckoutPaymentSubpageWithRef: RefForwardingComponent<
  ICheckoutPaymentSubpageHandles,
  IProps
> = (
  {
    selectedPaymentGateway,
    selectedPaymentGatewayToken,
    changeSubmitProgress,
    selectPaymentGateway,
    ...props
  }: IProps,
  ref
) => {
  const history = useHistory();
  const { data: user } = useUserDetails();
  const {
    checkout,
    billingAsShipping,
    requireBillingData,
    setBillingAddress,
    setBillingAsShippingAddress,
    setBillingData,
    selectedBillingAddressId,
    availablePaymentGateways,
    promoCodeDiscount,
    addPromoCode,
    removePromoCode,
    createPayment,
    createPaymentWithBillingData,
  } = useCheckout();
  const { items } = useCart();
  const { countries } = useContext(ShopContext);
  const intl = useIntl();

  const isShippingRequiredForProducts =
    items &&
    items.some(
      ({ variant }) => variant.product?.productType.isShippingRequired
    );

  const [billingErrors, setBillingErrors] = useState<IFormError[]>([]);
  const [billingDataErrors, setBillingDataErrors] = useState<any>([]);
  const [gatewayErrors, setGatewayErrors] = useState<IFormError[]>([]);
  const [promoCodeErrors, setPromoCodeErrors] = useState<IFormError[]>([]);

  const [billingAsShippingState, setBillingAsShippingState] = useState(
    billingAsShipping
  );
  const [billingDataRequireState, setBillingDataRequireState] = useState(
    requireBillingData
  );
  useEffect(() => {
    setBillingAsShippingState(billingAsShipping);
    setBillingDataRequireState(requireBillingData);
  }, [billingAsShipping, requireBillingData]);

  const checkoutBillingAddress = checkout?.billingAddress
    ? {
        ...checkout?.billingAddress,
        phone: checkout?.billingAddress?.phone || undefined,
      }
    : undefined;
  const paymentGateways = availablePaymentGateways
    ? availablePaymentGateways
    : [];

  const checkoutBillingFormId = "billing-form";
  const checkoutBillingFormRef = useRef<HTMLFormElement>(null);
  const checkoutGatewayFormId = "gateway-form";
  const checkoutGatewayFormRef = useRef<HTMLFormElement>(null);
  const checkoutNewAddressFormId = "new-address-form";
  const promoCodeDiscountFormId = "discount-form";
  const promoCodeDiscountFormRef = useRef<HTMLFormElement>(null);
  const BillingDataFormId = "billingData-form";
  const BillingDataFormRef = useRef<HTMLFormElement>(null);
  const [requireBillData, setRequireBillData] = useState(
    false
  );
  useImperativeHandle(ref, () => ({
    submitPayment: () => {
      if (billingAsShippingState) {
        handleSetBillingAddress();
      } else if (user && selectedBillingAddressId) {
        checkoutBillingFormRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      } else {
        // TODO validate form
        checkoutBillingFormRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }
    },
  }));

  const handleProcessPayment = async (
    gateway: string,
    token: string,
    cardData?: ICardData
  ) => {
    if (requireBillData) {
      const { dataError } = await createPaymentWithBillingData(
        gateway,
        token,
        cardData
      );
      const errors = dataError?.error;
      changeSubmitProgress(false);
      if (errors) {
        setGatewayErrors(errors);
      } else {
        setGatewayErrors([]);
        history.push(CHECKOUT_STEPS[2].nextStepLink);
      }
    } else {
      const { dataError } = await createPayment(gateway, token, cardData);
      const errors = dataError?.error;
      changeSubmitProgress(false);
      if (errors) {
        setGatewayErrors(errors);
      } else {
        setGatewayErrors([]);
        history.push(CHECKOUT_STEPS[2].nextStepLink);
      }
    }
  };
  const handlePaymentGatewayError = () => {
    changeSubmitProgress(false);
  };
  const handleSetBillingAddress = async (
    address?: IAddress,
    email?: string,
    userAddressId?: string
  ) => {
    if (!address && !billingAsShippingState) {
      setBillingErrors([
        {
          message: intl.formatMessage({
            defaultMessage: "Por favor ingresa una dirección de facturación",
            id: "checkoutpayment_billing_address",
          }),
        },
      ]);
      return;
    }

    const billingEmail = user?.email || email;

    if (
      !billingEmail &&
      !billingAsShippingState &&
      !isShippingRequiredForProducts
    ) {
      setBillingErrors([
        {
          field: "email",
          message: intl.formatMessage({
            defaultMessage:
              "Por favor ingresa una dirección de correo electrónico",
              id: "checkoutpayment_billing_address",
          }),
        },
      ]);
      return;
    }

    let errors;
    changeSubmitProgress(true);
    if (billingAsShippingState && isShippingRequiredForProducts) {
      const { dataError } = await setBillingAsShippingAddress();
      errors = dataError?.error;
    } else {
      const { dataError } = await setBillingAddress(
        {
          ...address,
          id: userAddressId,
        },
        billingEmail
      );
      errors = dataError?.error;
    }
    if (errors) {
      changeSubmitProgress(false);
      setBillingErrors(errors);
    } else {
      setBillingErrors([]);
      if (!billingDataRequireState) {
        setRequireBillData(true)
        BillingDataFormRef.current?.dispatchEvent(
          new Event("submit", { cancelable: true })
        );
      }else{
        if (checkoutGatewayFormRef.current) {
          checkoutGatewayFormRef.current.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
          } else {
          changeSubmitProgress(false);
          setGatewayErrors([
            {
              message: intl.formatMessage({
                defaultMessage: "Por favor elige un método de pago",
                id: "checkoutpayment_payment_method",
              }),
            },
          ]);
          }
      }
      // if (promoCodeDiscountFormRef.current) {
      //   promoCodeDiscountFormRef.current?.dispatchEvent(
      //     new Event("submit", { cancelable: true })
      //   );
      // } else
  
    }
  };
  const handleSetBillingData = async (data?: IBillingData) => {
    if (
      data?.businessName &&
      data?.CFDI &&
      data?.RFC &&
      data?.taxRegime &&
      data?.payment_method &&
      data?.phone
    ) {
      const { dataError } = await setBillingData({
        ...data,
      });
      let errors;
      changeSubmitProgress(true);
      errors = dataError?.error;
      if (errors) {
        changeSubmitProgress(false);
        setBillingDataErrors(errors);
      } else {
        setBillingDataErrors([]);
        if (checkoutGatewayFormRef.current) {
          checkoutGatewayFormRef.current.dispatchEvent(
            new Event("submit", { cancelable: true })
          );
          } else {
          changeSubmitProgress(false);
          setGatewayErrors([
            {
              message: intl.formatMessage({
                defaultMessage: "Por favor elige un método de pago",
                id: "checkoutpayment_payment_method",
              }),
            },
          ]);
          }
      }
    } else {
      setBillingDataErrors(
        <FormattedMessage
          id="billing_data_error"
          defaultMessage="Porfavor ingresa los datos de facturación"
        />
      );
      changeSubmitProgress(false);
    }
  };
  const handleAddPromoCode = async (promoCode: string) => {
    const { dataError } = await addPromoCode(promoCode);
    const errors = dataError?.error;
    if (errors) {
      changeSubmitProgress(false);
      setPromoCodeErrors(errors);
    } else {
      setPromoCodeErrors([]);
    //  if (checkoutGatewayFormRef.current) {
    //  checkoutGatewayFormRef.current.dispatchEvent(
    //    new Event("submit", { cancelable: true })
    //  );
    //  } else {
    //  changeSubmitProgress(false);
    //  setGatewayErrors([
    //    {
    //      message: intl.formatMessage({
    //        defaultMessage: "Por favor elige un método de pago",
    //        id: "checkoutpayment_payment_method",
    //      }),
    //    },
    //  ]);
    //  }
    }
  };
  const handleRemovePromoCode = async (promoCode: string) => {
    const { dataError } = await removePromoCode(promoCode);
    const errors = dataError?.error;
    if (errors) {
      changeSubmitProgress(false);
      setPromoCodeErrors(errors);
    } else {
      setPromoCodeErrors([]);
    //  if (checkoutGatewayFormRef.current) {
    //  checkoutGatewayFormRef.current.dispatchEvent(
    //    new Event("submit", { cancelable: true })
    //  );
    //  } else {
    //  changeSubmitProgress(false);
    //  setGatewayErrors([
    //    {
    //      message: intl.formatMessage({
    //        defaultMessage: "Por favor elige un método de pago.",
    //        id: "checkoutpayment_payment_method",
    //      }),
    //    },
    //  ]);
    //  }
    }
  };
  const handleSubmitUnchangedDiscount = () => {
    if (checkoutGatewayFormRef.current) {
      checkoutGatewayFormRef.current.dispatchEvent(
        new Event("submit", { cancelable: true })
      );
    } else {
      changeSubmitProgress(false);
      setGatewayErrors([
        {
          message: intl.formatMessage({
            defaultMessage: "Por favor elige un método de pago.",
            id: "checkoutpayment_payment_method",
          }),
        },
      ]);
    }
  };

  return (
    <CheckoutPayment
      {...props}
      billingErrors={billingErrors}
      billingDataErrors={billingDataErrors}
      gatewayErrors={gatewayErrors}
      billingFormId={checkoutBillingFormId}
      billingFormRef={checkoutBillingFormRef}
      billingDataFormId={BillingDataFormId}
      billingDataFormRef={BillingDataFormRef}
      userAddresses={user?.addresses
        ?.filter(filterNotEmptyArrayItems)
        .map(
          ({
            isDefaultBillingAddress,
            isDefaultShippingAddress,
            phone,
            ...address
          }) => ({
            ...address,
            isDefaultBillingAddress: !!isDefaultBillingAddress,
            isDefaultShippingAddress: !!isDefaultShippingAddress,
            phone: phone ? phone : undefined,
          })
        )}
      selectedUserAddressId={selectedBillingAddressId}
      checkoutBillingAddress={checkoutBillingAddress}
      countries={countries}
      paymentGateways={paymentGateways}
      selectedPaymentGateway={selectedPaymentGateway}
      selectedPaymentGatewayToken={selectedPaymentGatewayToken}
      selectPaymentGateway={selectPaymentGateway}
      setBillingAddress={handleSetBillingAddress}
      setBillingData={handleSetBillingData}
      billingAsShippingPossible={!!isShippingRequiredForProducts}
      billingAsShippingAddress={billingAsShippingState}
      setBillingAsShippingAddress={setBillingAsShippingState}
      setBillingDataRequire={setBillingDataRequireState}
      requireBillingData={billingDataRequireState}
      promoCodeDiscountFormId={promoCodeDiscountFormId}
      promoCodeDiscountFormRef={promoCodeDiscountFormRef}
      promoCodeDiscount={{
        voucherCode: promoCodeDiscount?.voucherCode,
      }}
      addPromoCode={handleAddPromoCode}
      removeVoucherCode={handleRemovePromoCode}
      submitUnchangedDiscount={handleSubmitUnchangedDiscount}
      promoCodeErrors={promoCodeErrors}
      gatewayFormId={checkoutGatewayFormId}
      gatewayFormRef={checkoutGatewayFormRef}
      userId={user?.id}
      newAddressFormId={checkoutNewAddressFormId}
      processPayment={handleProcessPayment}
      onGatewayError={handlePaymentGatewayError}
    />
  );
};

const CheckoutPaymentSubpage = forwardRef(CheckoutPaymentSubpageWithRef);

export { CheckoutPaymentSubpage };
