import {generateProductUrl} from "@temp/core/utils";
import React, {useContext}  from "react";

import { TaxedMoney } from "@components/containers";
import { Thumbnail } from "@components/molecules";
import {Link} from "react-router-dom";

import { Locale, LocaleContext } from "../../../../components/Locale/WrapperIntl";
import * as S from "./styles";
import { IProps } from "./types";


export const ProductTile: React.FC<IProps> = ({ product }: IProps) => {
  const contextIntl = useContext(LocaleContext);
  const price =
    product.pricing &&
    product.pricing.priceRange &&
    product.pricing.priceRange.start
      ? product.pricing.priceRange.start
      : undefined;

  return (
    <S.Wrapper data-cy="product-tile" data-aos="fade-up">
       <Link  to={generateProductUrl(product.id, product.name)}>
      <S.Image>
        <Thumbnail source={product} />
      </S.Image>
      </Link>
      <S.TitleWrapper>
        <S.Title>{contextIntl.locale === Locale.EN && product.translation ? product.translation.name: product.name}</S.Title>
      </S.TitleWrapper>
      <S.Price>
        <TaxedMoney taxedMoney={price} />
      </S.Price>
      <S.Description>
        {product.description}
      </S.Description>
      <Link  to={generateProductUrl(product.id, product.name)}>
        <S.ButtonShop>
          Comprar
        </S.ButtonShop>
      </Link>
    </S.Wrapper>
  );
};
