import { styled } from "@styles";

export const Wrapper = styled.div`
  display: grid;
  min-height: 60px;
  max-height: min-content;
  width: 100%;
  grid-template-areas: "products description price quantity totalPrice";
  grid-template-columns: 1fr 1.5fr 1fr 1fr 1fr;
  align-items: center;
  font-family: "DIN Pro";
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
`;

export const Column = styled.div`
  padding: 0.5rem;  
  text-align: center;
`;
