import React from "react";

import {  FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, OverlayTheme, OverlayType } from "..";
import { OverlayContextInterface } from "../Overlay";

const CheckoutAsGuest: React.FC<{
  overlay: OverlayContextInterface;
  checkoutUrl: string;
}> = ({ overlay, checkoutUrl }) => (
  <div className="checkout-login__guest">
    <h3 className="checkout__header">
      <FormattedMessage
          id="continue_guest"
          defaultMessage="Continuar como invitado"      
        />
    </h3>
    <p>
      <FormattedMessage
          id="continue_guest_message"
          defaultMessage="Si no deseas registrar una cuenta, no te preocupes. Puedes continuar como invitado. Nos preocuparemos por tu compra tanto como con cualquier usuario registrado"
        />      
    </p>
    <Link to={checkoutUrl}>
      <Button>        
        <FormattedMessage
          id="continue_guest"
          defaultMessage="Continuar como invitado"      
        />
      </Button>
    </Link>

    <p>
      <FormattedMessage
          id="or_u_can"
          defaultMessage="o puedes "
        />
      <span
        className="u-link"
        onClick={() => overlay.show(OverlayType.register, OverlayTheme.right)}
      >
      <FormattedMessage
        id="create_account"
        defaultMessage="crear una cuenta"
      />
      </span>
    </p>
  </div>
);

export default CheckoutAsGuest;
