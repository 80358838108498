import "./scss/index.scss";

import * as React from "react";
import { useSignIn } from "@sdk/react";
import { maybe } from "@utils/misc";
import { useIntl } from "react-intl";

import { Button, Form, TextField } from "..";

interface ILoginForm {
  hide?: () => void;
}

const LoginForm: React.FC<ILoginForm> = ({ hide }) => {
  const intl = useIntl();
  const [signIn, { loading, error }] = useSignIn();
  const [emailS, setEmailS] = React.useState("");
  const defEmail = localStorage.getItem("email");

  const handleOnSubmit = async (evt, { email, password }) => {
    evt.preventDefault();
    email = emailS
    const authenticated = await signIn({ email, password });
    if (authenticated && hide) {
      hide();
    }
  };

  React.useEffect(() => {
    if (defEmail !== undefined) {
      setEmailS(defEmail);
    }
  }, [emailS]);

  const changeEmail = (e) => {
    setEmailS(e.target.value);
    localStorage.setItem("email", e.target.value);
  };

  return (
    <div className="login-form">
      <Form
        errors={maybe(() => error.extraInfo.userInputErrors, [])}
        onSubmit={handleOnSubmit}
      >
        <TextField
          name="email"
          autoComplete="email"
          label={intl.formatMessage({
            defaultMessage: "Correo Electrónico",
            id: "email",
          })}
          type="email"
          onChange={(e) => {
            changeEmail(e);
          }}
          required
          value={emailS}
        ></TextField>
        <TextField
          name="password"
          autoComplete="password"
          label={intl.formatMessage({
            defaultMessage: "Contraseña",
            id: "password",
          })}
          type="password"
          required
        />
        <div className="login-form__button">
          <Button type="submit" {...(loading && { disabled: true })}>
            {loading
              ? intl.formatMessage({
                  defaultMessage: "Cargando",
                  id: "loading",
                })
              : intl.formatMessage({
                  defaultMessage: "Iniciar Sesión",
                  id: "login",
                })}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default LoginForm;
