import * as React from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "../..";

const Empty: React.FC<{ overlayHide(): void }> = ({ overlayHide }) => (
  <div className="cart__empty">
    <h4>      
      <FormattedMessage
        id="cart_empty"
        defaultMessage="Tu Carrito está Vacío"/>
    </h4>
    <p>
      <FormattedMessage
        id="cart_no_products"
        defaultMessage="Aún no has agregado nada a tu carrito. Estamos seguros que pronto encontrarás algo en nuestra tienda."/>
    </p>
    <div className="cart__empty__action">
      <Button secondary onClick={overlayHide}>        
        <FormattedMessage
          id="cart_continue_buying"
          defaultMessage="Continuar Comprando"/>
      </Button>
    </div>
  </div>
);

export default Empty;
