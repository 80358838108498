import * as React from "react";
import { FormattedMessage } from "react-intl";

import { ProductList } from "@components/organisms";

import { ProductDetails_product_category_products_edges } from "./gqlTypes/ProductDetails";

const OtherProducts: React.FC<{
  products: ProductDetails_product_category_products_edges[];
}> = ({ products }) => (
  <div>
    <div >
      <h4 className="product-page__other-products__title">
        <FormattedMessage
          id="recommended_products"
          defaultMessage="Productos recomendados."/>
      </h4>
      <ProductList
        // @ts-ignore
        products={products.map(({ node }) => node)} />
    </div>
  </div>
);

export default OtherProducts;
