import English from '@locale/en.json';
import Spanish from '@locale/es.json';
import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';


export enum Locale {
    EN = "en",
    ES = "es"
}
interface StructuredMessage {
    context?: string;
    string: string;
  }
type LocaleMessages = Record<string, StructuredMessage>;
const localeData: Record<Locale, LocaleMessages> = {
    [Locale.EN]: English,
    [Locale.ES]: Spanish,
}
const defaultLocale = Locale.ES;
export interface LocaleContextType {
    locale: Locale;
    setLocale: (locale: Locale) => void;
  }
export const LocaleContext = React.createContext<LocaleContextType>({
    locale: defaultLocale,
    setLocale: () => undefined
  });

function getKeyValueJson(messages: LocaleMessages): Record<string, string> {
    const dotSeparator = "_dot_";
    const sepRegExp = new RegExp(dotSeparator, "g");
    if (messages) {
      const keyValueMessages: Record<string, string> = {};
      return Object.entries(messages).reduce((acc, [id, msg]) => {
        acc[id.replace(sepRegExp, ".")] = msg.string;
        return acc;
      }, keyValueMessages);
    }
  }


const WrapperIntl = (props) => {
   const [locale, setLocale] = useState(defaultLocale);

   return (
       <LocaleContext.Provider value = {{locale, setLocale}}>
           <IntlProvider 
            defaultLocale={defaultLocale}
            messages={getKeyValueJson(localeData[locale])}
            locale={locale}>
               {props.children}
           </IntlProvider>
       </LocaleContext.Provider>
   );
}
export default WrapperIntl;