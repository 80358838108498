import React from "react";
import {  FormattedMessage } from "react-intl";

const ForgottenPassword: React.FC<{
  onClick: () => void;
}> = ({ onClick }) => (
  <>
    <div className="login__content__password-reminder">
      <p>
        <FormattedMessage
          id="password_forgotten"
          defaultMessage="¿Olvidaste tu contraseña?"/>
        <span className="u-link" onClick={onClick}>
          <FormattedMessage
            id="click_here"
            defaultMessage="Haz click aquí"/>
        </span>
      </p>
    </div>
  </>
);

export default ForgottenPassword;
