import { smallScreen } from "../../globalStyles/scss/variables.scss";
import "./scss/index.scss";

import * as React from "react";
import {  FormattedMessage, useIntl } from "react-intl";
import Media from "react-media";

import CostRow from "./CostRow";
import ProductRow, { EditableProductRowProps, ILine } from "./ProductRow";

interface TableProps extends EditableProductRowProps {
  lines: ILine[];
  subtotal: React.ReactNode;
  deliveryCost?: React.ReactNode;
  totalCost?: React.ReactNode;
  discount?: React.ReactNode;
  discountName?: string;
}

const Table: React.FC<TableProps> = ({
  subtotal,
  deliveryCost,
  totalCost,
  discount,
  discountName,
  lines,
  ...rowProps
}) => {
  const intl = useIntl();
  return (
  <Media query={{ minWidth: smallScreen }}>
    {mediumScreen => (
      <table className="cart-table">
        <thead>
          <tr>
            <th>
            <FormattedMessage
              id="products"
              defaultMessage="Productos"/>
            </th>
            {mediumScreen && <th>
              <FormattedMessage
                id="price"
                defaultMessage="Precio"/>
            </th>}
            <th>
            <FormattedMessage
              id="variant"
              defaultMessage="Variante"/>
            </th>
            <th className="cart-table__quantity-header">
              <FormattedMessage
                id="quantity"
                defaultMessage="Cantidad"/>
            </th>
            <th colSpan={2}>{mediumScreen ? 
              intl.formatMessage({id:"total_price",defaultMessage:"Precio total"}) : 
              intl.formatMessage({id:"price",defaultMessage:"Precio"})}</th>
          </tr>
        </thead>
        <tbody>
          {lines.map(line => (
            <ProductRow
              key={line.id}
              line={line}
              mediumScreen={mediumScreen}
              {...rowProps}
            />
          ))}
        </tbody>
        <tfoot>
          <CostRow
            mediumScreen={mediumScreen}
            heading="Subtotal"
            cost={subtotal}
          />
          {discount && (
            <CostRow
              mediumScreen={mediumScreen}
              heading={`${intl.formatMessage({id:"discount",defaultMessage:"Descuento"})}: ${discountName}`}
              cost={discount}
            />
          )}
          {deliveryCost && (
            <CostRow
              mediumScreen={mediumScreen}
              heading={intl.formatMessage({id:"delivery_cost",defaultMessage:"Costo de envío"})}
              cost={deliveryCost}
            />
          )}
          {totalCost && (
            <CostRow
              mediumScreen={mediumScreen}
              heading={intl.formatMessage({id:"total_cost",defaultMessage:"Costo total"})}
              cost={totalCost}
            />
          )}
        </tfoot>
      </table>
    )}
  </Media>
  );
};

export default Table;
