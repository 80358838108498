import React from "react";
import "./scss/index.scss";

import { useIntl } from "react-intl";

const PrivacyPolices: React.FC = () => {
  const intl = useIntl();

  return (
    <>
      {intl.locale === "en" ? (
        <div className="wrapper privacy-policies-container">
          <p className="center">
            <strong>NOTICE OF PRIVACY.</strong>
          </p>
          <p>
            <strong>GRUPO AEMA DE M&Eacute;XICO, S.A. DE C.V. </strong>
            (hereinafter <strong>&ldquo;GRUPO AEMA&rdquo;</strong> ) aims
            toMarketing and production of Security Seals, Security Labels,
            Security Bags,Fuel Protection among others. It has an address
            located at Gerardo Torres Diaz 3505,Monterrey, Nuevo León and that
            based on and in compliance with the provisions of Federal
            LawProtection of Personal Data Held by Individuals; informs that he
            puts hisprovision of this Privacy Notice by virtue of which it is
            responsible for collecting your datapersonal information, in the
            same way informs the use that is given to them and their protection.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>PURPOSES OF THE USE OF PERSONAL DATA.</strong>
            </li>
          </ol>
          <p>
            The personal data that <strong>&ldquo;GRUPO AEMA&rdquo;</strong>{" "}
            collects in its offices, if requested viatelephone, electronic
            messages; or through the website and contract our productsor online
            services; or through other sources permitted by law, they may be,
            among others:
          </p>
          <ol className="list">
            <li>
              1. Name, telephone numbers, email address, address, date of
              birth,place of birth, personal interests, shipping address.
            </li>
            <li>
              2. Federal Taxpayers Registry (RFC) and Unique Population Registry
              Code(CURP).
            </li>
            <li>
              3. Bank details: bank account number, interbank code, number
              ofcard.
            </li>
          </ol>
          <p>
            The Personal Data of the Holder will be collected solely and
            exclusively by <strong>&ldquo;GRUPO AEMA&rdquo;</strong> withthe
            purpose of:
          </p>
          <ol className="list">
            <li>
              1. Carry out the activities that derive from the relationship that
              binds <strong>&ldquo;GRUPO AEMA&rdquo;</strong> with the Owner,
              regarding the services or products provided to the Owner;
            </li>
            <li>2. Preparation of service proposals;</li>
            <li>3. Preparation of purchase orders;</li>
            <li>
              4. Preparation of invoices, referrals or any other information
              aimed atcomply with the services or products provided to the
              Holder;
            </li>
            <li>
              5. To share with the Owner information about the services of
              <strong>&ldquo;GRUPO AEMA&rdquo;</strong>, atthrough social
              networks (Instagram, Facebook, Twitter, LinkedIn, etc.) and / or
              pageWeb;
            </li>
            <li>
              6. For any other activity aimed at promoting, maintaining,
              improving and evaluatingthe services of{" "}
              <strong>&ldquo;GRUPO AEMA&rdquo;</strong>.
            </li>
          </ol>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> undertakes that all
            Personal Data that the Holder provides, orthat{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> obtains from it, will be
            treated under the strictest security measuresthat guarantee their
            confidentiality and good use for the purposes described in this
            Notice ofPrivacy.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>
                MEASURES TO LIMIT THE USE OR DISCLOSURE OF PERSONAL DATA.
              </strong>
            </li>
          </ol>
          <p>
            In order to limit the use or disclosure of personal data,{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> safeguardssaid personal
            data implementing security, administrative, technical and physical
            measures.Personal data is managed and protected through the use of
            databases, administered in computer systems, of which the only and
            exclusively access to thepersons designated for this purpose,
            without allowing their use, consultation, management or access
            tounauthorized persons. <strong>&ldquo;GRUPO AEMA&rdquo;</strong>{" "}
            has implemented applicable internal policies and processesto its
            staff, through which the personal data is used by a minimum of
            people,limiting the use of the means of reproduction and generating
            the obligation to destroy allthose copies or reproductions of
            documents that contain personal data other thanstrictly essential
            for the proper performance of the functions of the personnel of{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> as well as the prohibition
            of extracting from the offices of{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> any type ofinformation
            containing personal data.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>TRANSFERS OF PERSONAL DATA.</strong>
            </li>
          </ol>
          <p>
            By accepting and authorizing the processing of your personal data in
            the terms indicatedin the Federal Law on Protection of Personal Data
            Held by Private Parties, expressly empowers{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> to transfer personal
            information for the purposes of fulfilling the purposes
            andcommercial purposes between the Holder and{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> .
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>USE OF COOKIES.</strong>
            </li>
          </ol>
          <p>
            In order to provide you with a better service,{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> may use cookies. By
            cookies we mustunderstand how data files automatically downloaded
            and stored on the computer's hard drivecomputer equipment of the
            user when browsing a specific Internet page, which allow to
            exchangeinformation on the site and the user's browser. Likewise,
            the web beacon is a visible or hidden imageembedded within a website
            or email, which is used to monitor the behavior of theuser in these
            media. These cookies and / or web beacons can be disabled. To know
            howTo do so, consult the email{" "}
            <a href="mailto: contacto@grupoaema.com">contacto@grupoaema.com</a>{" "}
            .
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>
                MEANS TO EXERCISE THE RIGHTS OF ACCESS, RECTIFICATION,
                CANCELLATION OR OPPOSITION (ARCO).
              </strong>
            </li>
          </ol>
          <p>
            As the Holder of the personal data, you have the rights of access,
            rectification, cancellation andopposition provided for in Chapter II
            of the Federal Law on Protection of Personal Data in Possession
            ofIndividuals. To exercise any of these rights, the Holder must
            submit to <strong>&ldquo;GRUPO AEMA&rdquo;</strong>an application
            which must be accompanied by the following: the name of the Holder
            and address or other means so that{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> communicates its response;
            the documents that prove your identity, or where appropriate
            therepresentation of the Holder; the clear and precise description
            of the personal data with respect to which it is soughtexercise any
            of the aforementioned rights, and any other element or document that
            facilitates thelocation of personal data. In the case of requests
            for rectification of personal data, the Holdermust indicate, in
            addition to the aforementioned, the modifications to be made and
            provide thedocumentation to support your request. The requests
            referred to will be dealt with by theCustomer Service Department of{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> , and should be sent via
            email to{" "}
            <a href="mailto: contacto@grupoaema.com">contacto@grupoaema.com</a>{" "}
            or in writing to the address of
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> indicated in the first
            paragraphof this Notice. The requests will be analyzed and if they
            are appropriate, you will becommunicating through the email or
            address that for this purpose you have provided us, withinof the 20
            days following the receipt of your requests in order to make
            effective any of theaforementioned rights.
          </p>
          <p>
            In the event that we do not obtain your express opposition for your
            personal data to be transferred in theform and terms described
            above, we will understand that you have given your consent tacitly
            toit.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>MODIFICATIONS TO THE PRIVACY NOTICE.</strong>
            </li>
          </ol>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> reserves the right to
            modify this privacy notice to adapt it toLegislative or
            jurisprudential developments as well as industry practices, in which
            case, <strong>&ldquo;GRUPO AEMA&rdquo;</strong> will announce on
            this page the changes introduced with the anticipation that in its
            opinion is reasonable beforeto put it into practice.
          </p>
          <p>
            Certain services provided on or through the Site may contain
            particular conditions with provisionsspecific in terms of protection
            of Personal Data, in such case, Users must be subject to theprivacy
            policies and conditions that are specifically mentioned in the
            contract that governs saidservices
          </p>
          <p>
            Any modification to this Privacy Notice may be consulted at{" "}
            <a href="https://www.grupoaema.com/"> https://www.grupoaema.com/</a>
          </p>
          <br></br>
          <p>Last update date: January 11, 2021.</p>
        </div>
      ) : (
        <div className="wrapper privacy-policies-container">
          <p className="center">
            <strong>AVISO DE PRIVACIDAD.</strong>
          </p>
          <p>
            <strong>GRUPO AEMA DE M&Eacute;XICO, S.A. DE C.V. </strong> (en lo
            sucesivo <strong>&ldquo;GRUPO AEMA&rdquo;</strong>) tiene por objeto
            la comercialización y elaboración de Sellos de Seguridad, Etiquetas
            de Seguridad, Bolsas de Seguridad, Protección de Combustible entre
            otros. Cuenta con domicilio ubicado en Gerardo Torres Diaz 3505,
            Monterrey, Nuevo León y que con fundamento y en cumplimiento a lo
            dispuesto por la Ley Federal de Protección de Datos Personales en
            Posesión de los Particulares; informa que pone a su disposición el
            presente Aviso de Privacidad en virtud de que es responsable de
            recabar sus datos personales, de igual manera informa el uso que se
            le dé a los mismos y su protección.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>FINALIDADES DEL USO DE LOS DATOS PERSONALES.</strong>
            </li>
          </ol>
          <p>
            Los datos personales que <strong>&ldquo;GRUPO AEMA&rdquo;</strong>
            recabe en sus oficinas, en caso de solicitarlos vía telefónica,
            mensajes electrónicos; o bien a través del sitio de Internet y
            contrata nuestros productos o servicios en línea; o a través de
            otras fuentes permitidas por la Ley, pueden ser entre otros:
          </p>
          <ol className="list">
            <li>
              1. Nombre, teléfonos, dirección de correo electrónico, domicilio,
              fecha de nacimiento, lugar de nacimiento, intereses personales,
              dirección de envío.
            </li>
            <li>
              2. Registro Federal de Contribuyentes (RFC) y Clave Única de
              Registro de Población (CURP).
            </li>
            <li>
              3. Datos bancarios: número de cuenta bancaria, clabe
              interbancaria, número de tarjeta.
            </li>
          </ol>
          <p>
            Los Datos Personales del Titular serán recabados única y
            exclusivamente por <strong>&ldquo;GRUPO AEMA&rdquo;</strong> con la
            finalidad de:
          </p>
          <ol className="list">
            <li>
              1. Llevar a cabo las actividades que deriven de la relación que
              vincula a <strong>&ldquo;GRUPO AEMA&rdquo;</strong> con el
              Titular, respecto a los servicios o productos que se presten al
              Titular;
            </li>
            <li>2. Elaboración de propuestas de servicios;</li>
            <li>3. Elaboración de ordenes de compra;</li>
            <li>
              4. Preparación de facturas, remisiones o cualquier otra
              información tendiente a cumplir con los servicios o productos que
              se presten al Titular;
            </li>
            <li>
              5. Para compartir al Titular información sobre los servicios de
              <strong>&ldquo;GRUPO AEMA&rdquo;</strong>, a través de las redes
              sociales (Instagram, Facebook, Twitter, LinkedIn, etc.) y/o página
              web;
            </li>
            <li>
              6. Para cualquier otra actividad tendiente a promover, mantener,
              mejorar y evaluar los servicios de{" "}
              <strong>&ldquo;GRUPO AEMA&rdquo;</strong>.
            </li>
          </ol>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> se compromete a que todos
            los Datos Personales que el Titular le proporcione, o que{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> obtenga de este, serán
            tratados bajo las más estrictas medidas de seguridad que garanticen
            su confidencialidad y buen uso para las finalidades descritas en el
            presente Aviso de Privacidad.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>
                MEDIDAS PARA LIMITAR EL USO O DIVULGACIÓN DE DATOS PERSONALES.
              </strong>
            </li>
          </ol>
          <p>
            Con el objeto de limitar el uso o divulgación de los datos
            personales, <strong>&ldquo;GRUPO AEMA&rdquo;</strong> resguarda
            dichos datos personales implementando medidas de seguridad,
            administrativas, técnicas y físicas. Los datos personales son
            administrados y resguardados mediante el uso de bases de datos,
            administradas en sistemas de cómputo, de los cuales tienen acceso
            única y exclusivamente las personas designadas para tal efecto, sin
            que se permita su uso, consulta, manejo o acceso a personas no
            autorizadas. <strong>&ldquo;GRUPO AEMA&rdquo;</strong> ha
            implementado políticas y procesos internos aplicables a su personal,
            a través de los cuales los datos personales son utilizados por un
            mínimo de personas, limitándose el uso de los medios de reproducción
            y generándose la obligación de destruir todas aquellas copias o
            reproducciones de documentos que contengan datos personales que no
            sean estrictamente indispensable para el adecuado desempeño de las
            funciones del personal de <strong>&ldquo;GRUPO AEMA&rdquo;</strong>{" "}
            así como la prohibición de extraer de las oficinas de{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> cualquier tipo de
            información que contenga datos personales.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>TRANSFERENCIAS DE DATOS PERSONALES.</strong>
            </li>
          </ol>
          <p>
            Mediante la aceptación y autorización para el tratamiento de sus
            datos personales en los términos señalados en la Ley Federal de
            Protección de Datos Personales en Posesión de Particulares, faculta
            expresamente a<strong>&ldquo;GRUPO AEMA&rdquo;</strong> a transferir
            la información personal para los efectos de cumplir con los fines y
            propósitos comerciales entre el Titular y{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong>.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>USO DE COOKIES.</strong>
            </li>
          </ol>
          <p>
            Con el afán de brindarle un mejor servicio{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> podrá utilizar cookies.
            Por cookies debemos entender como los archivos de datos descargados
            automáticamente y almacenados en el disco duro del equipo de cómputo
            del usuario al navegar en una página de Internet específica, que
            permiten intercambiar información en el sitio y el navegador del
            usuario. Asimismo, el web beacon es una imagen visible u oculta
            insertada dentro de un sitio web o correo electrónico, que se
            utiliza para monitorear el comportamiento del usuario en estos
            medios. Estas cookies y/o web beacon pueden ser deshabilitadas. Para
            conocer cómo hacerlo, consulte al correo electrónico{" "}
            <a href="mailto: contacto@grupoaema.com">contacto@grupoaema.com</a>
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>
                MEDIOS PARA EJERCER LOS DERECHOS DE ACCESO, RECTIFICACIÓN,
                CANCELACIÓN U OPOSICIÓN (ARCO).
              </strong>
            </li>
          </ol>
          <p>
            Usted como Titular de los datos personales goza de los derechos de
            acceso, rectificación, cancelación y oposición previstos en el
            Capítulo II de la Ley Federal de Protección de Datos Personales en
            Posesión de Particulares. Para el ejercicio de cualquiera de estos
            derechos, el Titular deberá presentar a{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> una solicitud a la que
            deberá acompañar lo siguiente: el nombre del Titular y domicilio u
            otro medio para que <strong>&ldquo;GRUPO AEMA&rdquo;</strong> le
            comunique su respuesta; los documentos que acrediten su identidad, o
            en su caso la representación del Titular; la descripción clara y
            precisa de los datos personales respecto de los que se busca ejercer
            alguno de los derechos antes mencionados, y cualquier otro elemento
            o documento que facilite la localización de los datos personales. En
            el caso de solicitudes de rectificación de datos personales, el
            Titular deberá indicar, además de lo anteriormente señalado, las
            modificaciones a realizarse y aportar la documentación que sustente
            su petición. Las solicitudes a que se hace referencia serán
            atendidas por el Departamento de Servicio al Cliente de{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong>, y deberá enviarse vía
            correo electrónico a{" "}
            <a href="mailto: contacto@grupoaema.com">contacto@grupoaema.com</a>{" "}
            o por escrito al domicilio de{" "}
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> señalado en el primer
            párrafo del presente Aviso. Las solicitudes serán analizadas y en
            caso de resultar procedentes, se le estará comunicando a través del
            correo electrónico o domicilio que para tal efecto nos haya
            proporcionado, dentro de los 20 días siguientes a la recepción de
            sus solicitudes con la finalidad de hacer efectivo cualquiera de los
            derechos antes mencionados.
          </p>
          <p>
            En caso de que no obtengamos su oposición expresa para que sus datos
            personales sean transferidos en la forma y términos antes descritos,
            entenderemos que ha otorgado su consentimiento en forma tácita para
            ello.
          </p>
          <ol>
            <li>
              <strong> </strong>
              <strong>MODIFICACIONES AL AVISO DE PRIVACIDAD.</strong>
            </li>
          </ol>
          <p>
            <strong>&ldquo;GRUPO AEMA&rdquo;</strong> se reserva el derecho a
            modificar el presente aviso de privacidad para adaptarla a novedades
            legislativas o jurisprudenciales así como a prácticas de la
            industria, en cuyo caso, <strong>&ldquo;GRUPO AEMA&rdquo;</strong>{" "}
            anunciará en esta página los cambios introducidos con la
            anticipación que a su juicio resulte razonable antes de ponerla en
            práctica.
          </p>
          <p>
            Ciertos servicios prestados en o a través del Sitio pueden contener
            condiciones particulares con previsiones específicas en materia de
            protección de Datos Personales, en tal caso, los Usuario deberán
            sujetarse a las políticas de privacidad y condiciones que
            específicamente se mencionen en el contrato que rija dichos
            servicios
          </p>
          <p>
            Cualquier modificación a este Aviso de Privacidad podrá consultarlo
            en{" "}
            <a href="https://www.grupoaema.com/">https://www.grupoaema.com/</a>
          </p>
          <br></br>
          <p>Última fecha de actualización: 11/enero/2021.</p>
        </div>
      )}
    </>
  );
};

export default PrivacyPolices;
