import { media, styled } from "@styles";

export const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction:column;
`;

export const Thumbnail = styled.div<{ activeThumbnail: boolean }>`
  width: 69px;
  display: flex;
  border-width: 4px;
  border-style: solid;
  border-color: ${props =>
    props.activeThumbnail === true
      ? '#0A2057'
      : "transparent"};
  justify-content: center;
  height: 69px;
  overflow: hidden;
  img {
    width: 100%;
    object-fit: contain;
  }

  margin-top: 20px;
  margin-bottom: 20px;
`;

export const Button = styled.div`
  height: 50px;
  width: 100%;
  position: absolute;
  z-index: 1;
  background-color: rgba(50, 50, 50, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

// @ts-ignore
export const TopButton = styled(Button)`
  top: 0%;

  transform: rotate(180deg);
`;

export const BottomButton = styled(Button)`
  bottom: 0%;
`;

export const ThumbnailsContainer = styled.div`
  //position: block;
`;

export const ThumbnailList = styled.div`
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;
  ::-webkit-scrollbar {
    width: 0px;
  }

  ul {        
  ${media.smallScreen`
    position: absolute;
  `};
    display: flex;
    justify-content: space-around;
    width: 80%;
    padding: 0;
    margin: 0;
  }
`;

export const Preview = styled.div`
  width: auto;
  max-height: 560px;
  overflow: hidden;
  img {
    width: 400px;
    height: 500px;
    object-fit: contain;
  }
 
  ${media.xLargeScreen`
    img {
      width: 100%;
    }
  `};

  ${media.smallScreen`
    img {
      height: 300px;
    }
  `};
  /*  ${media.largeScreen`
    img {
      width: 250px;
      height: 350px;
    }
  `};
  ${media.mediumScreen`
    img {
      width: 200px;
      height: 300px;
    }
  `}; */
`;
