import React, { useContext, useEffect, useState } from "react";

import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import ReactSVG from "react-svg";
import { Locale, LocaleContext } from "../Locale/WrapperIntl";

import clsx from "clsx";

import expandImg from "images/expand-white.png";
import facebookIcon from "images/facebook-header.svg";
import instagramIcon from "images/instagram-header.svg";
import logoImg from "images/logo.png";
import twitterIcon from "images/twitter-header.svg";
import xImg from "images/x.svg";
import youtubeIcon from "images/youtube-header.svg";

export interface IMobileMenuProps {
  show: boolean;
  onClose: () => void;
}

const products = [
  {
    id: "security_alert",
    string: "alta seguridad",
    url: "/sellos-de-seguridad/1/",
  },
  { string: "seguridad", id: "security", url: "/seguridad/1/" },
  { string: "indicativos", id: "indicative", url: "/indicativos/18/" },
  {
    id: "security_labels",
    string: "etiquetas de seguridad",
    url: "/etiquetas-de-seguridad/2/",
  },
  {
    id: "security_bags",
    string: "bolsas de seguridad",
    url: "/bolsas-de-seguridad/3/",
  },
  {
    id: "fuel_protection",
    string: "protección de combustibles",
    url: "/proteccin-de-combustible/4/",
  },
];

export default function MobileMenu({ show, onClose }: IMobileMenuProps) {
  const contextIntl = useContext(LocaleContext);

  const [wasOpened, setWasOpened] = useState<boolean>(false);
  const [showProducts, setShowProducts] = useState<boolean>(false);

  // Disable body scroll if the mobile menu is open
  useEffect(() => {
    const body = document.getElementsByTagName("body")[0];
    body.style.overflowY = show ? "hidden" : "scroll";
  }, [show]);

  /**
   * Avoid closing animation when menu hasn't been opened
   */
  useEffect(() => {
    if (show && !wasOpened) {
      setWasOpened(true);
    }
  }, [show]);

  const getLanguageOptionClass = (language: Locale) => {
    return contextIntl.locale === language
      ? "mobile-menu__lngoption mobile-menu__lngoption--active"
      : "mobile-menu__lngoption";
  };

  return (
    <section
      className={clsx(
        "mobile-menu",
        show && "mobile-menu--open",
        !show && wasOpened && "mobile-menu--close"
      )}
    >
      <div className="mobile-menu__header">
        <img src={logoImg} className="mobile-menu__logo" />
        <ReactSVG
          className="mobile-menu__close"
          path={xImg}
          onClick={onClose}
        />
      </div>
      <div className="mobile-menu__about">
        <HashLink
          to="/#historia"
          className="mobile-menu__about--title"
          onClick={onClose}
        >
          <FormattedMessage defaultMessage="Nosotros" id="about_us" />
        </HashLink>

        <div className="mobile-menu__line" />
        <p
          className="mobile-menu__about--title mobile-menu__center"
          onClick={() => setShowProducts((prev) => !prev)}
        >
          <FormattedMessage defaultMessage="Productos" id="products" />
          <img
            src={expandImg}
            alt="Expand menu"
            className={clsx(
              "mobile-menu__expand",
              showProducts && "mobile-menu__expand--rotate"
            )}
          />
        </p>

        {showProducts && (
          <ul>
            {products.map((product) => (
              <Link
                className="mobile-menu__list--item"
                to={`/category${product.url}`}
                key={`menu-list-product-${product.string}`}
                onClick={onClose}
              >
                <li>
                  <FormattedMessage
                    defaultMessage={product.string}
                    id={product.id}
                  />
                </li>
              </Link>
            ))}
          </ul>
        )}

        <div className="mobile-menu__line" />
        <HashLink
          to="/#contacto"
          className="mobile-menu__about--title"
          onClick={onClose}
        >
          <FormattedMessage id="header_contact" defaultMessage="Contacto" />
        </HashLink>
      </div>
      <div>
        <p className="mobile-menu__language">
          <FormattedMessage id="language_word" defaultMessage="Idiomas" />
        </p>
      </div>
      <div className="mobile-menu__language--container">
        <div className="mobile-menu__languages">
          <p
            className={getLanguageOptionClass(Locale.ES)}
            onClick={() => contextIntl.setLocale(Locale.ES)}
          >
            <FormattedMessage defaultMessage="ESP" id="shortened_spanish" />
          </p>
          <p className="mobile-menu__language--separator">|</p>
          <p
            className={getLanguageOptionClass(Locale.EN)}
            onClick={() => contextIntl.setLocale(Locale.EN)}
          >
            <FormattedMessage defaultMessage="ING" id="shortened_english" />
          </p>
        </div>
        <div className="mobile-menu__socialMedia">
          <ul>
            <li>
              <a href="https://twitter.com/GrupoAema" target="_blank">
                <ReactSVG path={twitterIcon} />
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/grupoaemamx/" target="_blank">
                <ReactSVG path={instagramIcon} />
              </a>
            </li>
            <li>
              <a
                href="https://www.youtube.com/channel/UCeDVShdVHdUGjQj2zOJbgbA"
                target="_blank"
              >
                <ReactSVG path={youtubeIcon} />
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/AemaSeguridad/" target="_blank">
                <ReactSVG path={facebookIcon} />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="mobile-menu__line" />
      <div className="mobile-menu__terms">
        <p className="mobile-menu__link--small">
          <FormattedMessage
            id="footer_privacy"
            defaultMessage="Politicas de Privacidad"
          />
        </p>
        <Link to="/terms-conditions">
          <FormattedMessage
            id="footer_legal"
            defaultMessage="Términos de Uso"
          />
        </Link>
      </div>
      <div>
        <p className="mobile-menu__copyright">
          © 2022
          <FormattedMessage
            id="footer_rights"
            defaultMessage=" Grupo AEMA de México. Derechos Reservados."
          />
        </p>
      </div>
    </section>
  );
}
