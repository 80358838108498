import gql from "graphql-tag";
import { TypedQuery } from "@temp/core/queries";
import { Taxes } from "./types";

export const TaxesFragment = gql`
  fragment TaxesFragment on TaxCountry {
    countryCode
    countryName
    taxRate
    id
  }
`;
const TaxesQuery = gql`
  ${TaxesFragment}
  query TaxCountry {
    taxCountry {
      ...TaxesFragment
    }
  }
`;
export const TypedTaxesQuery = TypedQuery<Taxes, {}>(TaxesQuery);



