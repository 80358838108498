import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Icon } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { CartSummaryRow } from "@components/molecules";

import * as S from "./styles";
import { ICostLine, ICosts, IProps } from "./types";
import { TypedTaxesQuery } from "./queries";

const CostLine = ({
  name,
  cost,
  last = false,
  negative = false,
}: ICostLine) => (
  <S.CostLine last={last}>
    {name === "Envío" ? (
      <FormattedMessage id="shipping" defaultMessage="Envío" />
    ) : (
      <span>{name}</span>
    )}
    <span data-cy={`cartSummaryCost${name.replace(/\s/g, "")}`}>
      {negative && "- "}
      <TaxedMoney taxedMoney={cost} />
    </span>
  </S.CostLine>
);

const TaxLine = ({ name, cost, last = false, negative = false }: ICostLine) => (
  <S.CostLine last={last}>
    {name === "Impuesto" ? (
      <FormattedMessage id="tax" defaultMessage="Impuesto" />
    ) : (
      <span>{name}</span>
    )}
    <span data-cy={`cartSummaryCost${name.replace(/\s/g, "")}`}>
      {negative && "- "}
      {cost} MXN
    </span>
  </S.CostLine>
);

const Costs = ({ subtotal, promoCode, shipping, total, tax }: ICosts) => {
  const [taxRate, setTaxRate] = useState(null);
  const [subTotalNumber, setSubTotal] = useState(null);

  useEffect(() => {
    if (tax) {
      tax.taxCountry.map((countryTax: any) => {
        if (countryTax.countryCode === "MX") {
          const tax = countryTax.taxRate / 100 + 1;
          if (total) {
            const result = total.net.amount / tax;
            const iva = total.net.amount - result;
            setTaxRate(iva.toFixed(2));
            const subTotalAmount = total.net.amount - iva
            setSubTotal(subTotalAmount.toFixed(2));
          }      
        }
      });
    }
  }, [total]);

  return (
    <S.Costs>
      {subtotal && <TaxLine name="Subtotal" cost={subTotalNumber} />}
      {taxRate && <TaxLine name="Impuesto" cost={taxRate} />}
      {shipping && <CostLine name="Envío" cost={shipping} />}
      {promoCode && promoCode.gross.amount > 0 && (
        <CostLine name="Promoción" cost={promoCode} negative={true} />
      )}
      {total && <CostLine name="Total" cost={total} last={true} />}
    </S.Costs>
  );
};

/**
 * Cart summary displayed in checkout page
 */
const CartSummary: React.FC<IProps> = ({
  subtotal,
  total,
  shipping,
  promoCode,
  products,
}: IProps) => {
  const [mobileCartOpened, setMobileCartOpened] = useState(false);

  return (
    <S.Wrapper mobileCartOpened={mobileCartOpened}>
      <S.Title
        data-cy="cartSummaryTitle"
        onClick={() => setMobileCartOpened(!mobileCartOpened)}
      >
        <FormattedMessage id="summary" defaultMessage="Resumen" />
        <S.ArrowUp mobileCartOpened={mobileCartOpened}>
          <Icon name="arrow_up" size={24} />
        </S.ArrowUp>
      </S.Title>
      <S.Content>
        <S.HR />
        <S.CartSummaryProductList>
          {products?.map((product, index) => (
            <div key={product.sku}>
              <S.ProductLine>
                <CartSummaryRow
                  index={index}
                  sku={product.sku}
                  quantity={product.quantity}
                  name={product.name}
                  price={product.price}
                  thumbnail={product.thumbnail}
                />
              </S.ProductLine>
              <S.HR />
            </div>
          ))}
        </S.CartSummaryProductList>
        <TypedTaxesQuery>
          {({ data }) => (
            <Costs
              subtotal={subtotal}
              total={total}
              shipping={shipping}
              promoCode={promoCode}
              tax={data}
            />
          )}
        </TypedTaxesQuery>
      </S.Content>
    </S.Wrapper>
  );
};

export { CartSummary };
