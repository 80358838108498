import "./scss/index.scss";


import * as React from "react";
import * as ReactDOM from "react-dom";
import { FormattedMessage } from "react-intl";



export interface IModalProps {
    target?: HTMLElement | null;
    title: React.ReactNode;
    show: boolean;
    hide: () => void;
}
const modalRoot = document.getElementById("modal-root");

const Modal: React.FC<IModalProps> = ({

    title,
    show,
    hide,
    children,
    target = modalRoot,
}) =>
    target && show
        ? ReactDOM.createPortal(
            <div className="overlay overlay--modal">
                <div className="window">
                    <div className="modalMessage">
                        <div className="modalMessage__header">
                            {title}
                        </div>
                        <div className="modalMessage__content">
                            <p> {children}    </p>
                            <div className="modalMessage__foot">
                                <p>
                                    <FormattedMessage
                                        id="thankyou_choosing_us"
                                        defaultMessage="Gracias por preferirnos, puedes regresar para explorar más" />
                                </p>
                                <button className="modalMessage__returnBtn" onClick={hide}>
                                <FormattedMessage
                                        id="return"
                                        defaultMessage="Regresar" />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>,
            target
        )
        : null;

export default Modal;
