import * as React from "react";
import { FormattedMessage } from "react-intl";

const OfflinePlaceholder: React.FC<{}> = () => <>
    <FormattedMessage
        id="offline"
        defaultMessage="Desconectado "/> :(
</>;

export default OfflinePlaceholder;
