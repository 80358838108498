import React from "react";
import {  FormattedMessage, useIntl } from "react-intl";
import { Link } from "react-router-dom";

import * as S from "./styles";
import { IProps } from "./types";

export const AccountMenu: React.FC<IProps> = ({ links, active }: IProps) => {
  const intl = useIntl();
  
  return (
    <S.Wrapper>
      <S.MenuHeader>
        <FormattedMessage
          id="header_my_account"
          defaultMessage="Mi cuenta"/>:
      </S.MenuHeader>
      {links.map(link => {
        const menuItem = link
          .replace(/\//g, "")
          .replace("-", " ")
          .split(" ")
          .map(s => s.charAt(0).toUpperCase() + s.substring(1))
          .join(" ");
        return (
          <Link to={link} key={link} data-testid="account_menu__link">
            <S.MenuItem active={active === link}>
              {menuItem === 'Account' ? 
                intl.formatMessage({id:"login_account",defaultMessage:"Cuenta"}) : 
                menuItem === 'Order History' ? 
                intl.formatMessage({id:"header_orders_history",defaultMessage:"Historial de Ordenes"}) : 
                  intl.formatMessage({id:"addresses",defaultMessage:"Direcciones"})
                }
            </S.MenuItem>
          </Link>
        );
      })}
    </S.Wrapper>
  );
};
