const cities = [
  {
    label: "Aguascalientes",
    value: "Ags.",
  },
  {
    label: "Baja California",
    value: "B.C.",
  },
  {
    label: "Baja California Sur",
    value: "B.C.S.",
  },
  {
    label: "Ciudad de México",
    value: "CDMX",
  },
  {
    label: "Campeche",
    value: "Camp.",
  },
  {
    label: "Chihuahua",
    value: "Chih.",
  },
  {
    label: "Chiapas",
    value: "Chis.",
  },
  {
    label: "Coahuila",
    value: "Coah.",
  },
  {
    label: "Colima",
    value: "Col.",
  },
  {
    label: "Durango",
    value: "Dgo.",
  },
  {
    label: "Guerrero",
    value: "Gro.",
  },
  {
    label: "Guanajuato",
    value: "Gto.",
  },
  {
    label: "Hidalgo",
    value: "Hgo.",
  },
  {
    label: "Jalisco",
    value: "Jal.",
  },
  {
    label: "Michoacán",
    value: "Mich.",
  },
  {
    label: "Morelos",
    value: "Mor.",
  },
  {
    label: "Estado de México",
    value: "Méx.",
  },
  {
    label: "Nuevo León",
    value: "N.L.",
  },
  {
    label: "Nayarit",
    value: "Nay.",
  },
  {
    label: "Oaxaca",
    value: "Oax.",
  },
  {
    label: "Puebla",
    value: "Pue.",
  },
  {
    label: "Quintana Roo",
    value: "Q.R.",
  },
  {
    label: "Querétaro",
    value: "Qro.",
  },
  {
    label: "San Luis Potosí",
    value: "S.L.P.",
  },
  {
    label: "Sinaloa",
    value: "Sin.",
  },
  {
    label: "Sonora",
    value: "Son.",
  },
  {
    label: "Tabasco",
    value: "Tab.",
  },
  {
    label: "Tamaulipas",
    value: "Tamps.",
  },
  {
    label: "Tlaxcala",
    value: "Tlax.",
  },
  {
    label: "Veracruz",
    value: "Ver.",
  },
  {
    label: "Yucatán",
    value: "Yuc.",
  },
  {
    label: "Zacatecas",
    value: "Zac.",
  },
];

export default cities;
